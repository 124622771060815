import React, { Component } from 'react';
import {Explore} from './client/components/explore/Explore';
import {Profile} from './client/components/profile/Profile';
//import {Footer} from './client/components/footer/Footer';
import {Login} from './client/components/login/Login';
import {Wrapfull} from './client/components/wrap/Wrapfull';
import {Viewport} from './client/components/wrap/Viewport';
//import {Wrap} from './client/components/wrap/Wrap';
import {CreateWrap} from './client/components/wrap/CreateWrap';
import { Route, Redirect } from 'react-router';
import { withRouter, Switch } from 'react-router-dom';
import { userActions, capsuleActions, configActions } from './client/components/redux/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './App.css';
import {history} from './client/components/redux/helpers/history';
import { isObjectExists, getUserTokenFromStorage } from './client/utils/Helpers';
import { ChatbotView } from './client/components/capsuleView/ChatbotView';
import queryString from 'query-string';

class App extends Component {

    constructor (props) {
        super(props);
        this.state = {
            isLoggedIn:           false,
            footerToShow:         'main',
            loginRequestSent:     false,
            anonymousUserSpawned: false,
            isExploreGroup:       false,
            groupsToAdd:          {
                explore: isObjectExists(this.props.user, 'config.explore_category') ? this.props.user.config.explore_category : [],
                user:    isObjectExists(this.props.user, 'config.my_category') ? this.props.user.config.my_category : []
            },
            groupSetExplore: false,
            groupSetUser:    false,
            viewToRender:    'main',
            intervalIds:     {},
            ready:           false,
            capsuleToCopy:   {}
        };

        this.thumbnailParams = '150,crop';
        this.miniParams = '100x125';
    }

    componentWillMount() {
        if (!queryString.parse(this.props.location.search).bid) {
            if (-1 === window.location.href.toLowerCase().indexOf('/album/')) {
                this.loadSocials();
            }
        }
        setTimeout(() => {
            this.setState({ready: true});
        }, 500);

    }

    componentDidMount() {
        /*
         * Check if user has been logged in before, if not create anonymous login, else get auth token for user
         */

        if (window.location.pathname) {
            this.props.dispatch(userActions.getPublicUser(window.location.pathname.split('/')[1]));
        }

        if (-1 === window.location.href.toLowerCase().indexOf('/album/') && -1 === window.location.href.indexOf('/chat')) {
            this.props.dispatch(configActions.getChatbotConfig());
            if (!localStorage.getItem('MUUser') && !localStorage.getItem('MUUserLogin') ||
        (this.props.user.anonymousSpawned === false && this.props.user.withKey === false && this.props.user.withToken === false && !this.state.anonymousUserSpawned) ) {
                let generatedId = Math.random().toString(36).substring(7);
                this.props.dispatch(userActions.spawnAnonymousUser('POSTMAN_TEST_DEVICE_WEB_' + generatedId));
                this.setState({anonymousSpawned: true});
            } else {

                if (!this.props.user.withKey && !this.props.user.withToken && localStorage.getItem('MUUserLogin')) {
                    this.props.dispatch(userActions.getMe(JSON.parse(localStorage.getItem('MUUserLogin')).token));
                } else if (!this.props.user.withKey && !this.props.user.withToken && localStorage.getItem('MUUser')) {
                    this.props.dispatch(userActions.loginWithKey(JSON.parse(localStorage.getItem('MUUser')).uuid, JSON.parse(localStorage.getItem('MUUser')).loginKey));
                }
                this.setState({isLoggedIn: true});
            }

            if ((this.props.user.withKey || this.props.user.withToken) && !this.state.isLoggedIn) {
                this.setState({isLoggedIn: true});
            }

            this.calculateHeight();
            window.addEventListener('resize', this.calculateHeight.bind(this));
            window.addEventListener('orientationchange', this.calculateHeight.bind(this));
        }

    }

    componentDidUpdate(prevProps) {
        /*
         * Check if user logged in based on our store, or we just created an anonymous user, in that case log in
         */
        if (-1 === window.location.href.toLowerCase().indexOf('/album/') && -1 === window.location.href.indexOf('/chat')) {
            if (!this.props.config) {
                this.props.dispatch(configActions.getChatbotConfig());
            }

            if (this.props.user.isLoggedOut === true && this.state.isLoggedIn) {
                this.setState({isLoggedIn: false});
            }

            if (!prevProps.user.id && this.props.user.id) {
                this.setChatbotParametersIfAvailable(this.props.user.id);
            }

            if ((this.props.user.withKey === true || this.props.user.withToken === true) && !this.state.isLoggedIn) {
                this.setState({isLoggedIn: true, loginRequestSent: false});
            }

            if (isObjectExists(this.props.user, 'config.explore_category') && this.state.groupsToAdd.explore !== this.props.user.config.explore_category && !this.state.groupSetExplore) {
                let groupsToAdd = this.state.groupsToAdd;
                groupsToAdd.explore = this.props.user.config.explore_category;
                this.setState({groupsToAdd, groupSetExplore: true});
            }

            if (isObjectExists(this.props.user, 'config.my_category') && this.state.groupsToAdd.user !== this.props.user.config.my_category && !this.state.groupSetUser) {
                let groupsToAdd = this.state.groupsToAdd;
                groupsToAdd.user = this.props.user.config.my_category;
                this.setState({groupsToAdd, groupSetUser: true});
            }

            if ((!localStorage.getItem('MUUserLogin') && !localStorage.getItem('MUUser') && !this.state.anonymousUserSpawned && !this.state.anonymousSpawned) ||
        (this.props.user.anonymousSpawned === false && this.props.user.withKey === false && this.props.user.withToken === false && !this.state.anonymousUserSpawned)) {
                let generatedId = Math.random().toString(36).substring(7);
                this.props.dispatch(userActions.spawnAnonymousUser('POSTMAN_TEST_DEVICE_WEB_' + generatedId));
                this.setState({anonymousUserSpawned: true});
            } else if (localStorage.getItem('MUUserLogin') && !this.state.isLoggedIn && !this.state.loginRequestSent && !this.props.user.publicPage) {
            //Log in the user by token if exists
                this.props.dispatch(userActions.getMe());
                this.setState({loginRequestSent: true, anonymousUserSpawned: false});
            } else if (localStorage.getItem('MUUser') && !this.state.isLoggedIn && !this.state.loginRequestSent) {
            //Log in anonymous user if exists
                this.props.dispatch(userActions.loginWithKey(JSON.parse(localStorage.getItem('MUUser')).uuid, JSON.parse(localStorage.getItem('MUUser')).loginKey));
                this.setState({loginRequestSent: true, anonymousUserSpawned: false});
            }

            if (localStorage.getItem('MUUserLogin') && this.props.user.token && (this.props.user.token !== JSON.parse(localStorage.getItem('MUUserLogin')).token)) {
                this.props.dispatch(userActions.setUserObject());
            }

            if (document.getElementById('navigation-active-option') && document.getElementById('navigation-active-option').clientWidth !== this.state.widthOfNav) {
                this.setState({widthOfNav: document.getElementById('navigation-active-option').clientWidth});
            }

            this.stopIntervalIfReady();
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateHeight.bind(this));
        window.removeEventListener('orientationchange', this.calculateHeight.bind(this));
    }

    stopIntervalIfReady = () => {
        let intervals = Object.keys(this.state.intervalIds);
        for (let i in intervals) {
            if (this.props.user.fireBaseStatus && this.props.user.fireBaseStatus[intervals[i]] === 'ready') {
                this.listenStatus(intervals[i], true);
            }
        }
    }

    setChatbotParametersIfAvailable = (id) => {
        let chatbots = localStorage.getItem('fanbotsByUser') ? JSON.parse(localStorage.getItem('fanbotsByUser')) : null;
        if ( chatbots && id && chatbots[id] ) {
            localStorage.setItem('fanbotWebChatClientIds', JSON.stringify(chatbots[id]));
        }
    }

    changeView = (value) => {
        this.setState({viewToRender: value});
    }

    setIsExploreGroupView = (value) => {
        let isExploreGroup = value ? value : !this.state.isExploreGroup;
        this.setState({isExploreGroup});
    }

    onAddGroup = (id, isExplore) => {
        let groupsToAdd = {...this.state.groupsToAdd};
        isExplore ? groupsToAdd.explore.push(id) : groupsToAdd.user.push(id);

        this.setState({groupsToAdd});
    }

    onRemoveGroup = (id, isExplore) => {
        let groupsToAdd = {...this.state.groupsToAdd};
        let categoryToChange = isExplore ? 'explore' : 'user';
        let index = 0;
        for (let i in groupsToAdd[categoryToChange]) {
            if (groupsToAdd[categoryToChange][i] === id) {
                index = i;
            }
        }
        groupsToAdd[categoryToChange].splice(index, 1);
        this.setState({groupsToAdd});
    }

    setGroupsToAdd = (value) => {
        this.setState({groupsToAdd: value});
    }

    calculateHeight = () => {
        /*
         * Calculate the viewport size
         */
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = document.documentElement.clientHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('height', '-webkit-fill-available');
    }

    getThumbnailPictureBackground(url) {
        let background;
        background = {
            backgroundImage:    'url("' + url + '")',
            backgroundRepeat:   'no-repeat',
            backgroundSize:     'cover',
            backgroundPosition: 'center top',
        };
        return background;
    }

    getImageResizerParameters = (isCapsuleBackground) => {
        // XXX
        // eslint-disable-line no-debugger
        // debugger;
        let element = isCapsuleBackground ? document.getElementById('capsule-view') : document.body ;
        let parameter = '';

        if (element) {
            if (isCapsuleBackground) {
                parameter = 'w' + element.offsetWidth + '-h' + element.offsetHeight;
            } else {
                if (element.clientWidth != 0 && element.clientHeight != 0) {
                    parameter = 'w' + element.clientWidth + '-h' + element.clientHeight;
                }
            }

        }

        return parameter + '-cfill';
    }

    getClosestSizedImage = (images, size) => {
        let closestImage = {url: ''};
        let closestAbsoluteDistance;
        let isFirst = true;
        let i = 0;
        if (undefined !== size) {
            for (i in images) {
                if (isFirst && images[i].metadata && images[i].metadata.width) {
                    closestImage = images[i];
                    closestAbsoluteDistance = Math.abs(size - images[i].metadata.width);
                    isFirst = false;
                } else if (images[i].metadata && images[i].metadata.width) {
                    if (Math.abs(size - images[i].metadata.width) < closestAbsoluteDistance) {
                        closestImage = images[i];
                        closestAbsoluteDistance = Math.abs(size - images[i].metadata.width);
                    }
                }
            }
        }
        if (closestImage.url === '') {
            closestImage = images[0];

        }
        return closestImage.url;
    }

    getImageFromImageAndVideos = (capsule, size, mini) => {
        let imageUrl = ''; //placeholder here
        let scalingParam = mini ? this.miniParams : size ? size.clientWidth + 'x' + size.clientHeight : this.thumbnailParams;
        let validImage = capsule.items.image_and_video.images.find(function (img) {
            return (undefined !== img.url)  || undefined !== img.fileId;
        });

        // investigate why result is 0x74, causing viero internal server error
        if (scalingParam === '0x74') {
            scalingParam = '200x300';
        }

        if (validImage && capsule.externalSources !== null) {
            if (validImage.fileId) {
                if (validImage.metadata && validImage.metadata.storageUUID) {

                    // imageUrl = getUrlAndLicence(validImage.metadata.storageUUID, false, scalingParam, 'VieroToolkitUtilsTypeStillSingle').url;
                    // imageUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPj/HwADBwIAMCbHYQAAAABJRU5ErkJggg==';
                    imageUrl = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + validImage.fileId + '?token=' + getUserTokenFromStorage();
                } else {
                    imageUrl = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' +
                    validImage.fileId + '?token=' + getUserTokenFromStorage();
                    // validImage.fileId + '/' + this.getImageResizerParameters() + '?token=' + getUserTokenFromStorage();
                }

            } else if (validImage.url) {
                if (size) {
                    imageUrl = this.getClosestSizedImage(capsule.items.image_and_video.images, size.clientWidth);
                }
            }
        }

        return imageUrl;
    }

    getImageUrl = (imageObject /*, mini*/) => {
        let imageUrl = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' +
        //imageObject.fileId + '/' + 'w100-h100-cfill' + '?token=' + getUserTokenFromStorage();
        imageObject.fileId + '?token=' + getUserTokenFromStorage();
        // let sizeParam = mini ? this.miniParams : this.thumbnailParams;
        if (isObjectExists(imageObject, 'metadata.storageType') && imageObject.metadata.storageType === 'viero') {
            /*
            if (imageObject && imageObject.metadata && imageObject.metadata.mimeType && imageObject.metadata.mimeType.indexOf('video/') === -1) {
                imageUrl = getUrlAndLicence(imageObject.metadata.storageUUID, false, sizeParam, 'VieroToolkitUtilsTypeStillSingle').url;
            } else {
                imageUrl = getVideoThumbnail(imageObject.metadata.storageUUID, 0, sizeParam);
            }
            */
            imageUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/D/PwAHAwL/qGeMxAAAAABJRU5ErkJggg==';

        //if (isObjectExists(imageObject, 'metadata.storageType') && imageObject.metadata.storageType === 'viero') {
        //    imageUrl = getUrlAndLicence(imageObject.metadata.storageUUID, false, sizeParam, 'VieroToolkitUtilsTypeStillSingle').url;
        }

        return imageUrl;
    }

    isFileImage = (url, isFilename) => {
        let result;
        if (isFilename === true) {
            let filenameTest = url.toLowerCase();
            if (0 <= filenameTest.indexOf('.png') || 0 <= filenameTest.indexOf('.gif') || 0 <= filenameTest.indexOf('.jpeg') ||  0 <= filenameTest.indexOf('.jpg') ||  0 <= filenameTest.indexOf('.heic')) {
                result = true;
            } else {
                result = false;
            }
        } else {
            result = /^(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)$/.test(url.toLowerCase());
            //let result = true;
            let resultObject = {mimeType: ''};
            let urlTest = url.toLowerCase();
            if (result && 0 <= urlTest.indexOf('.png')) {
                resultObject.mimeType = 'image/png';
            } else if (result && 0 <= urlTest.indexOf('.gif')) {
                resultObject.mimeType = 'image/gif';
            } else if (result && 0 <= urlTest.indexOf('.jpeg')) {
                resultObject.mimeType = 'image/jpeg';
            } else if (result && 0 <= urlTest.indexOf('.jpg')) {
                resultObject.mimeType = 'image/jpg';
            }
            if (resultObject.mimeType !== '') {
                result = resultObject;
            }
        }
        return result;
    }

    getThumbnailPicture = (capsule, mini, element, type) => {
        if (type !== 'thumbnail') {
            if (isObjectExists(capsule, 'items.contact.image.profile.fileId') && capsule.items.contact.image.profile.fileId !== '') {
                let imageUrl = this.getImageUrl(capsule.items.contact.image.profile, mini, element);
                return <div className={mini === true ? 'capsule-pic-mini' : 'capsule-pic'} style={this.getThumbnailPictureBackground(imageUrl)}/>;
            } else if (isObjectExists(capsule, 'items.contact.image.profile.url') && capsule.items.contact.image.profile.url !== ''
              && isObjectExists(capsule, 'items.contact.image.profile.metadata.mimeType') && capsule.items.contact.image.profile.metadata.mimeType !== 'text/html'
              && (!isObjectExists(capsule, 'items.contact.image.profile.backgroundImageURL') || (isObjectExists(capsule, 'items.contact.image.profile.backgroundImageURL') && capsule.items.contact.image.profile.backgroundImageURL === ''))) {
                return <img className={mini === true ? 'capsule-pic-mini' : 'capsule-pic'} src={capsule.items.contact.image.profile.url}/>;
            } else if (isObjectExists(capsule, 'items.contact.image.profile.backgroundImageURL') && capsule.items.contact.image.profile.backgroundImageURL !== '') {
                return <img className={mini === true ? 'capsule-pic-mini' : 'capsule-pic'} src={capsule.items.contact.image.profile.backgroundImageURL}/>;
            }
        }
        if (isObjectExists(capsule, 'items.contact.image.thumbnail.fileId') && capsule.items.contact.image.thumbnail.fileId !== '') {
            let imageUrl = this.getImageUrl(capsule.items.contact.image.thumbnail, mini, element);
            return <div className={mini === true ? 'capsule-pic-mini' : 'capsule-pic'} style={this.getThumbnailPictureBackground(imageUrl)}/>;
        } else if (isObjectExists(capsule, 'items.contact.image.thumbnail.url') && capsule.items.contact.image.thumbnail.url !== '') {
            return <img className={mini === true ? 'capsule-pic-mini' : 'capsule-pic'} src={capsule.items.contact.image.thumbnail.url}/>;
        } else if (isObjectExists(capsule, 'items.image_and_video.images') && 0 < capsule.items.image_and_video.images.length) {
            let elementSize = element ? document.getElementsByClassName(element)[0] : document.getElementById('navigation-active-option') || {clientWidth: this.state.widthOfNav};
            if (!elementSize || (!elementSize.clientHeight && !elementSize.clientWidth)) elementSize = {clientWidth: 75, clientHeight: 75};
            let imageUrl = this.getImageFromImageAndVideos(capsule, elementSize, mini);
            return <img className={mini === true ? 'capsule-pic-mini' : 'capsule-pic'} src={imageUrl}/>;
        } else {
            return <div className={mini === true ? 'capsule-pic-mini no-pic' :'capsule-pic no-pic'}></div>;
        }
    }

    listenStatus = (id, isRemove) => {
        let intervalIds = {...this.state.intervalIds};
        if (!isRemove) {
            if (!this.state.intervalIds[id] || this.state.intervalIds[id] === null || this.state.intervalIds[id] === undefined) {
                this.props.dispatch(userActions.getFirebaseStatus(id));
                intervalIds[id] = window.setInterval(() => this.props.dispatch(userActions.getFirebaseStatus(id)), 2000);
                this.setState({intervalIds: intervalIds});
            }
        } else {
            if (this.state.intervalIds[id]) {
                window.clearInterval(this.state.intervalIds[id]);
                intervalIds[id] = null;
                this.setState({intervalIds});
            }
        }
    }

    logout = () => {
        this.props.dispatch(userActions.logout());
        this.props.dispatch(capsuleActions.clearCapsules());
        this.props.dispatch(capsuleActions.clearGroups());
        this.props.dispatch(capsuleActions.clearFavourites());
        this.setState({isLoggedIn: false, loginRequestSent: false, groupsToAdd: [], groupSetExplore: false, groupSetUser: false});
        this.changeView('main');
        this.navigateTo('explore');
    }

    navigateTo = (dest) => {
        switch (dest) {
            case 'explore':
                history.push('/');
                break;
            case 'profile':
                history.push('/profile');
                break;
            case 'login':
                history.push('/login');
                break;
            case 'groups':
                history.push('/groups');
                break;
        }

    }

    getImageScalingParameter = () => {
        let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        let scalingParameter = '';

        if (viewportWidth <= 125) {
            scalingParameter = '250x';   //was 125x
        } else if (viewportWidth > 125 && viewportWidth <= 250) {
            scalingParameter = '500x';   //was 250x
        } else if (viewportWidth > 250 && viewportWidth <= 500) {
            scalingParameter = '500x';  //iphone 10, was 500x
        } else if (viewportWidth > 500 && viewportWidth <= 1000) {
            scalingParameter = '1000x'; //was 500x
        } else if (viewportWidth > 1000) {
            scalingParameter = '1000x';  //my mac, was 600x
        }

        return scalingParameter;
    }

    changeFooterTo = (type) => {
        this.setState({footerToShow: type});
    }

    setCapsuleToEdit = (capsule) => {
        let capsuleToCopy = {
            name:  capsule.name,
            items: {
                contact: capsule.items.contact
            }

        };
        this.navigateTo('explore');
        this.setState({capsuleToCopy});
        this.changeView('createCard');
    }

    shouldShowPublicProfileButton = (capsule) => {
        //if (isObjectExists(capsule, 'publicUser.image') && capsule.publicUser.image !== '' && !this.props.user.publicPage && (capsule.public && capsule.groups.length > 0)) {
        //    if (localStorage.getItem('MUUserLogin') && this.isInFavorites(capsule.id)) {
        // lds black screen - this.isInFavorites doens't exist - verify if Attila meant it to exist
        if (isObjectExists(capsule, 'publicUser.image') && capsule.publicUser.image !== '' && !this.props.user.publicPage && (capsule.public && capsule.groups.length > 0)) {
            if (localStorage.getItem('MUUserLogin')) {
                return false;
            }
            return true;
        }
        return false;
    }

    render() {
        if (this.state.ready) {
            return (
                <React.Fragment>
                    <Switch>
                        <Route exact path='/' render={(props) =>
                            <Profile {...props}
                                home={true}
                                setIsExploreGroupView={this.setIsExploreGroupView}
                                isExploreGroupView={this.state.isExploreGroup}
                                onAddGroup={this.onAddGroup}
                                onRemoveGroup={this.onRemoveGroup}
                                setGroupsToAdd={this.setGroupsToAdd}
                                groupsToAdd={this.state.groupsToAdd}
                                changeView={this.changeView}
                                logout={this.logout}
                                navigateTo={this.navigateTo}
                                viewToRender={this.state.viewToRender}
                                changeFooterTo={this.changeFooterTo}
                                getThumbnailPicture={this.getThumbnailPicture}
                                getImageFromImageAndVideos={this.getImageFromImageAndVideos}
                                listenStatus={this.listenStatus}
                                getImageScalingParameter={this.getImageScalingParameter}
                                capsuleToCopy={this.state.capsuleToCopy}
                                isFileImage={this.isFileImage}
                            />
                        }

                        />

                        <Route exact path='/login' render={(props) => <Login {...props} navigateTo={this.navigateTo} changeFooterTo={this.changeFooterTo}/>} />
                        <Route exact path='/capsules/:capsuleId' render={(props) =>
                            <Explore {...props}
                                backToGroups={() => {this.navigateTo('explore'); location.reload();}}
                                getThumbnailPicture={this.getThumbnailPicture}
                                getImageFromImageAndVideos={this.getImageFromImageAndVideos}
                                listenStatus={this.listenStatus}
                                getImageScalingParameter={this.getImageScalingParameter}
                                setCapsuleToEdit={this.setCapsuleToEdit}
                                isShare={true}
                                shouldShowPublicProfileButton={this.shouldShowPublicProfileButton}
                            />}
                        />
                        <Route path='/group/:share_token?' render={(props) =>
                            <Explore {...props}
                                backToGroups={() => {this.navigateTo('explore'); location.reload();}}
                                groupShare={true}
                                getThumbnailPicture={this.getThumbnailPicture}
                                getImageFromImageAndVideos={this.getImageFromImageAndVideos}
                                listenStatus={this.listenStatus}
                                getImageScalingParameter={this.getImageScalingParameter}
                                setCapsuleToEdit={this.setCapsuleToEdit}
                                isShare={true}
                                shouldShowPublicProfileButton={this.shouldShowPublicProfileButton}
                            />}
                        />
                        <Route exact path='/capsules/x/e/:capsuleId' render={(props) =>
                            <Explore {...props}
                                backToGroups={() => this.navigateTo('explore')}
                                getThumbnailPicture={this.getThumbnailPicture}
                                getImageFromImageAndVideos={this.getImageFromImageAndVideos}
                                listenStatus={this.listenStatus}
                                getImageScalingParameter={this.getImageScalingParameter}
                                isShare
                            />}
                        />
                        <Route exact path='/verifyEmail' render={(props) => <Login navigateTo={this.navigateTo} {...props} changeFooterTo={this.changeFooterTo} />} />
                        <Route exact path='/resetPassword' render={(props) => <Login navigateTo={this.navigateTo} {...props} changeFooterTo={this.changeFooterTo} />} />
                        <Route path='/chat/:bid?/:cid?/:uid?/:color?' render={(props) => <ChatbotView {...props} standalone config={this.props.config}/>} />
                        <Route exact path='/Album/wrap/create' render={(props) => <CreateWrap navigateTo={this.navigateTo} {...props} user={this.props.user} capsules={this.props.capsules} listenStatus={this.listenStatus}/>} />
                        {/* <Route exact path='/Album/LetUsBeYourVoiceAlt' render={(props) => <Wrap navigateTo={this.navigateTo} {...props} user={this.props.user} config={this.props.config} dispatch={this.props.dispatch} capsules={this.props.capsules} listenStatus={this.listenStatus} location={this.props.location}/>} /> */}
                        {/* <Route exact path='/Album/LetUsBeYourVoice' render={(props) => <Wrapfull navigateTo={this.navigateTo} {...props} config={this.props.config} user={this.props.user} dispatch={this.props.dispatch} capsules={this.props.capsules} listenStatus={this.listenStatus} location={this.props.location}/>} /> */}
                        <Route exact path='/album/viewport' render={(props) => <Viewport navigateTo={this.navigateTo} {...props} config={this.props.config} user={this.props.user} dispatch={this.props.dispatch} capsules={this.props.capsules} listenStatus={this.listenStatus} location={this.props.location} isFromTemplate={false}/>} />
                        <Route exact path='/album/m3ds' render={(props) => <Wrapfull navigateTo={this.navigateTo} {...props} config={this.props.config} user={this.props.user} dispatch={this.props.dispatch} capsules={this.props.capsules} listenStatus={this.listenStatus} location={this.props.location} isFromTemplate={true}/>} />
                        <Route path='/Album/' render={(props) => <Wrapfull navigateTo={this.navigateTo} {...props} config={this.props.config} user={this.props.user} dispatch={this.props.dispatch} capsules={this.props.capsules} listenStatus={this.listenStatus} location={this.props.location} isFromTemplate={false}/>} />
                        <Route exact path='/:slug' render={(props) =>
                            <Profile {...props}
                                home={true}
                                setIsExploreGroupView={this.setIsExploreGroupView}
                                isExploreGroupView={this.state.isExploreGroup}
                                onAddGroup={this.onAddGroup}
                                onRemoveGroup={this.onRemoveGroup}
                                setGroupsToAdd={this.setGroupsToAdd}
                                groupsToAdd={this.state.groupsToAdd}
                                changeView={this.changeView}
                                logout={this.logout}
                                navigateTo={this.navigateTo}
                                viewToRender={this.state.viewToRender}
                                changeFooterTo={this.changeFooterTo}
                                getThumbnailPicture={this.getThumbnailPicture}
                                getImageFromImageAndVideos={this.getImageFromImageAndVideos}
                                listenStatus={this.listenStatus}
                                getImageScalingParameter={this.getImageScalingParameter}
                                capsuleToCopy={this.state.capsuleToCopy}
                                isFileImage={this.isFileImage}
                            />
                        }

                        />
                        <Route render={(props) => <Redirect to={{ pathname: '/', state: { from: props.location } }} />} />
                        <Route exact path='/capsules/:capsuleId' render={(props) =>
                            <Explore {...props}
                                backToGroups={() => {this.navigateTo('explore'); location.reload();}}
                                getThumbnailPicture={this.getThumbnailPicture}
                                getImageFromImageAndVideos={this.getImageFromImageAndVideos}
                                listenStatus={this.listenStatus}
                                getImageScalingParameter={this.getImageScalingParameter}
                                setCapsuleToEdit={this.setCapsuleToEdit}
                                isShare={true}
                            />}
                        />
                    </Switch>

                    <div className="footer-space"></div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    loadSocials = () => {
        window.fbAsyncInit = function() {
            // eslint-disable-next-line no-undef
            FB.init({
                cookie: true, // enable cookies to allow the server to access the session
                xfbml:  true  // parse XFBML
            });
            window.fbLoaded = true;
        };

        // Load the SDK Asynchronously
        (function(d) {
            var js, id = 'facebook-jssdk', ref = d.getElementsByTagName('script')[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement('script'); js.id = id; js.async = true;
            js.src = '//connect.facebook.net/en_US/all.js';
            ref.parentNode.insertBefore(js, ref);
        }(document));

        window.twttr = (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0],
                t = window.twttr || {};
            if (d.getElementById(id)) return t;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://platform.twitter.com/widgets.js';
            fjs.parentNode.insertBefore(js, fjs);

            t._e = [];
            t.ready = function(f) {
                t._e.push(f);
            };

            return t;
        }(document, 'script', 'twitter-wjs'));
    }
}

App.propTypes = {
    dispatch: PropTypes.func,
    user:     PropTypes.object,
    capsules: PropTypes.object,
    config:   PropTypes.object,
    location: PropTypes.object
};

function mapStateToProps(state) {
    const { user, capsules, config } = state;
    return {
        user,
        capsules,
        config
    };
}

const connectedApp = compose(withRouter, connect(mapStateToProps))(App);
export { connectedApp as App };
