import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
//import menuIcon from '../../assets/images/profile_asset_with_text.png';
import horizontalDivider from '../../assets/images/Path@3x.png';
import './Footer.css';
import { capsuleActions } from '../redux/actions';

export class Footer extends React.Component {

    isExplore = () => {
        let result = false;
        if ( -1 === window.location.href.indexOf('/login') && -1 === window.location.href.indexOf('/groups') && -1 === window.location.href.indexOf('/capsules') && -1 === window.location.href.indexOf('/profile')) {
            result = true;
        }
        return result;
    }

    determinateActionOnExplore = () => {
        if ( 0 < window.location.href.indexOf('/login') || 0 < window.location.href.indexOf('/groups') || 0 < window.location.href.indexOf('/capsules') || 0 < window.location.href.indexOf('/profile')) {   
            if ((localStorage.getItem('MUUser') && this.props.groupsToAdd.length === 0) || localStorage.getItem('MUUserLogin')) {
                this.props.dispatch(capsuleActions.clearCapsules());
            } 
            this.props.navigateTo('explore');
        } else {
            this.props.navigateTo('explore');
        }
    }

    renderChangeEmailFooter = () => {
        return ( 
            <Container className="container-width footer">
                <Row className="footer-navigation">
                    <Col className="left-icon">
                    
                        <img src={horizontalDivider} className="profile-divider"></img>
                        <div className="login change-email text-center" onClick={this.props.changeView}>Not the right email?</div>
                    </Col>
                </Row>
            </Container>);
    }

    render() {
        if (this.props.user) {
            switch (this.props.footerToShow) {
                case 'edit':
                case 'main':
                    return null;
                case 'changeEmail':
                    return this.renderChangeEmailFooter();
                case 'hide':
                    return null;
                
            }
        } else {
            return null;
        }
    }
     
}

Footer.propTypes = {
    navigateTo:            PropTypes.func,
    footerToShow:          PropTypes.string,
    changeView:            PropTypes.func,
    user:                  PropTypes.object,
    capsules:              PropTypes.object,
    dispatch:              PropTypes.func,
    setIsExploreGroupView: PropTypes.func,
    isExploreGroup:        PropTypes.bool,
    groupsToAdd:           PropTypes.array
};