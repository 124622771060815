import React from 'react';
import PropTypes from 'prop-types';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import Iframe from 'react-iframe';
import './SocialView.css';
import { capsuleActions } from '../redux/actions';
import {Container, Row, Col} from 'reactstrap';
import { isWebsiteSocial, knownTypes } from '../../utils/Helpers';
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';

export default class SocialView extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            isMediaView:     false,
            isXframe:        this.props.capsules.isXframe,
            isXframeLoading: this.props.capsules.isXframeLoading,
            twitterReset:    false,
            facebookLoaded:  false,
            canShow:         ''
        };
    }

    componentDidMount() {
        if (this.props.websiteUrl) {
            if (!isWebsiteSocial(this.props.websiteUrl)) {
                this.props.dispatch(capsuleActions.checkIfXframeOptions(this.props.websiteUrl));
            }

        } else {
            this.props.dispatch(capsuleActions.checkIfXframeOptions(this.props.socialType.value.urlString));
        }

    }

    calculateCapsuleViewHeight = () => {
        let height = 0;
        height = document.documentElement.style.cssText.split(':')[1].split('px')[0];
        return height;
    }

    componentDidUpdate(prevProps) {

        if (this.props.websiteUrl && isWebsiteSocial(this.props.websiteUrl) === 'facebook' && window.fbLoaded && !this.state.facebookLoaded) {
            window.FB.XFBML.parse();
            this.setState({facebookLoaded: true});
        }

        if (!this.props.websiteUrl) {
            if (window.fbLoaded && (this.props.socialType.label === 'facebook' || this.getSocialType(this.props.socialType) === 'facebook') &&
            !this.state.facebookLoaded) {
                window.FB.XFBML.parse();
                this.setState({facebookLoaded: true});

            }

            if ((this.props.socialType.label === 'twitter' || this.getSocialType(this.props.socialType) === 'twitter') && (prevProps.socialType !== this.props.socialType) &&
        (prevProps.socialType.label === 'twitter' || this.getSocialType(prevProps.socialType) === 'twitter') && !this.state.twitterReset ) {
                this.setState({twitterReset: true});
            }

            if (prevProps.socialType !== this.props.socialType) {
                this.props.dispatch(capsuleActions.checkIfXframeOptions(this.props.socialType.value.urlString));
                this.setState({facebookLoaded: false});
            }
        }
    }

    getProviderUrl = (providerUrl, label) => {
        let url = providerUrl;
        //if (0 < url.indexOf('m.')) {
        if (0 < url.indexOf('https://m.')) {
            url = url.replace('m.', 'www.');
        }
        switch (label) {
            case 'facebook':
                if (-1 !== url.indexOf('id=') && -1 === url.indexOf('profile.php') && -1 === url.indexOf('search')) {
                    url = 'https://www.facebook.com' + url.split('id=')[1];
                }
                break;
            case 'twitter':
                url = url.split('twitter.com/')[1];
                if (-1 !== url.indexOf('?')) {
                    url = url.split('?')[0];
                }

                if (providerUrl === 'https://www.twitter.com') {
                    url = providerUrl;
                }
                break;
            case 'spotify':
                if (-1 !== url.indexOf('artist')) {
                    url = url.split('artist');
                    url = url[0] + 'embed/artist' + url[1];
                } else {
                    url = 'https://www.spotify.com';
                }

                break;
            case 'youtube':
                if (0 < url.indexOf('watch')) {
                    url = 'https://www.youtube.com/embed/' + url.split('=')[1];
                } else if (0 < url.indexOf('/videos') && -1 === url.indexOf('/channel')) {
                    url = 'https://www.youtube.com/embed/?listType=user_uploads&list=' + url.split('https://')[1].split('/')[1];
                } else if (0 < url.indexOf('/user')) {
                    url = 'https://www.youtube.com/embed/?listType=user_uploads&list=' + url.split('user/')[1];
                } else if (0 < url.indexOf('/channel')) {
                    url = 'https://www.youtube.com/embed/videoseries?list=UU' + url.split('channel/')[1].split('/')[0].split('UC')[1];
                } else if (0 < url.indexOf('youtu.be')) {
                    url = 'https://www.youtube.com/embed/' + url.split('https://')[1].split('/')[1];
                } else {
                    url = 'https://www.youtube.com';
                }

                break;
            case 'deezer':
                url = url.split('artist/')[1];
                break;
        }
        return url;
    }

    getSocialType = (socialType, webUrl) => {
        let url = webUrl ? webUrl : socialType.value.urlString;
        let result = socialType ? socialType.label : '';

        for (let i in knownTypes) {
            if (url && -1 !== url.indexOf(knownTypes[i])) {
                result = knownTypes[i] === 'youtu.be' ? 'youtube' : knownTypes[i];
                break;
            }
        }

        return result;
    }

    isOnlyBaseUrl = (url) => {
        let knownBaseUrls = [
            'https://www.youtube.com',
            'https://www.youtu.be',
            'https://www.twitter.com',
            'https://www.facebook.com',
            'https://www.open.spotify.com',
            'https://www.spotify.com'
        ];

        for (let i in knownBaseUrls) {

            if (url === knownBaseUrls[i]) {
                if (this.state.canShow === '') {
                    this.setState({canShow: false});
                }
                return true;
            }
        }
        if (this.state.canShow === false) {
            this.setState({canShow: ''});
        }
        return false;
    }

    getView = (websiteUrl) => {
        let view;
        let url;

        let socialUrl = websiteUrl ? websiteUrl : this.props.socialType.value.urlString;
        let type;

        type = this.getSocialType(this.props.socialType, websiteUrl);
        url = this.getProviderUrl(socialUrl, type);

        if (type == 'facebook' && (-1 < url.indexOf('profile.php') || -1 < url.indexOf('search'))) {
            type = '';
        }

        if (url.slice(-1) === '/') {
            url = url.slice(0, -1);
        }

        switch (type) {
            case 'facebook':
                if (this.isOnlyBaseUrl(url)) {
                    view = this.showWebPage(url);
                } else {

                    view =
                    <div className="text-center">
                        <div
                            className="fb-page"
                            data-href={url}
                            data-tabs="timeline"
                            data-small-header="true"
                            data-adapt-container-width="true"
                            data-hide-cover="false"
                            data-show-facepile="false"
                            data-width={document.getElementById('capsule-view').clientWidth}
                            data-height={document.getElementById('capsule-view').clientHeight}
                        >
                        </div>
                    </div>;
                }
                break;
            case 'twitter':
                if (this.isOnlyBaseUrl(url)) {
                    view = this.showWebPage(url);
                } else {
                    if (this.state.twitterReset === true) {
                        view = null;
                        this.setState({twitterReset: false});
                    } else {
                        view = (
                            <TwitterTimelineEmbed
                                ref={(ref) => this.twitterComponent = ref}
                                sourceType="profile"
                                screenName={url}
                                theme="dark"
                            />
                        );

                    }
                }
                break;
            case 'spotify':
                if (this.isOnlyBaseUrl(url)) {
                    view = this.showWebPage(url);
                } else {
                    view =<Iframe url= {url}
                        allow="encrypted-media"
                        display="initial"
                        position="relative"></Iframe>;
                }
                break;
            case 'youtube':
                if (this.isOnlyBaseUrl(url)) {
                    view = this.showWebPage(url);
                } else {
                    view = <Iframe
                        url={url}
                        display="initial"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        position="relative"
                        allowFullScreen
                    />;
                }
                break;
            case 'soundcloud':
                view = <Iframe
                    scrolling="no"
                    frameBorder="no"
                    url="https://w.soundcloud.com/icon/?url=http%3A%2F%2Fsoundcloud.com%2Floureedmetallica&color=orange_white&size=32"
                    display="initial"
                    position="relative"
                />;
                break;
            case 'deezer':
                view = <Iframe
                    frameborder="0"
                    url={'https://www.deezer.com/plugins/player?format=square&autoplay=false&playlist=true&color=007FEB&layout=dark&size=medium&type=radio&id=artist-' + this.getProviderUrl(this.props.socialType.value.urlString, type) +'&app_id=1'} />;
                break;
            default:
                view = this.showWebPage(url);
        }
        return view;
    }

    getClientWidth = () => {
        let width;
        if (document.getElementById('capsule-view')) {
            width = document.getElementById('capsule-view').clientWidth;
        }

        return width;
    }

    isHttps = () => {
        let urlToCheck = this.props.websiteUrl ? this.props.websiteUrl : this.props.socialType.value.urlString;
        let result = false;

        if (urlToCheck.startsWith(('https://'))) {
            result = true;
        }

        return result;
    }

    showWebPage = () => {

        if (((this.props.capsules.isXframe === false && this.props.capsules.isXframeLoading === false) || this.state.canShow === false || (!this.isHttps() && this.props.capsules.isXframeLoading === false))) {
            return (
                <Container>
                    <Row>
                        <Col className="page-not-show">
                            <a target="_blank" rel="noopener noreferrer" href={this.props.websiteUrl ? this.props.websiteUrl : this.props.socialType.value.urlString} >
                                {this.props.websiteUrl ? this.props.websiteUrl : this.props.socialType.value.urlString}</a>
                        </Col>
                    </Row>
                </Container>
            );

        } else if (this.props.capsules.isXframe === true && this.props.capsules.isXframeLoading === false) {
            if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
                let url = this.props.websiteUrl ? this.props.websiteUrl : this.getProviderUrl(this.props.socialType.value.urlString);
                return <iframe id="iframe"
                    src={this.props.websiteUrl ? this.props.websiteUrl : this.getProviderUrl(this.props.socialType.value.urlString)}
                    frameBorder="0"
                    height={'100vh'}
                    sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation"
                    allow="encrypted-media *"
                    className={-1 !== url.toLowerCase().indexOf('ELSforaUtism'.toLowerCase()) ? 'ios-frameElsForAutism' : 'ios-frame'}

                />;
            } else {
                let url = this.props.websiteUrl ? this.props.websiteUrl : this.getProviderUrl(this.props.socialType.value.urlString);
                return <Iframe id="iframe"
                    url={this.props.websiteUrl ? this.props.websiteUrl : this.getProviderUrl(this.props.socialType.value.urlString)}
                    style={{width: this.getClientWidth()}}
                    display="initial"
                    allow="encrypted-media *"
                    sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation"
                    frameBorder="0"
                    position={this.props.websiteUrl ? 'relative' : 'absolute'}
                    className={-1 !== url.toLowerCase().indexOf('ELSforaUtism'.toLowerCase()) ? 'elsWhiteBackground' : 'blackBackground'}
                />;
            }

        } else {
            const override = css`
        display: absolute;
        text-align: center
        margin: 0 auto;
        border-color: red;
    `;
            return (
                <Container>
                    <Row>
                        <Col className="page-not-show">
                            <div className='spinner'>
                                <ClipLoader
                                    css={override}
                                    sizeUnit={'px'}
                                    size={75}
                                    color={'#123abc'}
                                    loading={true}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            );
        }

    }

    render() {

        if (this.props.websiteUrl && isWebsiteSocial(this.props.websiteUrl)) {
            return this.getView(this.props.websiteUrl);
        } else if (this.props.websiteUrl && !isWebsiteSocial(this.props.websiteUrl)) {
            return this.showWebPage();
        } else {
            return this.getView();
        }

    }

}

SocialView.propTypes = {
    socialType:    PropTypes.object,
    capsuleToShow: PropTypes.object,
    dispatch:      PropTypes.func,
    capsules:      PropTypes.object,
    websiteUrl:    PropTypes.string
};
