import { combineReducers } from 'redux';
import { capsules } from './capsulesReducer';
import { user } from './userReducer';
import { config } from './configReducer';

const appReducer = combineReducers({
    capsules,
    user,
    config
});
  
const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;