import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import './CapsuleView.css';
import Viewer from 'react-viewer';
var FileSaver = require('file-saver');
import 'react-viewer/dist/index.css';
import { isObjectExists, getObjectValue, getUserTokenFromStorage } from '../../utils/Helpers';
import { getUrlAndLicence, getVideoThumbnail } from '../../utils/VieroUtils';
import { userActions } from '../redux/actions';
import VideoPlayer from '../video/VideoPlayer';
import {VideoModal} from '../video/VideoModal';
import {ModalView} from '../profile/ModalView';
import mediaIcon from '../../assets/images/MediaIcon@3x.png';
import noPic from '../../assets/images/no_pic.jpeg';
//import divider from '../../assets/images/myu_create_card_bottomdivider_20190328.png';

export class MediaView extends Component {

    constructor (props) {
        super(props);
        this.state = {
            menuState: {
                isvCardOpen:       false,
                isBioOpen:         false,
                isAudioOpen:       false,
                isPhotosOpen:      false,
                isVideosOpen:      false,
                isNotesOpen:       false,
                isDocumentsOpen:   false,
                audioStateChanged: false
            },
            visible:      false,
            imageToShow:  '',
            showVideo:    {},
            isProcessing: false
        };

        this.thumbnailParam = '150x150,crop';
        this.defaultParam = '700x';
        this.stillProcessing = 'Photo still processing';
    }

    componentDidMount() {
        if (isObjectExists(this.props, 'capsuleToShow.items.image_and_video.images')) {
            let images = this.props.capsuleToShow.items.image_and_video.images;
            let imagesToCheck = [];

            for (let i in images) {
                if (isObjectExists(images[i], 'metadata.storageUUID')) {
                    imagesToCheck.push(images[i].metadata.storageUUID);
                    this.props.dispatch(userActions.getFirebaseStatus(images[i].metadata.storageUUID));

                }

            }

            this.setState({photosChecked: true, photoStatus: imagesToCheck});

        }
    }

    componentDidUpdate() {
        this.props.checkIfScrollable();

        this.checkIfPhotoReady();
    }

    checkIfPhotoReady = () => {
        let photos = this.state.photoStatus;

        for (let i in photos) {

            if (isObjectExists(this.props, 'user.fireBaseStatus.' + photos[i]) && this.props.user.fireBaseStatus[photos[i]] === 'processing') {
                this.props.listenStatus(photos[i]);
            }

            if (isObjectExists(this.props, 'user.fireBaseStatus.' + photos[i]) && this.props.user.fireBaseStatus[photos[i]] === 'ready') {
                this.props.listenStatus(photos[i], true);
            }
        }
    }

    toggleMenu = (menu) => {
        let modifiedMenuState = {...this.state.menuState};

        modifiedMenuState[menu] = !modifiedMenuState[menu];

        this.setState({
            menuState: modifiedMenuState
        });
    }

    toggleVideo = (i) => {
        let showVideo = {...this.state.showVideo};

        showVideo[i] = showVideo[i] ? !showVideo[i] : true;
        this.setState({showVideo});
    }

    /*
     *
     * Render the view of BIO
     *
     */

    renderBio = () => {

        let bio = this.props.capsuleToShow.items.artist.bioOverview;

        if (bio == '') {
            bio = this.props.capsuleToShow.items.artist.bioHeadline;
        }

        return (
            <React.Fragment>

                <Row className="media-bio"><Col>{bio}</Col></Row>
            </React.Fragment>
        );
    }

    getStatusOfMedia = (id) => {
        let result = true;

        if (this.props.user.gettingFireBase === false &&
             isObjectExists(this.props, 'user.fireBaseStatus.' + id) &&
              (this.props.user.fireBaseStatus[id] !== 'error' && this.props.user.fireBaseStatus[id] !== 'ready')
        ) {

            this.props.dispatch(userActions.getFirebaseStatus(id));
            result = false;
        }

        return result;
    }

    getImageUrl = (imageObject, preview) => {
        //let imageUrl = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + imageObject.fileId + '/-cfill' + '?token=' + getUserTokenFromStorage();
        let imageUrl = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + imageObject.fileId + '?token=' + getUserTokenFromStorage();

        if (isObjectExists(imageObject, 'metadata.storageType') && imageObject.metadata.storageType === 'viero') {
            imageUrl = getUrlAndLicence(imageObject.metadata.storageUUID, false, preview ? this.props.getImageScalingParameter() : this.thumbnailParam, 'VieroToolkitUtilsTypeStillSingle').url;
        }

        return imageUrl;
    }

    getImage = (imageObject) => {

        let image =  (
            <img src={imageObject.fileId ?
                this.getImageUrl(imageObject)
                : imageObject.url}
            alt="img" className={'media-images hover mb-10'}

            onClick={imageObject.fileId ?
                () => { this.setState({ visible: !this.state.visible, imageToShow: this.getImageUrl(imageObject, true) }); } :
                () => { this.setState({ visible: !this.state.visible, imageToShow: imageObject.url }); } }
            />
        );

        if (isObjectExists(imageObject, 'metadata.storageType') && imageObject.metadata.storageType === 'viero') {
            image = this.getStatusOfMedia(imageObject.metadata.storageUUID) !== false ?
                image
                :
                (<img src={noPic} className="media-images hover mb-10" onClick={() => this.setState({isProcessing: true})}></img>);
        }
        return image;
    }

    /*
     *
     * Render the view of Photos
     *
     */

    renderPhotos = () => {
        let images = this.props.capsuleToShow.items.image_and_video.images;
        return (
            <Row>
                {Object.keys(images).map((i) => (
                    <Col key={i} xs="6" sm="4" md="3" lg="3" className="text-center">
                        {this.getImage(images[i])}
                        {images[i].url && images[i].url !== '' && <div ><a href={images[i].url} target="_blank" rel="noopener noreferrer">URL</a></div>}
                    </Col>
                ))
                }
                <Viewer
                    visible={this.state.visible}
                    onClose={() => { this.setState({ visible: false }); } }
                    images={[{src: this.state.imageToShow, alt: '', downloadUrl: this.state.imageToShow }]}
                    drag={false}
                    noToolbar={false}
                    customToolbar={() => [{key: 'download', render: React.createElement('i', { className: 'react-viewer-icon react-viewer-icon-download' }), onClick: () => { FileSaver.saveAs(this.state.imageToShow, 'image.jpg'); } }]}
                    downloadable={true}
                    downloadInNewWindow={false}
                    noImgDetails={true}
                    noNavbar={true}
                    noClose={false}
                    onMaskClick={() => { this.setState({ visible: false }); } }
                />
            </Row>
        );
    }

    renderNotes = () => {
        const notes = this.props.capsuleToShow.items.contact.notes;
        return (
            Object.keys(notes).map((i) =>(

                <Row key={'note-' + i}><Col>{notes[i]}</Col></Row>

            ))

        );
    }

    getVideoObject = (video, i, preview) => {
        let src, keySystems, isViero = false;

        if (isObjectExists(video, 'metadata.storageType') && video.metadata.storageType === 'viero') {
            src = preview ? getVideoThumbnail(video.metadata.storageUUID, 1, this.thumbnailParam) : getUrlAndLicence(video.metadata.storageUUID, false, '', 'VieroToolkitUtilsTypeMotion').url;
            keySystems = getUrlAndLicence(video.metadata.storageUUID, false, '', 'VieroToolkitUtilsTypeMotion').keySystems;
            isViero = true;
        } else {
            src = video.fileId ? process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + video.fileId + '.' + ((video.metadata.mimeType.split('/')[1] === 'mpeg') ? 'mov' : video.metadata.mimeType.split('/')[1]) + '?token=' + getUserTokenFromStorage() : video.url;
        }
        return preview === true ?
            <img src={isViero ? src : mediaIcon} className="media-images hover mb-10" onClick={() => this.toggleVideo(i)} />
            :
            <VideoPlayer
                url={src}
                listenStatus={this.props.listenStatus}
                id={isViero ? video.metadata.storageUUID : null}
                status={this.props.user.fireBaseStatus}
                isViero={isViero} keySystems={keySystems}
                videoClass="media-videos"
                type={isViero ? null : video.metadata ? video.metadata.mimeType : null}
                toggle={() => this.toggleVideo(i)}/>;
    }

    /*
     *
     * Render the view of Videos
     *
     */

    renderVideos = () => {
        let videos = this.props.capsuleToShow.items.image_and_video.videos;
        return (
            Object.keys(videos).map((i) => (
                <React.Fragment key={'videos' + i}>
                    <VideoModal isOpen={this.state.showVideo[i]}
                        toggle={() => this.toggleVideo(i)} video={this.getVideoObject(videos[i], i)}
                        status={this.props.user.fireBaseStatus && videos[i].metadata && videos[i].metadata.storageUUID ? this.props.user.fireBaseStatus[videos[i].metadata.storageUUID] : false }/>
                    <Col  xs="6" sm="4" md="3" lg="3" className="text-center">
                        {this.getVideoObject(videos[i], i, true)}
                        {videos[i].url && videos[i].url !== '' && <div ><a href={videos[i].url} target="_blank" rel="noopener noreferrer">URL</a></div>}
                    </Col>
                </React.Fragment>
            ))

        );
    }

    playAudio = (i) => {
        let audio = document.getElementById('audio-' + i);
        if (audio.paused) {
            audio.play();
        } else {
            audio.pause();
        }
        this.setState({audioStateChanged: !this.state.audioStateChanged});

    }

    getDurationInMinutes = (duration) =>{
        let minutes = Math.floor(duration/60);
        var seconds = duration % 60;
        return (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
    }

    renderAudio = () => {
        let audios = this.props.capsuleToShow.items.audio.audio;
        let audioList = (

            Object.keys(audios).map((i) => (
                <Row key={i}>

                    <Col xs="1" sm="1" md="1" lg="1">{(parseInt(i, 10) + 1)}</Col>
                    <Col xs="6" sm="6" md="6" lg="6">
                        <audio
                            id={'audio-' + i}
                            src={audios[i].fileId ?
                                process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + audios[i].fileId + '?token=' + getUserTokenFromStorage()
                                : audios[i].url} type={audios[i].metadata ? audios[i].metadata.mimeType : null}
                        />
                        {   audios[i].filename ?
                            <span className="">{audios[i].filename}</span>
                            :
                            audios[i].url ?
                                <span className="">{audios[i].url}</span>
                                :
                                null
                        }
                    </Col>
                    <Col xs="2" sm="2" md="2" lg="2">
                        {
                            document.getElementById('audio-' + i) ?
                                <span className="right">{this.getDurationInMinutes(Math.floor(document.getElementById('audio-' + i).duration))}</span>
                                :
                                null
                        }
                    </Col>
                    <Col xs="3" sm="3" md="3" lg="3">
                        {
                            <Col className="hover" onClick={() => this.playAudio(i)}>
                                {document.getElementById('audio-' + i) ? document.getElementById('audio-' + i).paused ? 'Play' : 'Pause' : null}
                            </Col>
                        }

                    </Col>
                </Row>
            ))

        );

        return (
            <div className={this.state.menuState.isAudioOpen ? '' : 'hidden'}>
                {audioList}
            </div>
        );
    }

    renderDocuments = () => {
        let docs = this.props.capsuleToShow.items.documents.documents;
        return (
            <Row>
                {Object.keys(docs).map((i) => (
                    <Col key={i}>
                        <a className="item-link" target="_blank" rel="noopener noreferrer" href={docs[i].fileId  ?
                            process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + docs[i].fileId + '?token=' + getUserTokenFromStorage()
                            : docs[i].url}
                        alt="img"

                        >{docs[i].filename ? docs[i].filename : 'Document ' + (parseInt(i, 10) + 1)} </a>
                    </Col>
                ))
                }
            </Row>
        );
    }

    isMediaAdded = () => {
        let result = false;

        if (isObjectExists(this.props, 'capsuleToShow.items.artist.bioOverview') && this.props.capsuleToShow.items.artist.bioOverview !== ''
        || isObjectExists(this.props, 'capsuleToShow.items.audio.audio')  && 0 < getObjectValue(this.props, 'capsuleToShow.items.audio.audio').length
        || isObjectExists(this.props, 'capsuleToShow.items.image_and_video.images') && 0 < getObjectValue(this.props, 'capsuleToShow.items.image_and_video.images').length
        || isObjectExists(this.props, 'capsuleToShow.items.image_and_video.videos') && 0 < getObjectValue(this.props, 'capsuleToShow.items.image_and_video.videos').length
        || isObjectExists(this.props, 'capsuleToShow.items.documents.documents') && 0 < getObjectValue(this.props, 'capsuleToShow.items.documents.documents').length
        || (isObjectExists(this.props, 'capsuleToShow.items.contact.notes') && 1 < getObjectValue(this.props, 'capsuleToShow.items.contact.notes').length) || isObjectExists(this.props, 'capsuleToShow.items.contact.notes') && getObjectValue(this.props, 'capsuleToShow.items.contact.notes').length === 1 && getObjectValue(this.props, 'capsuleToShow.items.contact.notes')[0] !== ''
        ) {
            result = true;
        }
        return result;
    }

    render() {
        if (this.isMediaAdded()) {
            return (
                <React.Fragment>
                    <ModalView isOpen={this.state.isProcessing}
                        toggle={() => this.setState({isProcessing: !this.state.isProcessing})}
                        onCancel={() => {this.setState({isProcessing: !this.state.isProcessing});}}
                        title={this.stillProcessing}
                        modalType="imageProcessing"
                    />
                    <div className="media-list" id="media-list">
                        {(isObjectExists(this.props, 'capsuleToShow.items.artist.bioOverview') && this.props.capsuleToShow.items.artist.bioOverview !== '') || (isObjectExists(this.props, 'capsuleToShow.items.artist.bioHeadline') && this.props.capsuleToShow.items.artist.bioHeadline !== '') ?
                            <Row className="media-option">
                                <Col>
                                    <span className="navigation-button" onClick={() => this.toggleMenu('isBioOpen')}>BIO</span>
                                </Col>
                            </Row>
                            :
                            null
                        }
                        {this.state.menuState.isBioOpen &&
                        <div>
                            {this.renderBio()}
                        </div>
                        }

                        {isObjectExists(this.props, 'capsuleToShow.items.audio.audio')  && 0 < getObjectValue(this.props, 'capsuleToShow.items.audio.audio').length &&
                    <Row className="media-option">
                        <Col>
                            <span className="navigation-button" onClick={ isObjectExists(this.props, 'capsuleToShow.items.audio.audio')  && 0 < getObjectValue(this.props, 'capsuleToShow.items.audio.audio').length ? () => this.toggleMenu('isAudioOpen') : null}>AUDIO</span>
                        </Col>
                    </Row>
                        }
                        {isObjectExists(this.props, 'capsuleToShow.items.audio.audio')  && 0 < getObjectValue(this.props, 'capsuleToShow.items.audio.audio').length &&
                            this.renderAudio()
                        }

                        {isObjectExists(this.props, 'capsuleToShow.items.image_and_video.images') && 0 < getObjectValue(this.props, 'capsuleToShow.items.image_and_video.images').length &&
                    <Row className="media-option">
                        <Col>
                            <span className="navigation-button" onClick={isObjectExists(this.props, 'capsuleToShow.items.image_and_video.images') && 0 < getObjectValue(this.props, 'capsuleToShow.items.image_and_video.images').length ? () => this.toggleMenu('isPhotosOpen') : null}>PHOTOS</span>
                        </Col>
                    </Row>
                        }

                        { this.state.menuState.isPhotosOpen &&
                         this.renderPhotos()
                        }

                        {isObjectExists(this.props, 'capsuleToShow.items.image_and_video.videos') && 0 < getObjectValue(this.props, 'capsuleToShow.items.image_and_video.videos').length &&
                    <Row className="media-option">
                        <Col >
                            <span className="navigation-button" onClick={ isObjectExists(this.props, 'capsuleToShow.items.image_and_video.videos') && 0 < getObjectValue(this.props, 'capsuleToShow.items.image_and_video.videos').length ? () => this.toggleMenu('isVideosOpen') : null}>VIDEOS</span>
                        </Col>
                    </Row>
                        }
                        { this.state.menuState.isVideosOpen &&
                        <Row>
                            {this.renderVideos()}
                        </Row>
                        }

                        {isObjectExists(this.props, 'capsuleToShow.items.documents.documents') && 0 < getObjectValue(this.props, 'capsuleToShow.items.documents.documents').length &&
                    <Row className="media-option">
                        <Col>
                            <span className="navigation-button" onClick={ isObjectExists(this.props, 'capsuleToShow.items.documents.documents') && 0 < getObjectValue(this.props, 'capsuleToShow.items.documents.documents').length ? () => this.toggleMenu('isDocumentsOpen') : null}>DOCUMENTS</span>
                        </Col>
                    </Row>
                        }

                        {this.state.menuState.isDocumentsOpen &&
                         this.renderDocuments()
                        }

                        {(isObjectExists(this.props, 'capsuleToShow.items.contact.notes') && 1 < getObjectValue(this.props, 'capsuleToShow.items.contact.notes')) || isObjectExists(this.props, 'capsuleToShow.items.contact.notes') && getObjectValue(this.props, 'capsuleToShow.items.contact.notes').length === 1 && getObjectValue(this.props, 'capsuleToShow.items.contact.notes')[0] !== '' ?
                            <Row className="media-option">
                                <Col >
                                    <span className="navigation-button" onClick={ isObjectExists(this.props, 'capsuleToShow.items.contact.notes') && 0 < getObjectValue(this.props, 'capsuleToShow.items.contact.notes').length ? () => this.toggleMenu('isNotesOpen') : null}>NOTES</span>
                                </Col>
                            </Row>
                            : null
                        }
                        {this.state.menuState.isNotesOpen &&
                         this.renderNotes()
                        }
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <Container>
                    <Row>
                        <Col className="text-center">
                    No Media has been added
                        </Col>
                    </Row>
                </Container>
            );
        }

    }

}

MediaView.propTypes = {
    capsuleToShow:                            PropTypes.object,
    onClose:                                  PropTypes.func,
    user:                                     PropTypes.object,
    getTransformationClassBasedOnOrientation: PropTypes.func,
    isListing:                                PropTypes.bool,
    onShare:                                  PropTypes.func,
    deleteCapsule:                            PropTypes.func,
    checkIfScrollable:                        PropTypes.func,
    dispatch:                                 PropTypes.func,
    listenStatus:                             PropTypes.func,
    getStatusOfMedia:                         PropTypes.func,
    getImageScalingParameter:                 PropTypes.func
};
