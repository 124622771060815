import React from 'react';
import PropTypes from 'prop-types';
//import { Row, Col } from 'reactstrap';
//import Carousel from 'nuka-carousel';
import PINEmpty from '../../assets/images/inactive_icon.png';
import fbInactive from '../../assets/images/socialIcons/inactive/FacebookInactive@2x.png';
import twitterInactive from '../../assets/images/socialIcons/inactive/TwitterInactive@2x.png';
import soundCloudInactive from '../../assets/images/socialIcons/inactive/SoundcloudInactive@2x.png';
import spotifyInactive from '../../assets/images/socialIcons/inactive/SpotifyInactive@2x.png';
import youtubeInactive from '../../assets/images/socialIcons/inactive/YoutubeInactive@2x.png';
import instagramIcon from '../../assets/images/InstagramInactive@3x.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

class SocialCarousel extends React.Component {
    static propTypes = {
        index:              PropTypes.number,
        socialProfiles:     PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object)]),
        parentStateUpdater: PropTypes.func
    }

    static defaultProps = {
        index:          '',
        socialProfiles: [{}]
    }

    constructor(props) {
        super(props);

        this.afterSocialSlide = this.afterSocialSlide.bind(this);
        this.sliderRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.index !== this.props.index ) {
            this.sliderRef.current.slickGoTo(this.props.index);
        }
    }

    /*
    * Get corresponding social icon for the carousel
    */

    getSocialImage(social, url) {

        let knownTypes = ['facebook', 'twitter', 'spotify', 'youtube', 'deezer', 'instagram'];
        let type = social.label;
        let image = {cssClass: ''};
        for (let i in knownTypes) {
            
            if (social.value && social.value.urlString && -1 !== social.value.urlString.indexOf(knownTypes[i])) {
                type = knownTypes[i];
                break;
            }
        }

        switch (type) {
            case 'facebook':
                image.icon = fbInactive;
                image.cssClass = 'facebook-icon';
                break;
            case 'twitter':
                image.icon = twitterInactive;
                image.cssClass = 'twitter-icon';
                break;
            case 'spotify':
                image.icon = spotifyInactive;
                break;
            case 'youtube':
                image.icon = youtubeInactive;
                break;
            case 'soundcloud':
                image.icon = soundCloudInactive;
                break;
            case 'instagram':
                image.icon = instagramIcon;
                break;
            default:
                //return url + 'favicon.ico';
                image.icon = 'https://www.google.com/s2/favicons?domain=' + url;
        }

        return image;
    }

    renderSocialItem = (item) => {
            
        return (
            
            <img src={this.getSocialImage(item, item.value.urlString).icon}
                className={'option ' + this.getSocialImage(item, item.value.urlString).cssClass}
            />
        );
       
    }

    afterSocialSlide(index) {     
        //this.setState({index: index});
        this.props.parentStateUpdater(index);
    }

    render() {
    
        let settings = {
            dots:          false,
            arrows:        false,
            autoplay:      false,
            infinite:      false,
            draggable:     true,
            swipeToSlide:  true,
            variableWidth: true,
            focusOnSelect: true,
            centerMode:    false,
            vertical:      false,
            afterChange:   (index) => this.afterSocialSlide(index)
            
        };
        return (
 
            <Slider ref={this.sliderRef} {...settings}>
                <div className="opt-cont"><img src={PINEmpty} className='option' alt="main" /></div>
                {   //Map the social options in the order provided by backend
                    Object.keys(this.props.socialProfiles).map((i) => (
                        this.props.socialProfiles[i].value.toolbar === true && this.props.socialProfiles[i].value.urlString && this.props.socialProfiles[i].value.urlString !== '' &&
                            <div className="opt-cont" key={i}>
                                
                                {this.renderSocialItem(this.props.socialProfiles[i])}
                            </div>
                        
                    ))
                }
                    
            </Slider>

        /*
            <Carousel
                slideIndex={this.props.index}
                afterSlide={slideIndex => this.afterSocialSlide(slideIndex)}
                slideWidth={'25px'}
                initialSlideWidth={50}
                cellAlign={'left'}
                cellSpacing={30}
                withoutControls={true}
                frameOverflow={'hidden'}
                swiping={true}
                draggable={true}
            >

            </Carousel>
            */
        );
    }
}

export default SocialCarousel;
