import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Col, Row } from 'reactstrap';
import { isObjectExists, getObjectValue, getCapsuleName } from '../../utils/Helpers';
// import { getUrlAndLicence} from '../../utils/VieroUtils';
//import xIcon from '../../assets/images/x.png';

class NavigationCarousel extends React.Component {
    static propTypes = {
        index:                                    PropTypes.number,
        socialProfiles:                           PropTypes.arrayOf(PropTypes.object),
        parentStateUpdater:                       PropTypes.func,
        slidesToShow:                             PropTypes.number,
        capsules:                                 PropTypes.array,
        user:                                     PropTypes.object,
        getTransformationClassBasedOnOrientation: PropTypes.func,
        isPreview:                                PropTypes.bool,
        startingIndex:                            PropTypes.number,
        getImageFromImageAndVideos:               PropTypes.func
    }

    static defaultProps = {
        index:          '',
        socialProfiles: [{}],
        user:           {}
    }

    constructor(props) {
        super(props);

        this.state = {
            index: this.props.isPreview ? 0 : props.index
        };

        this.thumbnailParam = '400x200,crop';

    }

    isOneItemCapsule = () => {
        let res = false;
        if (1 === this.props.capsules.length || this.props.isPreview === true) {
            res = true;
        }
        return res;
    }

    afterNavigationSlide = (index) => {
        this.setState({index: index});
        this.props.parentStateUpdater(index);
    }

    getClosestSizedImage = (images, size) => {
        let closestImage = {url: ''};
        let closestAbsoluteDistance;
        let isFirst = true;
        let i = 0;
        if (undefined !== size) {
            for (i in images) {
                if (isFirst && images[i].metadata && images[i].metadata.width) {
                    closestImage = images[i];
                    closestAbsoluteDistance = Math.abs(size - images[i].metadata.width);
                    isFirst = false;
                } else if (images[i].metadata && images[i].metadata.width) {
                    if (Math.abs(size - images[i].metadata.width) < closestAbsoluteDistance) {
                        closestImage = images[i];
                        closestAbsoluteDistance = Math.abs(size - images[i].metadata.width);
                    }
                }
            }
        }
        if (closestImage.url === '') {
            closestImage = images[0];

        }
        return closestImage.url;
    }

    getCarouselImage = (capsule) => {
        let background = '';
        if (isObjectExists(capsule, 'items.contact.image.thumbnail.fileId') || isObjectExists(capsule, 'items.contact.image.thumbnail.url')) {
            let imageUrl;

            if (isObjectExists(capsule, 'items.contact.image.thumbnail.metadata.storageType') && capsule.items.contact.image.thumbnail.metadata.storageType === 'viero') {
                //imageUrl = getUrlAndLicence(capsule.items.contact.image.thumbnail.metadata.storageUUID, false, this.thumbnailParam, 'VieroToolkitUtilsTypeStillSingle').url;
                imageUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P//PwAGBAL/VJiKjgAAAABJRU5ErkJggg==';
            } else {
                if (isObjectExists(capsule, 'items.contact.image.thumbnail.fileId') && capsule.items.contact.image.thumbnail.fileId !== '') {
                    imageUrl = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' +
                    // capsule.items.contact.image.thumbnail.fileId + '/w400-h200-cfill' + '?token=' + this.props.user.token;
                    capsule.items.contact.image.thumbnail.fileId + '?token=' + this.props.user.token;
                } else {
                    imageUrl = capsule.items.contact.image.thumbnail.url;
                }
            }

            background = {
                backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0) 38%,rgba(0,0,0,0.9) 100%), url("' + imageUrl + '")',

                backgroundRepeat:   'no-repeat',
                backgroundSize:     'cover',
                backgroundPosition: 'center top',
            };

            return (

                <Col className="navigation-container">
                    <Row className={'navigation-img'} style={background} >
                        <Col className="align-self-end"><span className="navigation-name-on-pic">{getCapsuleName(capsule)}</span></Col>
                    </Row>
                </Col>

            );
        } else if (isObjectExists(capsule, 'items.image_and_video.images') && getObjectValue(capsule, 'items.image_and_video.images').length > 0) {
            let imageUrl = this.props.getImageFromImageAndVideos(capsule, document.getElementById('navigation-active-option'));
            background = {
                backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0) 38%,rgba(0,0,0,0.9) 100%), url("' + imageUrl + '")',

                backgroundRepeat:   'no-repeat',
                backgroundSize:     'cover',
                backgroundPosition: 'center top',
            };

            return (
                <Col className="navigation-container">

                    {/*<img id="" className="x-icon" src={xIcon} />*/}
                    <Row className={'navigation-img'} style={background} >
                        <Col className="align-self-end"><span className="navigation-name-on-pic align-self-end">{getCapsuleName(capsule)}</span></Col>
                    </Row>
                </Col>
            );
        } else {
            return (
                <Col className="navigation-container align-self-end">
                    {/*<img id="" className="x-icon" src={xIcon} />*/}
                    <Row className="navigation-img no-pic">
                        <Col className="align-self-end"><span className="navigation-name-on-pic align-self-end">{getCapsuleName(capsule)}</span></Col>
                    </Row>
                </Col>);
        }
    }

    addPlaceHolderSlide = () => {
        if (this.props.isPreview) {
            return <div></div>;
        } else {
            return null;
        }
    }

    getInitialSlide = () => {
        let slide;
        if (this.isOneItemCapsule()) {
            slide = 1;
        } else if (this.props.startingIndex) {
            slide = this.props.startingIndex;
        } else {
            slide = this.props.index;
        }

        return slide;
    }

    render() {
        let settings = {
            arrows:        false,
            autoplay:      false,
            dots:          false,
            draggable:     this.isOneItemCapsule() ? false : true,
            easing:        'linear',
            infinite:      false,
            //lazyLoad:      'onDemand',
            initialSlide:  this.isOneItemCapsule() ? 1 : this.props.index,
            centerMode:    true,
            swipeToSlide:  this.isOneItemCapsule() ? false : true,
            vertical:      false,
            variableWidth: true,
            focusOnSelect: this.isOneItemCapsule() ? false : true,
            afterChange:   (index) => this.afterNavigationSlide(index)
        };
        return (
            <div id="slider">
                <Slider {...settings} ref={(ref) => this.slider = ref}>
                    {this.isOneItemCapsule() && <Col></Col>}
                    {   //Map the navigation options in the order provided by backend

                        Object.keys(this.props.capsules).map((i) => (
                            <React.Fragment key={i}>

                                <div>

                                    {this.getCarouselImage(this.props.capsules[i])
                                    }

                                </div>

                            </React.Fragment>
                        ))

                    }
                    {this.isOneItemCapsule() && <Col></Col>}
                </Slider>
            </div>
        );
    }
}

export default NavigationCarousel;
