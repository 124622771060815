import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { userActions } from '../../redux/actions';
import { capsuleActions } from '../../redux/actions';

export class SendGridView extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            id:           '',
            key:          '',
            list:         '',
            group:        '',
            newGroupName: ''
            
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.sendgridConnected === false && this.props.user.sendgridConnected === true) {
            this.setState({shareSent: true});
        }

        if (!prevProps.capsules.groupCreated && this.props.capsules.groupCreated) {
            this.props.dispatch(userActions.connectToSendGrid(this.state.id, this.state.key, this.state.list, this.props.capsules.newGroup.id));
        }
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    handleChange = (e) => {
        this.setState({group: e.target.value});
    }

    setupSendGrid = () => {
        if (this.state.newGroupName !== '') {
            this.props.dispatch(capsuleActions.createCapsuleGroup(this.state.newGroupName));
        } else {
            this.props.dispatch(userActions.connectToSendGrid(this.state.id, this.state.key, this.state.list, this.state.group));
        }
        
    }

    getOptions = () => {
        return Object.keys(this.props.groups).map((i) => (
            <React.Fragment key={i}>
                <option key={this.props.groups[i].id} id={this.props.groups[i].id} value={this.props.groups[i].id}>{this.props.groups[i].name}</option>
            </React.Fragment>
        ));
    
    }

    onGroupChange = (e) => {
        this.setState({newGroupName: e.target.value});
    }

    renderForm = () => {
        return (
            <Container>
  
                <Row className="align-items-center mb-4">
                    <Col>
                        <input
                            value={this.state.id}
                            onChange={(e) => this.onChange(e)}
                            name="id"
                            id="id"
                            placeholder="Sendgrid ID"
                            className="capsule-input"
                        />
                    
                    </Col>
                </Row>
                <Row className="align-items-center mb-4">
                    <Col>
                        <input
                            value={this.state.key}
                            onChange={(e) => this.onChange(e)}
                            name="key"
                            id="key"
                            placeholder="Sendgrid Key"
                            className="capsule-input"
                        />
                    </Col>
                </Row>
                <Row className="align-items-center mb-4">
                    <Col>
                        <input
                            value={this.state.list}
                            onChange={(e) => this.onChange(e)}
                            name="list"
                            id="list"
                            placeholder="Sendgrid List ID"
                            className="capsule-input"
                        />
                    </Col>
                </Row>
                <Row className="text-dark mb-1">
                    <Col>
                    Select group
                    </Col>
                    <Col>
                    Create group
                    </Col>
                </Row>
                <Row className="align-items-center mb-4">
                    <Col>
                        <select disabled={this.state.newGroupName} value={this.state.group} onChange={this.handleChange}>
                            <option id="sendgrid" value=''>SendGrid</option>
                            {this.getOptions()}
                        </select>
                    </Col>
                    <Col className="text-dark">
                        <input
                            value={this.state.newGroupName}
                            onChange={(e) => this.onGroupChange(e)}
                            name="name"
                            id="name"
                            placeholder="Group Name"
                            className="capsule-input text-dark"
                        />
                    </Col>
                </Row>
                            
                <Row className="text-center button-row mb-4">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.setupSendGrid()}>{'Synchronize'}</button>
                    </Col>
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.toggle()}>{'Cancel'}</button>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderSuccess = () => {
        return (
            <Container>
                <Row><Col className="text-dark">Sendgrid synchronized successfully!</Col></Row>
                <Row className="text-center button-row">
            
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.toggle()}>{'OK'}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    render() {

        if (!this.state.shareSent) {
            return this.renderForm();
        } else {
            return this.renderSuccess();
        }
    }

}

SendGridView.propTypes = {
    dispatch: PropTypes.func,
    user:     PropTypes.object,
    groups:   PropTypes.array,
    toggle:   PropTypes.func,
    capsules: PropTypes.object
};