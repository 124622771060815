import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Collapse, Container, Input } from 'reactstrap';
//import nameIcon from '../../assets/images/RelatedNameIcon@3x.png';
import phoneNumberIcon from '../../assets/images/PhoneNumberIcon@3x.png';
import wwwIcon from '../../assets/images/WWWIcon@3x.png';
import emailIcon from '../../assets/images/EmailIcon@3x.png';
import mapIcon from '../../assets/images/MapPinIcon@3x.png';
import birthDayIcon from '../../assets/images/BirthdayIcon@3x.png';
import calendarIcon from '../../assets/images/CalendarIcon@3x.png';
import subtractIcon from '../../assets/images/Subtract@3x.png';
import addIcon from '../../assets/images/AddIcon@3x.png';
import profilePhoto from '../../assets/images/ProfilePhoto@2x.png';
import backButton from '../../assets/images/Close@2x.png';
import {ModalView} from './ModalView';
import { getUrlAndLicence} from '../../utils/VieroUtils';
import { isObjectExists } from '../../utils/Helpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import subtractIcon from '../../assets/images/Subtract@3x.png';
var moment = require('moment');

export class VCardEdit extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            isShowMoreOpen:  false,
            isAddressOpen:   false,
            isVcardOpen:     false,
            everUploadImage: false
        };
        this.inputProfilePicRef = React.createRef();

        this.uploadingText = 'Uploading ';
        this.processingText = 'Processing ';
        this.removeText = 'Remove';
        this.thumbnailParams = '150x150,crop';
        this.addPhoneNumberText = 'Add new phone number';
        this.addEmailText = 'Add new email address';
        this.addWebText = 'Add new website';
        this.addLocationText = 'Add new address';
        this.addDateText = 'Add new date';
    }

    openProfilePicDialog = () => {
        this.inputProfilePicRef.current.click();
        if (!this.state.everUploadImage) {
            this.setState({everUploadImage: true});   //everUploadImage stop profile image in edit from bouncing while saved profile image has not changed.
        }
    }

    onChange = (e, indexOfAddress, type) => {
        this.props.onCapsuleChange(e, 'vCard', indexOfAddress, type);
    }

    onBirthDayChange = (date, isAnniversary) => {
        let e = {
            target: {
                value: date ? date : '',
                name:  isAnniversary ? 'anniversary' : 'birthDay'
            }
        };

        if (isAnniversary) {
            this.props.onCapsuleChange(e, 'vCard', 0, 'anniversary');
        } else {
            this.props.onCapsuleChange(e, 'vCard');
        }

    }

    onUrlChange = (value) => {
        this.props.onCapsuleChange(value, 'thumbnailUrl');
        this.openModal();
    }

    onSocialChange = (e, i, service) => {
        this.props.onCapsuleChange(e, 'social', i, service);
    }

    openModal = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    toggleMenu = (menu) => {
        this.setState({[menu]: !this.state[menu]});
    }

    onUploadImage = (e) =>{
        this.props.uploadPicture(e, 'thumbnail');
        this.openModal();
    }

    renderAddresses = () => {
        let addresses = this.props.vCardData.locationAddress;
        return (
            <React.Fragment>
                {addresses.length > 0 &&
            Object.keys(addresses).map((i) => (
                <React.Fragment key={i}>
                    <Row id={i} className="input-row">
                        <Col lg="2" md="2" sm="2" xs="2" className="text-center">
                            <img className="vCard-icons" src={mapIcon} />
                        </Col>
                        <Col lg="8" md="8" sm="8" xs="8">
                            <Input type="select" name="select" id="exampleSelect" bsSize="sm" className="type-select"
                                onChange={(e) => this.onChange(e, i, 'addressType')}>
                                <option selected={addresses[i].label === 'Home' || addresses[i].label === 'home'} value="home">home</option>
                                <option selected={addresses[i].label === 'Work' || addresses[i].label === 'work'} value="work">work</option>
                            </Input>
                        </Col>
                        <Col lg="2" md="2" sm="2" xs="2" className="text-center">
                            <img src={subtractIcon} className="add-field-icon minus" onClick={() => this.props.removeField('locationAddress', i)}/>
                        </Col>

                    </Row>
                    <Row className="input-row">
                        <Col lg="2" md="2" sm="2" xs="2">

                        </Col>

                        <Col lg="10" md="10" sm="10" xs="10">
                            <input
                                value={addresses[i].value.street}
                                onChange={(e) => this.onChange(e, i)}
                                name="street"
                                id="street"
                                className="capsule-input"
                                placeholder='Street'
                            />
                        </Col>

                    </Row>
                    <Row className="input-row">
                        <Col lg="2" md="2" sm="2" xs="2">

                        </Col>
                        <Col lg="5" md="5" sm="5" xs="5">
                            <input
                                value={addresses[i].value.city}
                                onChange={(e) => this.onChange(e, i)}
                                name="city"
                                id="city"
                                className="capsule-input"
                                placeholder='City'
                            />
                        </Col>
                        <Col lg="5" md="5" sm="5" xs="5">
                            <input
                                value={addresses[i].value.country}
                                onChange={(e) => this.onChange(e, i)}
                                name="country"
                                id="country"
                                className="capsule-input"
                                placeholder='Country'
                            />
                        </Col>
                    </Row>
                    <Row className="input-row">
                        <Col lg="2" md="2" sm="2" xs="2">

                        </Col>
                        <Col lg="5" md="5" sm="5" xs="5">
                            <input
                                value={addresses[i].value.state}
                                onChange={(e) => this.onChange(e, i)}
                                name="state"
                                id="state"
                                className="capsule-input"
                                placeholder='State'
                            />
                        </Col>
                        <Col lg="5" md="5" sm="5" xs="5">
                            <input
                                value={addresses[i].value.postalCode}
                                onChange={(e) => this.onChange(e, i)}
                                name="postalCode"
                                id="postalCode"
                                className="capsule-input"
                                placeholder='Postalcode'
                            />
                        </Col>
                    </Row>

                </React.Fragment>
            ))
                }

                <Row className="mb-3">

                    <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                        {addresses.length === 0  && <img className="vCard-icons" src={mapIcon} />}
                    </Col>

                    <Col>
                        <span>{this.addLocationText}</span>
                        <img src={addIcon} className="add-field-icon plus ml-2" onClick={() => this.props.addField('locationAddress')}/>
                    </Col>
                </Row>
            </React.Fragment>
        );

    }

    renderPhoneNumbers = () => {

        let phoneNumbers = this.props.vCardData.phoneNumber;

        return (
            <React.Fragment>
                {phoneNumbers.length > 0 &&
                    Object.keys(phoneNumbers).map((i) => (
                        <Row className="input-row" key={i}>
                            <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                                <img className="vCard-icons" src={phoneNumberIcon} />
                            </Col>
                            <Col xs="3" lg="3" md="3" sm="3">
                                <Input type="select" name="select" id="exampleSelect" bsSize="sm" className="type-select"
                                    onChange={(e) => this.onChange(e, i, 'phoneType')}>
                                    <option selected={phoneNumbers[i].label == 'Home' || phoneNumbers[i].label == 'home'} value="home">home</option>
                                    <option selected={phoneNumbers[i].label == 'Work' || phoneNumbers[i].label === 'WORK' || phoneNumbers[i].label === 'work'} value="work">work</option>
                                    <option selected={phoneNumbers[i].label == 'mobile'} value="mobile">mobile</option>
                                    <option selected={phoneNumbers[i].label == 'main'} value="main">main</option>
                                    <option selected={phoneNumbers[i].label == 'home fax'} value="home fax">home fax</option>
                                    <option selected={phoneNumbers[i].label == 'work fax'} value="work fax">work fax</option>
                                    <option selected={phoneNumbers[i].label == 'other fax'} value="other fax">other fax</option>
                                    <option selected={phoneNumbers[i].label == 'pager'} value="pager">pager</option>
                                </Input>
                            </Col>
                            <Col xs="5" lg="5" md="5" sm="5">
                                <input
                                    value={phoneNumbers[i].value}
                                    onChange={(e) => this.onChange(e, i, 'phoneNumber')}
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    className="capsule-input"
                                    placeholder='Phonenumber'
                                />
                            </Col>
                            <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                                <img src={subtractIcon} className="add-field-icon minus" onClick={() => this.props.removeField('phoneNumber', i)}/>
                            </Col>
                        </Row>

                    ))
                }

                <Row className="mb-3">

                    <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                        {phoneNumbers.length === 0  && <img className="vCard-icons" src={phoneNumberIcon} />}
                    </Col>

                    <Col>
                        <span>{this.addPhoneNumberText}</span>
                        <img src={addIcon} className="add-field-icon plus ml-2" onClick={() => this.props.addField('phoneNumber')}/>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }

    renderEmailAddresses = () => {

        let emailAddresses = this.props.vCardData.emailAddress;

        return (
            <React.Fragment>
                {emailAddresses.length > 0 &&
                    Object.keys(emailAddresses).map((i) => (
                        <Row className="input-row" key={i}>
                            <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                                <img className="vCard-icons" src={emailIcon} />
                            </Col>
                            <Col xs="3" lg="3" md="3" sm="3">
                                <Input type="select" name="select" id="exampleSelect" bsSize="sm" className="type-select"
                                    onChange={(e) => this.onChange(e, i, 'emailType')}>
                                    <option selected={emailAddresses[i].label == 'home' || emailAddresses[i].label == 'Home'} value="home">home</option>
                                    <option selected={emailAddresses[i].label == 'work' || emailAddresses[i].label == 'work'} value="work">work</option>
                                </Input>
                            </Col>
                            <Col xs="5" lg="5" md="5" sm="5">
                                <input
                                    value={emailAddresses[i].value}
                                    onChange={(e) => this.onChange(e, i, 'emailAddress')}
                                    name="emailAddress"
                                    id="emailAddress"
                                    className="capsule-input"
                                    placeholder='Email'
                                />
                            </Col>
                            <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                                <img src={subtractIcon} className="add-field-icon minus" onClick={() => this.props.removeField('emailAddress', i)}/>
                            </Col>
                        </Row>

                    ))
                }

                <Row className="mb-3">

                    <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                        {emailAddresses.length === 0  && <img className="vCard-icons" src={emailIcon} />}
                    </Col>

                    <Col>
                        <span>{this.addEmailText}</span>
                        <img src={addIcon} className="add-field-icon plus ml-2" onClick={() => this.props.addField('emailAddress')}/>
                    </Col>
                </Row>

            </React.Fragment>
        );

    }

    renderUrlAddresses = () => {

        let urlAddresses = this.props.socialData.socialProfiles;

        return (
            <React.Fragment>
                {urlAddresses.length > 0 &&
                        Object.keys(urlAddresses).map((i) => (
                            <Row className="input-row" key={i}>
                                <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                                    <img className="vCard-icons" src={wwwIcon} />
                                </Col>
                                <Col xs="3" lg="3" md="3" sm="3">
                                    {urlAddresses[i].label == 'home' || urlAddresses[i].label == 'work' || urlAddresses[i].label == 'Home' || urlAddresses[i].label == 'work' ?
                                        <Input type="select" name="select" id="exampleSelect" bsSize="sm" className="type-select"
                                            onChange={(e) => this.onChange(e, i, 'urlType')}>
                                            <option selected={urlAddresses[i].label == 'home'} value="home">home</option>
                                            <option selected={urlAddresses[i].label == 'work'} value="work">work</option>
                                        </Input>
                                        :
                                        <input
                                            value={urlAddresses[i].label}
                                            disabled
                                            className="capsule-input"
                                            placeholder="Web address"
                                        />
                                    }
                                </Col>
                                <Col xs="5" lg="5" md="5" sm="5">
                                    <input
                                        value={urlAddresses[i].value.urlString}
                                        onChange={(e) => this.onSocialChange(e, i, 'url')}
                                        className="capsule-input"
                                        placeholder="Web address"
                                    />
                                </Col>
                                <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                                    <img src={subtractIcon} className="add-field-icon minus" onClick={() => this.props.removeField('urlAddress', i)}/>
                                </Col>
                            </Row>

                        ))
                }

                <Row className="mb-3">

                    <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                        {urlAddresses.length === 0  && <img className="vCard-icons" src={wwwIcon} />}
                    </Col>

                    <Col>
                        <span>{this.addWebText}</span>
                        <img src={addIcon} className="add-field-icon plus ml-2" onClick={() => this.props.addField('urlAddress')}/>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }

    getVieroUrl = (metadata) => {
        let url;

        url = getUrlAndLicence(metadata.storageUUID, false, this.thumbnailParams, 'VieroToolkitUtilsTypeStillSingle').url;
        return url;
    }

    getDate = (dateString) => {
        return dateString.replace(' ', 'T');
    }

    getPreview = () => {
        //let caseOf = 'skip';
        let preview;
        //let status = this.uploadingText;

        let tempStorageUUID = this.props.metadata && this.props.metadata.storageUUID ? this.props.metadata.storageUUID : '';

        this.props.checkIfUploading();
        if (isObjectExists(this.props, 'user.fireBaseStatus.' + tempStorageUUID) && this.props.user.fireBaseStatus[tempStorageUUID] !== 'ready') {
        //if ((isObjectExists(this.props, 'metadata.storageType') && this.props.metadata.storageType === 'viero' && this.props.getStatusOfMedia(this.props.metadata.storageUUID, 'capsuleMedia') === false)
        //|| this.props.metadata && this.props.user.gettingFireBase === true && this.props.user.fireBaseStatus && this.props.user.fireBaseStatus[this.props.metadata.storageUUID] !== 'ready' && this.props.user.fireBaseStatus[this.props.metadata.storageUUID] !== undefined) {
            //status = this.props.user.fireBaseStatus[this.props.metadata.storageUUID] === 'processing' ? this.processingText : this.uploadingText;
            let status = this.props.user.fireBaseStatus[tempStorageUUID];
            preview = (<div>{status}...</div>);
            //caseOf = 'loading or processing';
        } else if (this.props.metadata && this.props.metadata.storageType !== 'viero') {
            //caseOf = 'image is a URL';
            preview = (
                <img id="capsule-image" className={this.props.thumbnail !== '' ? 'hover profile-edit-photo radius img-fluid' : 'hover profile-photo-icon'} onClick={() => this.openModal()} src={
                    this.props.metadata && this.props.thumbnail !== '' ?
                        this.props.thumbnail.url
                        :
                        profilePhoto} />
            );
        } else if (isObjectExists(this.props, 'user.fireBaseStatus.' + tempStorageUUID) && this.props.user.fireBaseStatus[tempStorageUUID] === 'ready') {
            //caseOf = 'viero is ready';
            preview = (
                <img id="capsule-image" className='hover profile-edit-photo radius img-fluid' onClick={() => this.openModal()} src={
                    this.getVieroUrl(this.props.metadata)} />
            );
        //} else if ((this.props.metadata && this.props.thumbnail === '') || !isObjectExists(this.props, 'metadata.storageType')) {
        } else if (!(isObjectExists(this.props, 'metadata.storageType'))) {
            //caseOf = 'no image show icon';
            preview = (
                <img id="capsule-image" className='hover profile-photo-icon' onClick={() => this.openModal()} src={profilePhoto} />
            );
        } else if (!this.state.everUploadImage) {
            //caseOf = 'initial load';
            preview = (
                <img id="capsule-image" className={this.props.thumbnail !== '' ? 'hover profile-edit-photo radius img-fluid' : 'hover profile-photo-icon'} onClick={() => this.openModal()} src={
                    this.props.metadata && this.props.metadata.storageType === 'viero' ?
                        this.getVieroUrl(this.props.metadata)
                        :
                        this.props.thumbnail.url} />
            );
        }

        //console.log('caseOf thumbnail=', caseOf);   //need to uncomment caseOf assignments above 
        return preview;
    }

    render() {
        return (

            !this.props.isSectionOpen ?
                <Container className="profile-closed">
                    <Row onClick={() => this.props.toggleMenu('isVcardOpen')}>
                        <Col >
                            <div onClick={() => this.props.toggleMenu('isVcardOpen')}>
                                <span>Add vCard Info</span>

                            </div>

                        </Col>
                        <Col className="right"><img src={backButton} className="hover section-button"/></Col>
                    </Row>
                </Container>
                :
                <Collapse isOpen={this.props.isSectionOpen}>
                    <ModalView isOpen={this.state.isOpen}
                        toggle={() => this.openModal()}
                        onUpload={() =>this.openProfilePicDialog()}
                        onContinue={this.onUrlChange}
                        defaultValue={this.props.thumbnail.url}
                        title="Profile Picture"
                        modalType="media"
                        isProfile
                    />
                    <Container className="profile-card" >

                        <Row onClick={() => this.props.toggleMenu('isVcardOpen')} className="mb-2">
                            <Col className="font-weight-bold color-black">
                        Add vCard Info
                            </Col>
                            <Col className="right">
                                <img src={backButton} className="hover button-up section-button"/>
                            </Col>

                        </Row>

                        <Row className="input-row">
                            <Col xs="4" sm="4" md="4" lg="3" xl="3" className="text-center">
                                {this.getPreview()}
                                <input onChange={(e) => this.onUploadImage(e)} accept=".jpg, .jpeg, .png, .heic"  ref={this.inputProfilePicRef} type="file" style={{display: 'none'}}/>
                                {this.props.thumbnail &&
                                    <div onClick={() => this.props.removeUserImages('thumbnail')} className="navigation-button mb-10">{this.removeText}</div>
                                }
                            </Col>
                            <Col xs="8" sm="8" md="8" lg="9" xl="9" >
                                <input
                                    value={this.props.vCardData.firstName}
                                    onChange={(e) => this.onChange(e)}
                                    name="firstName"
                                    id="firstName"
                                    className="capsule-input mb-2"
                                    placeholder='First name'
                                />

                                <input
                                    value={this.props.vCardData.lastName}
                                    onChange={(e) => this.onChange(e)}
                                    name="lastName"
                                    id="lastName"
                                    className="capsule-input last-name mb-2"
                                    placeholder='Last name'
                                />

                                <input
                                    value={this.props.vCardData.company}
                                    onChange={(e) => this.onChange(e)}
                                    name="company"
                                    id="company"
                                    className="capsule-input mb-2"
                                    placeholder='Company'
                                />
                            </Col>
                        </Row>

                        {this.renderPhoneNumbers()}
                        {this.renderEmailAddresses()}

                        <Collapse isOpen={this.state.isShowMoreOpen}>
                            {this.renderUrlAddresses()}
                            {this.renderAddresses()}

                            <Row className="input-row">
                                <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                                    <img className="vCard-icons" src={birthDayIcon} />
                                </Col>
                                <Col xs="8" lg="8" md="8" sm="8">

                                    <DatePicker
                                        selected={this.props.vCardData.birthDay === '' ? null : moment(this.props.vCardData.birthDay).toDate()}
                                        onChange={(e) => this.onBirthDayChange(e)}
                                        placeHolderText="Select birthday"
                                        showYearDropdown
                                    />
                                    {/*<input
                                        value={this.props.vCardData.birthDay}
                                        onChange={(e) => this.onChange(e)}
                                        name="birthDay"
                                        id="birthDay"
                                        className="capsule-input"
                                        placeholder='Birthday'
                                    />*/}
                                </Col>
                                <Col xs="2" lg="2" md="2" sm="2">
                                </Col>
                            </Row>
                            {   Object.keys(this.props.vCardData.dates).map((i) => (
                                <Row key={i} className="input-row">
                                    <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                                        <img className="vCard-icons" src={calendarIcon} />
                                    </Col>
                                    <Col xs="3" lg="3" md="3" sm="3">
                                        <Input type="select" name="select" bsSize="sm" className="type-select"
                                            onChange={(e) => this.onChange(e, i, 'dateType')}>
                                            <option selected={this.props.vCardData.dates[i].label == 'anniversary'} value="anniversary">anniversary</option>
                                            <option selected={this.props.vCardData.dates[i].label == 'other'} value="other">other</option>
                                        </Input>
                                    </Col>
                                    <Col xs="5" sm="5" md="5" lg="5">
                                        {/*<input
                                        value={this.props.vCardData.date}
                                        onChange={(e) => this.onChange(e)}
                                        name="date"
                                        id="date"
                                        className="capsule-input"
                                        placeholder='Date'
                                    />*/}
                                        <DatePicker
                                            selected={this.props.vCardData.dates[i].value === '' ? null : moment(this.props.vCardData.dates[i].value).toDate()}
                                            onChange={(e) => this.onChange(e, i, 'dates')}
                                            placeHolderText="Select anniversary"
                                            className="date-field"
                                            showYearDropdown
                                        />
                                    </Col>
                                </Row>
                            )
                            )
                            }
                            <Row className="mb-3">

                                <Col xs="2" lg="2" md="2" sm="2" className="text-center">
                                    {this.props.vCardData.dates.length === 0  && <img className="vCard-icons" src={calendarIcon} />}
                                </Col>

                                <Col>
                                    <span>{this.addDateText}</span>
                                    <img src={addIcon} className="add-field-icon plus ml-2" onClick={() => this.props.addField('dates')}/>
                                </Col>
                            </Row>
                        </Collapse>
                        <Row>
                            <Col className={this.state.isShowMoreOpen ? 'show-more opened hover navigation-button' : 'show-more hover navigation-button'} onClick={() => this.setState({isShowMoreOpen: !this.state.isShowMoreOpen})}>
                                {this.state.isShowMoreOpen ? 'show less' : 'show more' }
                            </Col>
                        </Row>
                    </Container>
                </Collapse>

        );
    }
}

VCardEdit.propTypes = {
    dispatch:         PropTypes.func,
    //changeView:       PropTypes.func,
    onCapsuleChange:  PropTypes.func,
    vCardData:        PropTypes.object,
    removeFromSocial: PropTypes.func,
    addToSocial:      PropTypes.func,
    socialData:       PropTypes.object,
    user:             PropTypes.object,
    thumbnail:        PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    metadata:         PropTypes.object,
    uploadPicture:    PropTypes.func,
    toggleMenu:       PropTypes.func,
    isSectionOpen:    PropTypes.bool,
    //getStatusOfMedia: PropTypes.func,
    removeUserImages: PropTypes.func,
    removeField:      PropTypes.func,
    addField:         PropTypes.func,
    checkIfUploading: PropTypes.func
};
