import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'reactstrap';
import { Helmet } from 'react-helmet';
//import Div100vh from 'react-div-100vh';
import { isObjectExists } from '../../utils/Helpers';
//var ChatBotConfig = require('ChatBotConfig');

export class ChatbotView extends Component {

    constructor (props) {
        super(props);
        this.state = {
            loaded:      false,
            listenerSet: false,
            ready:       false
        };
    }

    componentDidMount() {

        if (!this.props.standalone) {
            window.addEventListener('resize', this.calculateHeight);
            this.calculateHeight();
            var checkExist = setInterval(function() {
                if (document.querySelector('#fanbot-chat-widget > div.input-wrapper > div > div.input-box > textarea')) {
                    document.querySelector('#fanbot-chat-widget > div.input-wrapper > div > div.input-box > textarea').addEventListener('click', () => {
                        document.querySelector('.message-box').scroll(0, document.querySelector('.message-box .container').scrollHeight);
                    });
                    if (document.getElementById('capsule-header') && document.getElementById('capsule-view')) {
                        let chatbotHeight =  document.getElementById('capsule-view').clientHeight - document.getElementById('capsule-header').clientHeight;
                        if (document.getElementById('multichat-chat-box')) {
                            document.getElementById('multichat-chat-box').style.height= chatbotHeight + 'px';
                            clearInterval(checkExist);
                        }
                    }
                }
            }, 100);

            document.documentElement.style.overflowY = 'hidden';
            document.getElementById('capsule-view').style.overflowX = 'hidden';

        }

        if (this.props.standalone) {
            //window.androidObj = function AndroidClass() {};
            localStorage.removeItem('fanbotWebChatClientIds');
            document.getElementsByClassName('background')[0].style = 'background-color: white';
            if (this.props.match.params.bid && !this.props.match.params.cid && this.props.match.params.uid) {
                /*
                 * if no url param we send the session info to the mobile app so they can store it
                 */
                this.setState({ready: true});
                var sendParams = setInterval(function(sendSession) {
                    if (localStorage.getItem('fanbotWebChatClientIds')) {
                        sendSession();
                        clearInterval(sendParams);
                    }

                }, 100, this.sendSessionToApp);
            } else if (this.props.match.params.bid && this.props.match.params.cid) {

                /*
                * if we have clientId and botId, set session into localstorage before loading bot
                */

                this.setChatbotParameters(this.props.match.params.bid, this.props.match.params.cid);
            } else {
                var setParams = setInterval(function(props, setFunc, param, setSession) {
                    if (isObjectExists(props, 'location.search') && param) {
                        setFunc(param);
                        setSession();
                        clearInterval(setParams);
                    }

                }, 100, this.props,  this.setChatbotParametersIfAvailable, this.props.match.params.uid, this.setChatSession);

                var saveSession = setInterval(function(props, setSession) {
                    if (localStorage.getItem('fanbotWebChatClientIds') && props.standalone) {
                        setSession();
                        clearInterval(saveSession);

                    }
                }, 100, this.props, this.setChatSession);

            }

        }

    }

    componentWillUnmount() {
        document.documentElement.style.overflowY = 'auto';
        document.getElementById('capsule-view').style.overflowX = 'auto';
    }

    sendSessionToApp = () => {
        let sessionObject = localStorage.getItem('fanbotWebChatClientIds');

        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
            window.webkit.messageHandlers.saveChatbotSession.postMessage(sessionObject);
        } else {
            window.androidObj.saveChatbotSession(JSON.stringify(sessionObject));
        }
    }

    setChatbotParameters = (botId, clientId) => {
        let sessionObject = JSON.stringify({[botId]: clientId});
        localStorage.setItem('fanbotWebChatClientIds', sessionObject);
        this.setState({ready: true});
    }

    setChatbotParametersIfAvailable = (id) => {
        let chatbots = localStorage.getItem('fanbotsByUser') ? JSON.parse(localStorage.getItem('fanbotsByUser')) : [];
        if ( this.checkIfExists(id) ) {
            if (this.checkIfSessionExists(id, this.props.match.params.bid)) {
                let clientIds = chatbots[id];
                localStorage.removeItem('fanbotWebChatClientIds');
                localStorage.setItem('fanbotWebChatClientIds', JSON.stringify(clientIds));
            }

        } else {
            localStorage.removeItem('fanbotWebChatClientIds');
        }
    }

    setChatSession = () => {
        let id = this.props.match.params.uid;

        let newObj = JSON.parse(localStorage.getItem('fanbotsByUser')) ? JSON.parse(localStorage.getItem('fanbotsByUser')) : {};
        let newItem = JSON.parse(localStorage.getItem('fanbotWebChatClientIds'));

        if (newItem) {
            newObj[id] = {...newObj[id], ...newItem};
            localStorage.setItem('fanbotsByUser', JSON.stringify(newObj));
        }

    }

    componentDidUpdate() {
        !this.props.standalone && this.calculateHeight();

        if (localStorage.getItem('fanbotWebChatClientIds') && !this.state.ready) {
            this.setState({ready: true});
        }
    }

    checkIfSessionExists = (id, botId) => {
        let chatSessionsStore = localStorage.getItem('fanbotsByUser') ? JSON.parse(localStorage.getItem('fanbotsByUser')) : {};
        let isExists = false;
        if (chatSessionsStore[id]) {
            for (let i in chatSessionsStore[id]) {
                if (i === botId) {
                    isExists = true;
                }
            }
        }

        return isExists;

    }

    checkIfExists = (id) => {
        let isExists = false;
        let chatSessionsStore = localStorage.getItem('fanbotsByUser') ? JSON.parse(localStorage.getItem('fanbotsByUser')) : {};

        for (let i in chatSessionsStore) {
            if (i === id) {
                isExists = true;
            }
        }

        return isExists;
    }

    getChatBotConfig = () => {
        let config, capsuleId;
        let chatbotCapsules = this.props.config.chatBotConfig;

        if (this.props.standalone && this.props.match.params.cid) {
            capsuleId = this.props.match.params.cid;
        } else if (this.props.standalone && this.props.match.params.bid) {
            config = {
                chatId: this.props.match.params.bid,
                color:  this.props.match.params.color ? this.props.match.params.color : '#c1c1c1'
            };

            return config;
        } else {
            capsuleId = this.props.capsuleToShow.id;
        }

        for (let i in chatbotCapsules) {
            if (chatbotCapsules[i].capsuleId === capsuleId) {
                config = chatbotCapsules[i];
            }
        }

        return config;
    }

    calculateHeight = () => {
        if (document.getElementById('capsule-header') && document.getElementById('capsule-view')) {
            let chatbotHeight =  document.getElementById('capsule-view').clientHeight - document.getElementById('capsule-header').clientHeight;
            if (document.getElementById('multichat-chat-box')) {
                document.getElementById('multichat-chat-box').style.height= chatbotHeight + 'px';
            }
        }
    }

    getStandaloneClass = () => {
        let elementClass = 'chatbot-standalone';

        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
            elementClass = 'chatbot-standalone-ios';
        }

        return elementClass;
    }

    initWebChat = () => {

        //window.webChatConfig = {'color': 'c1c1c1','id': '5ea9cd009937d402c991fa29','url': 'https://s3-eu-west-1.amazonaws.com/fanbot.ai/web-chat/'};
        window.webChatConfig = {'id': this.getChatBotConfig().chatId, 'url': 'https://s3-eu-west-1.amazonaws.com/fanbot.ai/web-chat/'};
        const script = document.createElement('script');
        script.src = 'https://s3-eu-west-1.amazonaws.com/fanbot.ai/web-chat/embedded/index.js';
        document.body.appendChild(script);
    }

    render() {
        let view;
        //let notStandalone = this.props.config.chatBotConfig && !this.props.standalone ? true : false;
        //let oldForm = this.props.standalone && queryString.parse(this.props.location.search).bid ? true : false;
        //let newForm = this.props.standalone && this.props.config.chatBotConfig && queryString.parse(this.props.location.search).cid ? true : false;

        view =
                <React.Fragment>
                    <Helmet>
                        <script>
                            {this.initWebChat()}
                        </script>
                        <script id="multichat-chat-import"></script>
                    </Helmet>
                    {this.props.standalone ?

                        <div id="multichat-chat-box" className={this.getStandaloneClass()}></div>

                        :
                        <Row><Col><div id="multichat-chat-box"></div></Col></Row>
                    }

                </React.Fragment>;

        return view;

    }

}

ChatbotView.propTypes = {
    dispatch:      PropTypes.func,
    isEdit:        PropTypes.func,
    capsules:      PropTypes.object,
    user:          PropTypes.object,
    capsuleToShow: PropTypes.object,
    onMenuChange:  PropTypes.func,
    isPreview:     PropTypes.bool,
    isFromGroups:  PropTypes.bool,
    config:        PropTypes.object,
    standalone:    PropTypes.bool,
    location:      PropTypes.object,
    match:         PropTypes.object,
};
