import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import { userActions } from '../redux/actions';
import { isObjectExists } from '../../utils/Helpers';
import {
    SortableContainer,
    SortableElement,
    arrayMove
} from 'react-sortable-hoc';

const SortableItem = SortableElement(({indexOfItem, value, config, shouldDisplay, changeVisibility, isExploreGroups}) =>
    <Row id={'item-' + indexOfItem}  className="group-element filter-page">
        <Col>
            {value.shared ? value.name + ' (shared)' : value.name}
        </Col>
        <Col className="right">
            {
                shouldDisplay(value.id, config) ?
                    <span onClick={() => changeVisibility(value.id, isExploreGroups)} className="circle-filled right group-circle" />
                    :
                    <span onClick={() => changeVisibility(value.id, isExploreGroups)} className="circle right group-circle" />
            }
        </Col>
    </Row>

);

const SortableList = SortableContainer(({items, ...props}) => {
    return (
        <ul className="list-unstyled">
            {items.map((value, index) => (
                <SortableItem
                    key={`item-${index}`} index={index}
                    value={value} shouldDisplay={props.shouldDisplay}
                    config={props.config}
                    changeVisibility={props.changeVisibility}
                    isExploreGroups={props.isExploreGroups}
                />
            ))}
            <span id="bottom"></span>
        </ul>
    );
});

class FilterGroups extends Component {

    constructor (props) {
        super(props);
        this.state = {
            tempGroups:           [],
            userConfigured:       false,
            exploreParam:         null,
            orderedUserGroups:    this.props.groups,
            orderedExploreGroups: this.props.exploreGroups,
            config:               this.getConfig(),
            configUpdated:        false,
            gotConfig:            false,
            showTop:              isObjectExists(this.props, 'user.config.topConfig') ? this.props.user.config.topConfig.showTop : true,
            isFeatured:           isObjectExists(this.props, 'user.config.topConfig') ? this.props.user.config.topConfig.isFeatured : true,
        };
        this.exploreGroups = 'Explore Public Groups';
        this.mostRecent = 'Most Recent';
        this.setGroups = 'Save';
        this.cancel = 'Cancel';
    }

    componentDidUpdate() {
        if (this.state.userConfigured) {
            this.props.onBack();
        }
    }

    isInConfig = (id, config) => {
        for ( let i in config) {
            if (config[i].groupId === id) {
                return true;
            }
        }

        return false;
    }

    shouldDisplay = (id, config) => {
        for ( let i in config) {
            if (config[i].groupId === id && config[i].display) {
                return true;
            }
        }

        return false;
    }

    getPartialConfig = (config, groups) => {
        let cleanConfig = [];
        for (let i in config) {
            if (config[i] instanceof Object) {
                cleanConfig.push(config[i]);
            }
        }

        config = cleanConfig;

        let index = config.length;

        if (groups && config.length < groups.length + 1) {
            for (let i in groups) {
                if (!this.isInConfig(groups[i].id, config)) {
                    config.push({
                        groupId: groups[i].id,
                        order:   String(index),
                        display: true
                    });
                    index++;
                }
            }
        } else {
            config = [];
            for (let i in groups) {

                config.push({
                    groupId: groups[i].id,
                    order:   String(index),
                    display: true
                });
                index++;

            }
        }
        return config;
    }

    getConfig = () => {
        let userConfig = [];
        let exploreConfig = [];

        exploreConfig = isObjectExists(this.props, 'user.config.explore_category') ? this.props.user.config.explore_category : [];
        userConfig = isObjectExists(this.props, 'user.config.my_category') ? this.props.user.config.my_category : [];

        let config = {
            explore_category: exploreConfig,
            my_category:      userConfig
        };

        return config;
    }

    configureUser = () => {

        if (localStorage.getItem('MUUserLogin')) {
            let topConfig = {
                showTop:    this.state.showTop,
                isFeatured: this.state.isFeatured
            };
            this.props.dispatch(userActions.configFilter({...this.state.config, topConfig}));
            this.props.setExploreGroups();
            this.props.onBack();

        } else if (localStorage.getItem('MUUser')) {
            this.props.onBack();
            this.setState({exploreParam: true, userConfigured: true});
        } else {
            this.setState({exploreParam: false, userConfigured: true});
        }
    }

    onExploreSortEnd = ({oldIndex, newIndex}) => {
        let reordered = this.state.orderedExploreGroups;
        let reorderedConfig = this.state.config.explore_category;
        let config = {
            my_category: this.state.config.my_category
        };
        reordered = arrayMove(reordered, oldIndex, newIndex);
        reorderedConfig = arrayMove(reorderedConfig, oldIndex, newIndex);
        for (let i in reordered) {
            reordered[i].order = parseInt(i);
        }

        for (let i in reorderedConfig) {
            reorderedConfig[i].order = parseInt(i);
        }

        config.explore_category = reorderedConfig;

        this.setState({
            orderedExploreGroups: reordered,
            config
        });

    };

    onUserSortEnd = ({oldIndex, newIndex}) => {
        let reordered = this.state.orderedUserGroups;
        let reorderedConfig = this.state.config.my_category;
        let config = {
            explore_category: this.state.config.explore_category
        };
        reordered = arrayMove(reordered, oldIndex, newIndex);
        reorderedConfig = arrayMove(reorderedConfig, oldIndex, newIndex);
        for (let i in reordered) {
            reordered[i].order = parseInt(i);
        }

        for (let i in reorderedConfig) {
            reorderedConfig[i].order = parseInt(i);
        }

        config.my_category = reorderedConfig;

        this.setState({
            orderedUserGroups: reordered,
            config
        });

    };

    changeVisibility = (id, isExplore) => {
        let partialConfig = isExplore ? this.state.config.explore_category : this.state.config.my_category;

        for (let i in partialConfig) {
            if (partialConfig[i].groupId === id) {
                partialConfig[i].display = !partialConfig[i].display;
            }
        }
        let config = {...this.state.config};
        isExplore ? config.explore_category = partialConfig : config.my_category = partialConfig;
        this.setState({config});

    }

    convertGroups = () => {
        let groups = this.props.groups;
        let result = [];

        for (let i in groups) {
            result.push({
                capsuleId: groups[i].id,
                order:     String(parseInt(i) + 1)
            });
        }

        return result;
    }

    orderGroups = (groups, config, isExploreGroup) => {
        let orderedGroups = [];
        let configGroups = [];

        for (let i in config) {
            for (let j in groups) {
                if (config[i].groupId === groups[j].id) {
                    orderedGroups.push(groups[j]);
                    configGroups.push({display: config[i].display, groupId: config[i].groupId, order: config[i].order, name: config[i].name });
                }
            }
        }

        let found;  //section added to add any exploreGroups enabled/added or disabled/deleted that is not in the userConfig while they are logged in. Backend auto updates user config on next login
        let needToUpdate = false;

        if (isExploreGroup) {
            for (let i in groups) {
                found = false;
                for (let j in configGroups) {
                    if (groups[i].id === configGroups[j].groupId) {
                        found = true;
                    }
                }
                if (!found) {
                    let order = 0;
                    for (let i in configGroups) {
                        if (order <= configGroups[i].order) {
                            order = configGroups[i].order + 1;
                        }
                    }
                    orderedGroups.push(groups[i]);
                    configGroups.push({display: true, groupId: groups[i].id, order: order, name: groups[i].name});
                    needToUpdate = true;
                }
            }
            if (needToUpdate || (groups && groups.length) < (config && config.length)) {
                if (!this.state.configUpdated) {
                    let currentConfig = this.state.config;
                    currentConfig.explore_category = configGroups;
                    this.setState({config: currentConfig, configUpdated: true});
                    this.getExploreList(); //called to fill in bubble for newly enabled public group
                }
            }
        }

        return orderedGroups;
    }

    getUserGroupList = () => {
        const groupList = this.orderGroups(this.state.orderedUserGroups, this.state.config.my_category, false);
        if (0 < groupList.length) {
            return <SortableList
                items={groupList}
                onSortEnd={this.onUserSortEnd}
                pressDelay={200}
                changeVisibility={this.changeVisibility}
                lockAxis={'y'}
                isExploreGroups={false}
                shouldDisplay={this.shouldDisplay}
                config={this.state.config.my_category}
                isExplore={false}

            />;
        }
    }

    getExploreList = () => {

        const groupList = this.orderGroups(this.state.orderedExploreGroups, this.state.config.explore_category, true);
        if (0 < groupList.length) {
            return <SortableList
                items={groupList}
                onSortEnd={this.onExploreSortEnd}
                pressDelay={200}
                changeVisibility={this.changeVisibility}
                lockAxis={'y'}
                isExploreGroups={true}
                shouldDisplay={this.shouldDisplay}
                config={this.state.config.explore_category}
                isExplore={true}

            />;
        }
    }

    changeShowFeatured = (value) => {
        this.setState({showTop: value});
    }

    switchFeatured = (value) => {
        this.setState({isFeatured: value});
    }

    listExploreGroups = () => {

        return (
            <React.Fragment>
                <div className="my-3 filter-line title-align">Show/Hide Groups</div>
                <Row className="ml-1">
                    <Col xs="4" sm="4" md="2" lg="2"
                        onClick={() => {this.switchFeatured(true);}}
                        className={this.state.isFeatured ? 'edit-option active title-align' : 'edit-option title-align'}>
                        Featured
                    </Col>
                    <Col xs="4" sm="4" md="2" lg="2"
                        onClick={() => {this.switchFeatured(false);}}
                        className={this.state.isFeatured ? 'preview-option' : 'preview-option active'}>
                            Favorites
                    </Col>
                    <Col className="right">
                        {this.state.showTop ?
                            <span onClick={() => this.changeShowFeatured(false)} className="circle-filled right group-circle-featured-filter-line" />
                            :
                            <span onClick={() => this.changeShowFeatured(true)} className="circle right group-circle-featured-filter-line" />}
                    </Col>
                </Row>
                <div className="my-3 filter-line title-align">MY GROUPS</div>
                {this.getUserGroupList()}
                <div className="my-3 filter-line title-align">PUBLIC GROUPS</div>
                {this.getExploreList()}
            </React.Fragment>

        );
    }

    render() {
        return (

            <Container>
                <div className="fixed-top container bg-black my-auto fixed-header-height">
                    <Row className="header mb-auto my-auto">
                        <Col className="my-auto"><span className="navigation-button" onClick={() => this.props.onBack()}>Back</span></Col>
                        <Col className="right mr-3 my-auto"><span className="navigation-button" onClick={() => this.configureUser()}>Done</span></Col>
                    </Row>
                </div>
                {
                    this.listExploreGroups()

                }

            </Container>

        );
    }

}

FilterGroups.propTypes = {
    capsules:             PropTypes.object,
    dispatch:             PropTypes.func,
    user:                 PropTypes.object,
    onBack:               PropTypes.func,
    afterNavigationSlide: PropTypes.func,
    exploreGroups:        PropTypes.array,
    onRemoveGroup:        PropTypes.func,
    onAddGroup:           PropTypes.func,
    getCapsulesOfGroup:   PropTypes.func,
    groups:               PropTypes.array,
    isExploreGroups:      PropTypes.bool,
    setExploreGroups:     PropTypes.func,
    switchFeatured:       PropTypes.func,
    isFeatured:           PropTypes.bool,
    showFeatured:         PropTypes.bool,
    changeShowFeatured:   PropTypes.func
};

export default FilterGroups;
