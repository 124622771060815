import { userService } from '../services/userService';
import { capsuleActions } from './capsuleActions';

export const userActions = {
    spawnAnonymousUser,
    loginWithKey,
    getCapsules,
    createCapsule,
    updateCapsule,
    deleteCapsule,
    uploadFile,
    getFiles,
    deleteFile,
    addImageOrVideoToCapsule,
    updateImageAndVideo,
    signUp,
    login,
    verifyEmail,
    getMe,
    logout,
    clearError,
    setUserObject,
    generateVideoPreview,
    resetPassword,
    setPassword,
    setDeleted,
    configFilter,
    getExploreCount,
    getFile,
    getFirebaseStatus,
    getUserCapsuleCount,
    verifyPin,
    updateEmail,
    refreshToken,
    updatePhoneNumber,
    setDefaultProps,
    updateUserCapsules,
    getMostRecentCount,
    getMainCapsule,
    connectToSendGrid,
    getPublicUser,
    setMainCapsule,
    syncGoogleContacts,
    getGoogleAuthLink,
    getGroupShareToken,
    getSharedGroups,
    setGroupInConfig,
    setUserCapsulesGroup,
    deleteMyAccount,
    cancelDeleteMyAccount,
    suspendMyAccount,
    setAccountDeleteStatus,
    setAccountCancelDeleteStatus,
    setAccountSuspendStatus,
    getVerifiedUsers,
    setGotVerifiedUsers,
    setUpdatedUserCanPublish,
    setCanPublishValue,
    setUpdatedUserBanned,
    setBannedValue
};

function getGroupShareToken(groupId) {

    return dispatch => {
        dispatch(request());

        userService.getGroupShareToken(groupId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_GROUP_SHARE_TOKEN_REQUEST'}; }
    function success(response) { return { type: 'GET_GROUP_SHARE_TOKEN_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_GROUP_SHARE_TOKEN_ERROR', error }; }
}

function getSharedGroups() {

    return dispatch => {
        dispatch(request());

        userService.getSharedGroups()
            .then(
                response => {
                    dispatch(capsuleActions.addSharedGroups(response));
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_SHARED_GROUPS_REQUEST'}; }
    function success(response) { return { type: 'GET_SHARED_GROUPS_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_SHARED_GROUPS_ERROR', error }; }
}

function signUp(token, email, password, name, title, phone) {
    return dispatch => {
        dispatch(request());

        userService.signUp(token, email, password, name, title, phone)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SIGN_UP_REQUEST'}; }
    function success(response) { return { type: 'SIGN_UP_SUCCESS', response }; }
    function failure(error) { return { type: 'SIGN_UP_ERROR', error }; }
}

function setMainCapsule(id) {
    return dispatch => {
        dispatch(request());

        userService.setMainCapsule(id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SET_MAIN_CAPSULE_REQUEST'}; }
    function success(response) { return { type: 'SET_MAIN_CAPSULE_SUCCESS', response }; }
    function failure(error) { return { type: 'SET_MAIN_CAPSULE_ERROR', error }; }
}

function getPublicUser(slug) {
    return dispatch => {
        dispatch(request());

        userService.getPublicUser(slug)
            .then(
                response => {
                    dispatch(capsuleActions.setGroups(response));
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_PUBLIC_ACCOUNT_REQUEST'}; }
    function success(response) { return { type: 'GET_PUBLIC_ACCOUNT_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_PUBLIC_ACCOUNT_ERROR', error }; }
}

function setDefaultProps() {
    return dispatch => {
        dispatch(success());
    };

    function success() { return { type: 'SET_USER_PROPS_DEFAULT'};}
}

function setGroupInConfig(id) {
    return dispatch => {
        dispatch(success());
    };

    function success() { return { type: 'SET_GROUP_CONFIG', id};}
}

function setUserCapsulesGroup(id, content) {
    return dispatch => {
        dispatch(success());
    };

    function success() { return { type: 'SET_CAPSULES_OF_GROUP_CONFIG', id, content};}
}

function spawnAnonymousUser(deviceName) {
    return dispatch => {
        dispatch(request());

        userService.spawnAnonymousUser(deviceName)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_ANONYMOUS_SPAWN_REQUEST'}; }
    function success(response) { return { type: 'GET_ANONYMOUS_SPAWN_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_ANONYMOUS_SPAWN_ERROR', error }; }
}

function verifyEmail(verificationToken) {
    return dispatch => {
        dispatch(request());

        userService.verifyEmail(verificationToken)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'VERIFY_EMAIL_REQUEST'}; }
    function success(response) { return { type: 'VERIFY_EMAIL_SUCCESS', response }; }
    function failure(error) { return { type: 'VERIFY_EMAIL_ERROR', error }; }
}

function setUpdatedUserBanned(value) {

    return dispatch => {
        dispatch(success(value));

    };

    function success(response) { return { type: 'SET_UPDATED_USER_BANNED', response }; }

}

function setBannedValue(userId, canPublishValue) {
    return dispatch => {
        dispatch(request());

        userService.setBannedValue(userId, canPublishValue)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SET_BANNED_VALUE_REQUEST'}; }
    function success(response) { return { type: 'SET_BANNED_VALUE_SUCCESS', response, userId, canPublishValue}; }
    function failure(error) { return { type: 'SET_BANNED_VALUE_ERROR', error }; }
}

function setGotVerifiedUsers(value) {

    return dispatch => {
        dispatch(success(value));

    };

    function success(response) { return { type: 'SET_GOT_VERIFIED_USERS', response }; }
}

function setUpdatedUserCanPublish(value) {

    return dispatch => {
        dispatch(success(value));

    };

    function success(response) { return { type: 'SET_UPDATED_USER_CAN_PUBLISH', response }; }

}

function getVerifiedUsers(verified) {
    return dispatch => {
        dispatch(request());

        userService.getVerifiedUsers(verified)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_VERIFIED_USERS_REQUEST'}; }
    function success(response) { return { type: 'GET_VERIFIED_USERS_SUCCESS', response}; }
    function failure(error) { return { type: 'GET_VERIFIED_USERS_ERROR', error }; }
}

function setCanPublishValue(userId, canPublishValue) {
    return dispatch => {
        dispatch(request());

        userService.setCanPublishValue(userId, canPublishValue)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SET_CAN_PUBLISH_VALUE_REQUEST'}; }
    function success(response) { return { type: 'SET_CAN_PUBLISH_VALUE_SUCCESS', response, userId, canPublishValue}; }
    function failure(error) { return { type: 'SET_CAN_PUBLISH_VALUE_ERROR', error }; }
}

function verifyPin(uuid, pin) {
    return dispatch => {
        dispatch(request());

        userService.verifyPin(uuid, pin)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'VERIFY_PIN_REQUEST'}; }
    function success(response) { return { type: 'VERIFY_PIN_SUCCESS', response }; }
    function failure(error) { return { type: 'VERIFY_PIN_ERROR', error }; }
}

function login(email, password) {
    return dispatch => {
        dispatch(request());

        userService.login(email, password)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'LOGIN_REQUEST'}; }
    function success(response) { return { type: 'LOGIN_SUCCESS', response }; }
    function failure(error) { return { type: 'LOGIN_ERROR', error }; }
}

function getMe(token) {
    return dispatch => {
        dispatch(request());

        userService.getMe(token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_USER_REQUEST'}; }
    function success(response) { return { type: 'GET_USER_SUCCESS', response, token }; }
    function failure(error) { return { type: 'GET_USER_ERROR', error }; }
}

function loginWithKey(userId, loginKey) {
    return dispatch => {
        dispatch(request());

        userService.loginWithKey(userId, loginKey)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_LOGIN_WITH_KEY_REQUEST'}; }
    function success(response) { return { type: 'GET_LOGIN_WITH_KEY_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_LOGIN_WITH_KEY_ERROR', error }; }
}

function updateUserCapsules(capsules) {
    return dispatch => {
        dispatch(request());

        dispatch(success(capsules));

    };

    function request() { return { type: 'UPDATE_USER_CAPSULES_REQUEST'}; }
    function success(response) { return { type: 'UPDATE_USER_CAPSULES_SUCCESS', response }; }
}

function getFirebaseStatus(id) {
    return dispatch => {
        dispatch(request());

        setTimeout(() => {
            dispatch(success('ready'));
        }, 1000);
        /*
        userService.getFirebaseStatus(id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
            */
    };

    function request() { return { type: 'GET_FIREBASE_STATUS_REQUEST'}; }
    function success(response) { return { type: 'GET_FIREBASE_STATUS_SUCCESS', response, id }; }
    // function failure(error) { return { type: 'GET_FIREBASE_STATUS_ERROR', error }; }
}

function getCapsules(isAfterCreate, isShare) {
    return dispatch => {
        dispatch(request());

        userService.getCapsules(isAfterCreate)
            .then(
                response => {
                    if (!isShare) {
                        dispatch(capsuleActions.userCapsulesToCapsuleList(response));
                    }
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_CAPSULES_REQUEST'}; }
    function success(response) {
        if (isAfterCreate) {
            return { type: 'GET_CAPSULES_AFTER_CREATE_SUCCESS', response };
        } else {
            return { type: 'GET_CAPSULES_SUCCESS', response };
        }
    }
    function failure(error) { return { type: 'GET_CAPSULES_ERROR', error }; }
}

function createCapsule(data) {
    return dispatch => {
        dispatch(request());

        userService.createCapsule(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'CREATE_CAPSULE_REQUEST'}; }
    function success(response) { return { type: 'CREATE_CAPSULE_SUCCESS', response }; }
    function failure(error) { return { type: 'CREATE_CAPSULE_ERROR', error }; }
}

function getMainCapsule(userToken) {
    return dispatch => {
        dispatch(request());

        userService.getMainCapsule(userToken)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_MAIN_CAPSULE_REQUEST'}; }
    function success(response) { return { type: 'GET_MAIN_CAPSULE_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_MAIN_CAPSULES_ERROR', error }; }
}

function syncGoogleContacts(code) {
    return dispatch => {
        dispatch(request());

        userService.syncGoogleContacts(code)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SYNC_GOOGLE_CONTACTS_REQUEST'}; }
    function success(response) { return { type: 'SYNC_GOOGLE_CONTACTS_SUCCESS', response }; }
    function failure(error) { return { type: 'SYNC_GOOGLE_CONTACTS_ERROR', error }; }
}

function getGoogleAuthLink() {
    return dispatch => {
        dispatch(request());

        userService.getGoogleAuthLink()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_GOOGLE_AUTH_LINK_REQUEST'}; }
    function success(response) { return { type: 'GET_GOOGLE_AUTH_LINK_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_GOOGLE_AUTH_LINK_ERROR', error }; }
}

function updateCapsule(data, capsuleId, setPublic, isFromExplore, skipAssembly) {
    return dispatch => {
        dispatch(request());

        userService.updateCapsule(data, capsuleId, setPublic, skipAssembly)
            .then(
                response => {
                    dispatch(capsuleActions.refreshCapsules(response));
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'UPDATE_CAPSULE_REQUEST'}; }
    function success(response) {
        if (isFromExplore) {
            return {type: 'UPDATE_CAPSULE_EXP_SUCCESS', response };
        } else if (skipAssembly) {
            return { type: 'UPDATE_MAIN_CAPSULE_SUCCESS', response };
        } else {
            return { type: 'UPDATE_CAPSULE_SUCCESS', response };
        }
    }
    function failure(error) { return { type: 'UPDATE_CAPSULE_ERROR', error }; }
}

function uploadFile(file, userToken, fileType) {
    return dispatch => {
        dispatch(request(fileType));

        switch (fileType) {
            case 'picture':
                userService.uploadFile(file[0], userToken)
                    .then(
                        response => {
                            dispatch(successPicture(response));
                            dispatch(getFiles(userToken));

                            //dispatch(this.addImageOrVideoToCapsule(capsuleId, response, userToken));
                        },
                        error => {
                            dispatch(failure(error));
                        }
                    );
                break;
            case 'audio':
                userService.uploadFile(file[0], userToken)
                    .then(
                        response => {
                            dispatch(successAudio(response));
                            dispatch(getFiles(userToken));

                            //dispatch(this.addImageOrVideoToCapsule(capsuleId, response, userToken));
                        },
                        error => {
                            dispatch(failure(error));
                        }
                    );

                break;
            case 'video':
                userService.uploadFile(file[0], userToken)
                    .then(
                        response => {
                            dispatch(successVideo(response));
                            dispatch(getFiles(userToken));

                            //dispatch(this.addImageOrVideoToCapsule(capsuleId, response, userToken));
                        },
                        error => {
                            dispatch(failure(error));
                        }
                    );

                break;
            case 'document':
                userService.uploadFile(file[0], userToken)
                    .then(
                        response => {
                            dispatch(successDocument(response));
                            dispatch(getFiles(userToken));

                            //dispatch(this.addImageOrVideoToCapsule(capsuleId, response, userToken));
                        },
                        error => {
                            dispatch(failure(error));
                        }
                    );

                break;
            case 'photo':
                userService.uploadFile(file[0], userToken)
                    .then(
                        response => {
                            dispatch(successPhoto(response));
                            dispatch(getFiles(userToken));

                            //dispatch(this.addImageOrVideoToCapsule(capsuleId, response, userToken));
                        },
                        error => {
                            dispatch(failure(error));
                        }
                    );

                break;
        }
    };

    function request(fileType) { return { type: 'UPLOAD_FILES_REQUEST', fileType: fileType}; }
    function successPicture(response) { return { type: 'UPLOAD_FILES_PICTURE_SUCCESS', response }; }
    function successVideo(response) { return { type: 'UPLOAD_FILES_VIDEO_SUCCESS', response }; }
    function successAudio(response) { return { type: 'UPLOAD_FILES_AUDIO_SUCCESS', response }; }
    function successDocument(response) { return { type: 'UPLOAD_FILES_DOCUMENT_SUCCESS', response }; }
    function successPhoto(response) { return { type: 'UPLOAD_FILES_PHOTO_SUCCESS', response }; }
    function failure(error) { return { type: 'UPLOAD_FILES_ERROR', error }; }
}

function getFiles(userToken) {
    return dispatch => {
        dispatch(request());

        userService.getFiles(userToken)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_FILES_REQUEST'}; }
    function success(response) { return { type: 'GET_FILES_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_FILES_ERROR', error }; }
}

function getFile(id) {
    return dispatch => {
        dispatch(request());

        userService.getFile(id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_FILE_REQUEST'}; }
    function success(response) { return { type: 'GET_FILE_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_FILE_ERROR', error }; }
}

function getExploreCount() {
    return dispatch => {
        dispatch(request());

        userService.getExploreCount()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_COUNT_REQUEST'}; }
    function success(response) { return { type: 'GET_COUNT_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_COUNT_ERROR', error }; }
}

function getMostRecentCount() {
    return dispatch => {
        dispatch(request());

        userService.getMostRecentCount()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_MOST_RECENT_COUNT_REQUEST'}; }
    function success(response) { return { type: 'GET_MOST_RECENT_COUNT_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_MOST_RECENT_COUNT_ERROR', error }; }
}

function getUserCapsuleCount() {
    return dispatch => {
        dispatch(request());

        userService.getUserCapsuleCount()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_USER_COUNT_REQUEST'}; }
    function success(response) { return { type: 'GET_USER_COUNT_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_COUNT_ERROR', error }; }
}

function refreshToken(callback) {
    return dispatch => {
        dispatch(request());

        userService.refreshToken(callback)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'REFRESH_TOKEN_REQUEST'}; }
    function success(response) { return { type: 'REFRESH_TOKEN_SUCCESS', response }; }
    function failure(error) { return { type: 'REFRESH_TOKEN_ERROR', error }; }
}

function connectToSendGrid(id, key, listId, group) {
    return dispatch => {
        dispatch(request());

        userService.connectToSendGrid(id, key, listId, group)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'CONNECT_SENDGRID_REQUEST'}; }
    function success(response) { return { type: 'CONNECT_SENDGRID_SUCCESS', response }; }
    function failure(error) { return { type: 'CONNECT_SENDGRID_ERROR', error }; }
}

function deleteFile(id, userToken) {
    return dispatch => {
        dispatch(request());

        userService.deleteFile(id, userToken)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'DELETE_FILE_REQUEST'}; }
    function success(response) { return { type: 'DELETE_FILE_SUCCESS', response }; }
    function failure(error) { return { type: 'DELETE_FILE_ERROR', error }; }
}

function generateVideoPreview(id) {
    return dispatch => {
        dispatch(request());

        userService.generateVideoPreview(id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GENERATE_PREVIEW_REQUEST'}; }
    function success(response) { return { type: 'GENERATE_PREVIEW_SUCCESS', response }; }
    function failure(error) { return { type: 'GENERATE_PREVIEW_ERROR', error }; }
}

function addImageOrVideoToCapsule(capsuleId, fileId, userToken) {
    return dispatch => {
        dispatch(request());

        userService.addImageOrVideoToCapsule(capsuleId, fileId, userToken)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'ADD_FILE_TO_CAPSULE_REQUEST'}; }
    function success(response) { return { type: 'ADD_FILE_TO_CAPSULE_SUCCESS', response }; }
    function failure(error) { return { type: 'ADD_FILE_TO_CAPSULE_ERROR', error }; }
}

function updateImageAndVideo(userToken, capsuleId, data) {
    return dispatch => {
        dispatch(request());

        userService.updateImageAndVideo(userToken, capsuleId, data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'UPDATE_IAV_REQUEST'}; }
    function success(response) { return { type: 'UPDATE_IAV_SUCCESS', response }; }
    function failure(error) { return { type: 'UPDATE_IAV_ERROR', error }; }
}

function deleteCapsule(capsuleId) {
    return dispatch => {
        dispatch(request());

        userService.deleteCapsule(capsuleId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'DELETE_CAPSULE_REQUEST'}; }
    function success(response) { userActions.getCapsules(); return { type: 'DELETE_CAPSULE_SUCCESS', response, capsuleId }; }
    function failure(error) { userActions.getCapsules(); return { type: 'DELETE_CAPSULE_ERROR', error }; }
}

function setUserObject() {
    return dispatch => {
        dispatch(clear());
    };
    function clear() { return { type: 'SET_USER_OBJECT'}; }
}

function resetPassword(email, isPhone) {
    return dispatch => {
        dispatch(request());

        userService.resetPassword(email, isPhone)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'RESET_PASSWORD_REQUEST'}; }
    function success(response) { return { type: 'RESET_PASSWORD_SUCCESS', response }; }
    function failure(error) { return { type: 'RESET_PASSWORD_ERROR', error }; }
}

function setPassword(newPassword, token) {
    return dispatch => {
        dispatch(request());

        userService.setPassword(newPassword, token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SET_PASSWORD_REQUEST'}; }
    function success(response) { return { type: 'SET_PASSWORD_SUCCESS', response }; }
    function failure(error) { return { type: 'SET_PASSWORD_ERROR', error }; }
}

function updateEmail(email) {
    return dispatch => {
        dispatch(request());

        userService.updateEmail(email)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'UPDATE_EMAIL_REQUEST'}; }
    function success(response) { return { type: 'UPDATE_EMAIL_SUCCESS', response }; }
    function failure(error) { return { type: 'UPDATE_EMAIL_ERROR', error }; }
}

function updatePhoneNumber(phoneNumber) {
    return dispatch => {
        dispatch(request());

        userService.updatePhoneNumber(phoneNumber)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'UPDATE_PHONE_REQUEST'}; }
    function success(response) { return { type: 'UPDATE_PHONE_SUCCESS', response }; }
    function failure(error) { return { type: 'UPDATE_PHONE_ERROR', error }; }
}

function setDeleted(value) {

    return dispatch => {
        dispatch(success(value));

    };

    function success(response) { return { type: 'SET_DELETED', response }; }
}

function setAccountDeleteStatus(value) {

    return dispatch => {
        dispatch(success(value));

    };

    function success(response) { return { type: 'SET_ACCOUNT_DELETE_STATUS', response }; }
}

function setAccountCancelDeleteStatus(value) {

    return dispatch => {
        dispatch(success(value));

    };

    function success(response) { return { type: 'SET_ACCOUNT_CANCEL_DELETE_STATUS', response }; }
}

function setAccountSuspendStatus(value) {

    return dispatch => {
        dispatch(success(value));

    };

    function success(response) { return { type: 'SET_ACCOUNT_SUSPEND_STATUS', response }; }
}

function configFilter(groups) {
    return dispatch => {
        dispatch(request());

        userService.configFilter(groups, true)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'CONFIG_USER_EXPLORE_REQUEST'}; }
    function success(response) { return { type: 'CONFIG_USER_EXPLORE_SUCCESS', response, groups }; }
    function failure(error) { return { type: 'CONFIG_USER_EXPLORE_ERROR', error }; }
}

function logout() {
    return dispatch => {
        dispatch(request());

        userService.logout()
            .then(
                response => {
                    dispatch(success(response));

                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'LOGOUT_REQUEST'}; }
    function success(response) { return { type: 'LOGOUT_SUCCESS', response }; }
    function failure(error) { return { type: 'LOGOUT_ERROR', error }; }
}

function deleteMyAccount() {
    return dispatch => {
        dispatch(request());

        userService.deleteMyAccount()
            .then(
                response => {
                    dispatch(success(response));

                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'DELETE_MY_ACCOUNT_REQUEST'}; }
    function success(response) { return { type: 'DELETE_MY_ACCOUNT_SUCCESS', response }; }
    function failure(error) { return { type: 'DELETE_MY_ACCOUNT_ERROR', error }; }
}

function cancelDeleteMyAccount() {
    return dispatch => {
        dispatch(request());

        userService.cancelDeleteMyAccount()
            .then(
                response => {
                    dispatch(success(response));

                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'CANCEL_DELETE_MY_ACCOUNT_REQUEST'}; }
    function success(response) { return { type: 'CANCEL_DELETE_MY_ACCOUNT_SUCCESS', response }; }
    function failure(error) { return { type: 'CANCEL_DELETE_MY_ACCOUNT_ERROR', error }; }
}

function suspendMyAccount() {
    return dispatch => {
        dispatch(request());

        userService.suspendMyAccount()
            .then(
                response => {
                    dispatch(success(response));

                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SUSPEND_MY_ACCOUNT_REQUEST'}; }
    function success(response) { return { type: 'SUSPEND_MY_ACCOUNT_SUCCESS', response }; }
    function failure(error) { return { type: 'SUSPEND_MY_ACCOUNT_ERROR', error }; }
}

function clearError() {
    return dispatch => {
        dispatch(clear());
    };
    function clear() { return { type: 'CLEAR_ERROR'}; }
}
