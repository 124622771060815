// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("./Wraps.css");
var ___HTML_LOADER_IMPORT_1___ = require("./wrapImages/M3DS Logo.png");
var ___HTML_LOADER_IMPORT_2___ = require("./wrapImages/ColbyRaha 198x3 TopBikeImageOnly.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<html> <head> <link rel=\"stylesheet\" href=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </head> <body> <div class=\"page-container bg-white\"> <div class=\"top-background text-center\"> <img class=\"img img-fluid float-left logo-size logo-six-position\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"/> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" class=\"img img-fluid colby-bike-image\"> </div> <div class=\"colby-bottom-background\"> </div> <div class=\"sixth-image-text\"> <div class=\"text-position-six\"> <div class=\"colby-page-name\">COLBY RAHA</div><span class=\"colby-side\">8 TIME X GAMES MEDALIST</span> <div class=\"colby-page-text\"> <div class=\"new-line\">\"I used M3DS salve to heal my</div> <div class=\"new-line\">finger when I got it chopped off</div> <div class=\"new-line\">The product helped the healing</div> <div class=\"new-line\">process so I was able to compete</div> <div class=\"new-line\">in the X games</div> <div class=\"new-line\">6 weeks later.\"</div> </div> </div> </div> </div> </body> </html> ";
// Exports
module.exports = code;