import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isObjectExists, getVcard } from '../../utils/Helpers';
import {Container, Row, Col} from 'reactstrap';
var moment = require('moment');

export class VcardView extends Component {

    constructor (props) {
        super(props);
        this.state = {
            gotToken:     false,
            vCard:        '',
            vCardHasData: false
        };

    }

    async componentDidMount() {
        this.props.checkIfScrollable();
        let vCard =  encodeURIComponent(await getVcard({...this.updateSocials(this.props.capsuleToShow.items.contact), capsuleName: this.props.capsuleToShow.name}));
        this.setState({vCard});
    }

    componentDidUpdate() {
        if (!this.state.vCardHasData) {
            this.isVCardDownloadReady();
        }
    }

    updateSocials = (contact) => {
        let modifiedContact = contact;
        if (isObjectExists(this.props, 'capsuleToShow.items.contact.socialProfiles') && 0 < contact.socialProfiles.length) {
            let socials = contact.socialProfiles;
            let modifiedSocials = [];
            for (let i in socials) {
                if (socials[i].value.toolbar === true) {
                    modifiedSocials.push(socials[i]);
                }
            }
            modifiedContact.socialProfiles = modifiedSocials;

        }

        return modifiedContact;
    }

    arrayHasValidItems = (arr, type) => {
        let result = false;

        switch (type) {
            case 'website':
                if (arr && 1 < arr.length || (arr.length === 1 && arr[0].value.urlString !== '')) {
                    for (let i in arr) {
                        if (arr[i].value.toolbar === true) {
                            result = true;
                            break;
                        }
                    }
                }
                break;
            case 'postal':
                if ((arr && 1 < arr.length) || (arr.length === 1 &&
                    arr[0].value.street !== '' &&
                    arr[0].value.city !== '' &&
                    arr[0].value.state !== '' &&
                    arr[0].value.postalCode !== '' &&
                    arr[0].value.country !== '')

                ) {
                    result = true;
                }
                break;
            case 'notes':
                if (arr && 1 < arr.length || arr && (arr.length === 1 && arr[0] !== '')) {
                    result = true;
                }
                break;
            default:
                if (arr && 1 < arr.length || arr && (arr.length === 1 && arr[0].value !== '')) {
                    result = true;
                }
                break;

        }
        return result;
    }

    getName = (firstName, lastName) => {
        let name = '';

        if (firstName !== '' && lastName !== '') {
            name = firstName + ' ' + lastName;
        } else if (firstName !== '' && lastName === '') {
            name = firstName;
        } else {
            name = lastName;
        }

        return name;
    }

    saveVcard = () => {
        let capsuleContactObject = this.props.capsuleToShow.items.contact;
        let vCardText = getVcard(capsuleContactObject);
        let type = 'text/vcard';

        var FileSaver = require('file-saver');
        var blob = new Blob([vCardText], {type: type + ';charset=utf-8'});
        FileSaver.saveAs(blob,  this.getName(this.props.capsuleToShow.items.contact.name.givenName, this.props.capsuleToShow.items.contact.name.familyName));
    }

    isVCardDownloadReady = () => {
        const socialProfiles = isObjectExists(this.props, 'capsuleToShow.items.contact.socialProfiles') ? this.props.capsuleToShow.items.contact.socialProfiles : [];
        const emailAddresses = isObjectExists(this.props, 'capsuleToShow.items.contact.emailAddresses') ? this.props.capsuleToShow.items.contact.emailAddresses : [];
        const phoneNumbers = isObjectExists(this.props, 'capsuleToShow.items.contact.phoneNumbers') ? this.props.capsuleToShow.items.contact.phoneNumbers : [];
        const postalAddresses = isObjectExists(this.props, 'capsuleToShow.items.contact.postalAddresses') ? this.props.capsuleToShow.items.contact.postalAddresses : [];
        const firstName = isObjectExists(this.props, 'capsuleToShow.items.contact.name.givenName') ? this.props.capsuleToShow.items.contact.name.givenName : '';
        const lastName =  isObjectExists(this.props, 'capsuleToShow.items.contact.name.familyName') ? this.props.capsuleToShow.items.contact.name.familyName : '';
        const birthDay = isObjectExists(this.props, 'capsuleToShow.items.contact.date.birthday') ? this.props.capsuleToShow.items.contact.date.birthday === '' ? null : new Date(this.props.capsuleToShow.items.contact.date.birthday) : null;
        const dates = isObjectExists(this.props, 'capsuleToShow.items.contact.date.dates') ? this.props.capsuleToShow.items.contact.date.dates : [];
        const notes = isObjectExists(this.props, 'capsuleToShow.items.contact.notes') ? this.props.capsuleToShow.items.contact.notes : null;

        if (firstName !== '' || lastName !== '' ||
        this.arrayHasValidItems(phoneNumbers) ||
        this.arrayHasValidItems(emailAddresses) ||
        this.arrayHasValidItems(socialProfiles, 'website') ||
        this.arrayHasValidItems(postalAddresses, 'postal') ||
        birthDay || this.arrayHasValidItems(dates) || this.arrayHasValidItems(notes, 'notes')
        ) {
            if (!this.state.vCardHasData) {
                this.setState({vCardHasData: true});
            }
        }
    }

    shouldShowvCard = (firstName, lastName, phoneNumbers, emailAddresses, socialProfiles, postalAddresses, birthDay, dates, notes) => {
        let result = false;
        if (firstName !== '' || lastName !== '' ||
        this.arrayHasValidItems(phoneNumbers) ||
        this.arrayHasValidItems(emailAddresses) ||
        this.arrayHasValidItems(socialProfiles, 'website') ||
        this.arrayHasValidItems(postalAddresses, 'postal') ||
        birthDay || this.arrayHasValidItems(dates) || this.arrayHasValidItems(notes, 'notes')
        ) {
            result = true;
        }

        return result;
    }

    /*
     *
     * Render the view of vCard
     *
     */

    rendervCard = () => {
        const socialProfiles = isObjectExists(this.props, 'capsuleToShow.items.contact.socialProfiles') ? this.props.capsuleToShow.items.contact.socialProfiles : [];
        const emailAddresses = isObjectExists(this.props, 'capsuleToShow.items.contact.emailAddresses') ? this.props.capsuleToShow.items.contact.emailAddresses : [];
        const phoneNumbers = isObjectExists(this.props, 'capsuleToShow.items.contact.phoneNumbers') ? this.props.capsuleToShow.items.contact.phoneNumbers : [];
        const postalAddresses = isObjectExists(this.props, 'capsuleToShow.items.contact.postalAddresses') ? this.props.capsuleToShow.items.contact.postalAddresses : [];
        const firstName = isObjectExists(this.props, 'capsuleToShow.items.contact.name.givenName') ? this.props.capsuleToShow.items.contact.name.givenName : '';
        const lastName =  isObjectExists(this.props, 'capsuleToShow.items.contact.name.familyName') ? this.props.capsuleToShow.items.contact.name.familyName : '';
        const birthDay = isObjectExists(this.props, 'capsuleToShow.items.contact.date.birthday') ? this.props.capsuleToShow.items.contact.date.birthday === '' ? null : new Date(this.props.capsuleToShow.items.contact.date.birthday) : null;
        const dates = isObjectExists(this.props, 'capsuleToShow.items.contact.date.dates') ? this.props.capsuleToShow.items.contact.date.dates : [];
        const notes = isObjectExists(this.props, 'capsuleToShow.items.contact.notes') ? this.props.capsuleToShow.items.contact.notes : null;

        if (this.shouldShowvCard(firstName, lastName, phoneNumbers, emailAddresses, socialProfiles, postalAddresses, birthDay, dates, notes)) {
            return (

                <Container>
                    {this.props.vCardHasData && this.props.vCard !== '' ?
                        <Col className="navigation-button right download-vcard">
                            <a download className="navigation-button"
                                href={'data:text/vcard;charset=utf8,' + this.props.vCard}>
                                            Download vCard
                            </a>
                        </Col>
                        :
                        <Col className="navigation-button right download-vcard text-white">
                            {/*}{this.isVCardDownloadReady()}*/}
                        </Col>
                    }
                    {(firstName !== '' || lastName !== '') &&
                    <React.Fragment>
                        <Row>
                            <Col xs="5" sm="6" md="6" lg="6">Name</Col>
                        </Row>
                        <Row>
                            <Col className="ml-3">
                                {
                                    this.getName(firstName, lastName)
                                }
                            </Col>
                        </Row>
                    </React.Fragment>
                    }
                    {this.arrayHasValidItems(phoneNumbers) &&
                     <React.Fragment>
                         <Row><Col >Number</Col></Row>
                         {
                             Object.keys(phoneNumbers).map((i) => (
                                 phoneNumbers[i].value !== '' &&
                         <React.Fragment key={'phoneNumbers-' + i}>
                             <Row><Col className="vcard-title ml-2">{phoneNumbers[i].label}</Col></Row>

                             <Row><Col className="vcard-title ml-3">
                                 <a href={'tel:' + phoneNumbers[i].value}>{phoneNumbers[i].value}</a>
                             </Col>
                             </Row>
                         </React.Fragment>

                             ))
                         }
                     </React.Fragment>
                    }
                    {this.arrayHasValidItems(emailAddresses) &&
                    <React.Fragment>
                        <Row><Col>Email</Col></Row>
                        {
                            Object.keys(emailAddresses).map((i) => (
                                emailAddresses[i].value !== '' &&
                        <React.Fragment key={'emailAddresses-' + i}>
                            <Row><Col className="vcard-title ml-2" lg={{size: 12}}>{emailAddresses[i].label}</Col></Row>
                            <Row>
                                <Col className="ml-3">
                                    <a href={'mailto:' + emailAddresses[i].value}>{emailAddresses[i].value}</a>
                                </Col></Row>
                        </React.Fragment>
                            ))
                        }
                    </React.Fragment>
                    }
                    {this.arrayHasValidItems(socialProfiles, 'website') &&
                    <React.Fragment>
                        <Row><Col>Websites</Col></Row>
                        {
                            Object.keys(socialProfiles).map((i) => (
                                socialProfiles[i].value.urlString !== '' && socialProfiles[i].value.toolbar === true &&
                        <React.Fragment key={'webaddress-' + i}>
                            <Row><Col className="vcard-title ml-2">{socialProfiles[i].label}</Col></Row>
                            <Row><Col className="ml-3"><a href={socialProfiles[i].value.urlString}>{socialProfiles[i].value.urlString}</a></Col></Row>
                        </React.Fragment>
                            ))
                        }
                    </React.Fragment>
                    }
                    {
                        this.arrayHasValidItems(postalAddresses, 'postal') &&
                        Object.keys(postalAddresses).map((i) => (
                            <React.Fragment key={i}>
                                <Row><Col>{'Address - ' + postalAddresses[i].label}</Col></Row>
                                <Row>
                                    <Col  className="ml-3">
                                        {
                                            postalAddresses[i].value.street + ', ' +
                                        postalAddresses[i].value.city + ', ' +
                                        postalAddresses[i].value.state + ', ' +
                                        postalAddresses[i].value.postalCode
                                        }
                                    </Col>
                                </Row>
                            </React.Fragment>
                        ))

                    }
                    {birthDay &&
                    <React.Fragment>
                        <Row>
                            <Col>Birthday</Col>
                        </Row>
                        <Row>
                            <Col className="ml-3">
                                <div>{moment(birthDay).format('LL')}</div>
                            </Col>
                        </Row>
                    </React.Fragment>
                    }
                    {this.arrayHasValidItems(dates) &&
                    <React.Fragment>
                        <Row><Col>Dates</Col></Row>
                        {
                            Object.keys(dates).map((i) => (
                                dates[i].value !== '' &&
                        <React.Fragment key={'dates-' + i}>
                            <Row><Col className="vcard-title ml-2">{dates[i].label}</Col></Row>
                            <Row>
                                <Col className="ml-3">
                                    {moment(dates[i].value).format('LL')}
                                </Col></Row>
                        </React.Fragment>
                            ))
                        }
                    </React.Fragment>
                    }
                    {this.arrayHasValidItems(notes, 'notes') &&
                    <React.Fragment>
                        <Row><Col>Notes</Col></Row>
                        {
                            Object.keys(notes).map((i) => (
                                notes[i] !== '' &&
                                <React.Fragment key={'notes-' + i}>
                                    <Row>
                                        <Col className="ml-3">
                                        &#8226;{' ' + notes[i]}
                                        </Col></Row>
                                </React.Fragment>
                            ))
                        }
                    </React.Fragment>
                    }

                </Container>
            );
        } else {

            return (
                <Container>
                    <Row>
                        <Col className="text-center">
                        No vCard Data has been added
                        </Col>
                    </Row>
                </Container>
            );
        }
    }

    render() {
        return this.rendervCard();

    }

}

VcardView.propTypes = {
    dispatch:          PropTypes.func,
    render:            PropTypes.string,
    capsules:          PropTypes.object,
    user:              PropTypes.object,
    capsuleToShow:     PropTypes.object,
    onMenuChange:      PropTypes.func,
    isPreview:         PropTypes.bool,
    isFromGroups:      PropTypes.bool,
    checkIfScrollable: PropTypes.func,
    vCard:             PropTypes.string,
    vCardHasData:      PropTypes.bool
};
