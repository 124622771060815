import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import { getCapsuleName, isObjectExists } from '../../utils/Helpers';
import { capsuleActions } from '../redux/actions';
//import subtractIcon from '../../assets/images/Subtract@3x.png';
import {
    SortableContainer,
    SortableElement,
    arrayMove
} from 'react-sortable-hoc';

//const SortableItem = SortableElement(({indexOfItem, value, onRemoveCapsule, getThumbnailPicture}) =>
const SortableItem = SortableElement(({indexOfItem, value, getThumbnailPicture}) =>
    <Row id={'item-' + indexOfItem} className="input-row">

        <Col xs="11" sm="11" md="11" lg="11">
            {getThumbnailPicture(value, true)}

            <div>{getCapsuleName(value)}</div>
            {
                value.ownerId ?
                    isObjectExists(value, 'items.contact.organisation.organizationName') && value.items.contact.organisation.organizationName !== '' && <div><span className="category">{value.items.contact.organisation.organizationName}</span></div>
                    :
                    value.category !== '' && <div><span className="category">{value.category}</span></div>
            }
        </Col>
        {/*}<Col className="my-auto">
            <img onClick={() => onRemoveCapsule(value.id)} className="manage-icons" src={subtractIcon} />
        </Col>*/}

    </Row>

);

const SortableList = SortableContainer(({items, ...props}) => {
    return (
        <ul className="list-unstyled title-align-reorder-left">
            {items.map((value, index) => (
                <SortableItem
                    key={`item-${index}`} index={index} value={value} onRemoveCapsule={props.onRemoveCapsule} getThumbnailPicture={props.getThumbnailPicture}
                />
            ))}
            <span id="bottom"></span>
        </ul>
    );
});

class FavoriteView extends Component {

    constructor (props) {
        super(props);
        this.state = {
            orderedFavorites: this.convertFavorites(),
            favorites:        this.props.favourites,
            tempGroups:       [],
            userConfigured:   false,
            exploreParam:     null
        };
        this.favorites = 'Favorites';
        this.saveFavorites = 'Save';
        this.cancel = 'Cancel';
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        let reordered = this.state.orderedFavorites;
        let reorderedView = this.state.favorites;
        reordered = arrayMove(this.state.orderedFavorites, oldIndex, newIndex);
        reorderedView = arrayMove(this.state.favorites, oldIndex, newIndex);

        for (let i in reordered) {
            reordered[i].order = parseInt(i) + 1;
        }
        this.setState({
            orderedFavorites: reordered,
            favorites:        reorderedView
        });

    };

    convertFavorites = () => {
        let favorites = this.props.favourites;
        let result = [];

        for (let i in favorites) {
            result.push({
                capsuleId: favorites[i].id,
                order:     parseInt(i) + 1
            });
        }

        return result;
    }

    onRemoveCapsule = (id) => {
        let orderedCapsules = this.state.orderedFavorites;
        let favorites = [...this.state.favorites];
        let indexToDelete;

        for (let i in orderedCapsules) {
            if (orderedCapsules[i].capsuleId === id) {
                indexToDelete = i;
                break;
            }
        }

        if (indexToDelete == 0) {
            orderedCapsules.splice(0, 1);
            for (let i in orderedCapsules) {
                orderedCapsules[i].order = orderedCapsules[i].order - 1;
            }
        } else {
            orderedCapsules.splice(indexToDelete, 1);

            for (let i in orderedCapsules) {
                if (parseInt(indexToDelete) <= parseInt(i)) {
                    orderedCapsules[i].order = orderedCapsules[i].order - 1;
                }}
        }
        favorites.splice(indexToDelete, 1);
        this.setState({favorites});
    }

    onSave = () => {
        this.props.dispatch(capsuleActions.addItemsToFavourites(this.state.orderedFavorites, this.state.favorites));
        this.onCancel();
    }

    onCancel = () => {
        this.props.back();
    }

    listExploreGroups = () => {
        const favourites = this.state.favorites;

        if (0 < favourites.length) {
            return <SortableList
                items={favourites}
                onSortEnd={this.onSortEnd}
                onRemoveCapsule={this.onRemoveCapsule}
                pressDelay={200}
                getThumbnailPicture={this.props.getThumbnailPicture}
                lockAxis={'y'}

            />;

        }
    }

    render() {
        return (

            <Container className="">
                <Row className="capsule-header">
                    <Col className="text-center reorder-title-line">{this.favorites}</Col>
                </Row>
                <Row className="group-element">
                    <Col className="p-2 text-center second-title-line">Drag Images to Reorder</Col>
                </Row>
                {
                    this.listExploreGroups()
                }
                <Row className="options">
                    <Col className="text-center">
                        <button type="button" onClick={() => this.onCancel()} className="btn btn-secondary reorder-cancel-button">{this.cancel}</button>
                    </Col>
                    <Col className="text-center">
                        <button type="button" onClick={() =>  this.onSave()} className="btn btn-light reorder-save-button">{this.saveFavorites}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

}

FavoriteView.propTypes = {
    favourites:          PropTypes.array,
    dispatch:            PropTypes.func,
    back:                PropTypes.func,
    groupsToAdd:         PropTypes.array,
    onRemoveGroup:       PropTypes.func,
    onAddGroup:          PropTypes.func,
    setGroupsToAdd:      PropTypes.func,
    getCapsulesOfGroup:  PropTypes.func,
    getThumbnailPicture: PropTypes.func
};

export default FavoriteView;
