import { capsuleService } from '../services/capsuleService';
import { assemblePayload } from '../helpers/payloadAssembler';
import { userActions } from './userActions';
//import { userActions } from './userActions';

export const capsuleActions = {
    getPublicCapsules,
    getCapsuleGroups,
    createCapsuleGroup,
    addCapsuleToGroup,
    removeCapsuleFromGroup,
    getCapsuleById,
    getCapsulesByGroup,
    previewCapsule,
    searchCapsulesByName,
    checkIfXframeOptions,
    importCapsuleFromMB,
    userCapsulesToCapsuleList,
    updateCapsuleGroup,
    editGroupCapsules,
    clearCapsules,
    deleteGroup,
    getShareToken,
    consumeShareToken,
    getSharedCapsules,
    clearGroupCapsules,
    getExploreGroups,
    getCapsulesOfExploreGroup,
    getUserCapsulesOfExploreGroup,
    setUserCapsulesForExploreGroup,
    setCapsulesToPublic,
    //getMostRecentCount,
    clearGroups,
    removeCapsuleFromShared,
    refreshCapsuleById,
    refreshCapsules,
    filterPrivateCapsules,
    getUserCapsules,
    addListToSearchResults,
    preloadSingleCapsule,
    getExternalCapsule,
    setCapsuleType,
    importContactsFromCsv,
    getFeaturedCapsules,
    getMainCapsule,
    updateMainCapsule,
    getFavourites,
    addToFavourites,
    removeFromFavourites,
    addItemsToFavourites,
    createShareForm,
    shareBack,
    shareBackCapsule,
    setGroups,
    addSharedGroups,
    removeGroupFromSharedWithMe,
    clearFavourites,
    getCapsuleSharedUsers,
    setGotCapsuleSharedUsers,
    revokeUserShare,
    setRevokedUserShare,
    removeSearchCapsuleDeleted,
    getGroupSharedUsers,
    revokeUserGroupShare,
    setRevokedUserGroupShare,
    setGotGroupSharedUsers,
    setMusicValue,
    updateShareToken

};

function setMusicValue(value) {
    return dispatch => {
        dispatch(request());

        capsuleService.setMusicValue(value)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );

    };

    function request() { return { type: 'SET_MUSIC_VALUE_REQUEST'}; }
    function success(response) { return { type: 'SET_MUSIC_VALUE_SUCCESS', response }; }
    function failure(error) { return { type: 'SET_MUSIC_VALUE_ERROR', error }; }
}

function getPublicCapsules(limit, offset, toList, isPagination) {
    return dispatch => {
        dispatch(request());

        capsuleService.getPublicCapsules(limit, offset)
            .then(
                response => {
                    if (toList) {
                        dispatch(successToList(response));
                    } else {
                        dispatch(success(response));
                    }

                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_PUBLIC_CAPSULE_REQUEST', capsuleType: 'all'}; }
    function success(response) { return { type: 'GET_PUBLIC_CAPSULE_SUCCESS', response, isPagination, capsuleType: 'all' }; }
    function successToList(response) { return { type: 'GET_PUBLIC_CAPSULE_TO_LIST_SUCCESS', response}; }
    function failure(error) { return { type: 'GET_PUBLIC_CAPSULE_ERROR', error }; }
}

function searchCapsulesByName(name, group, limit, offset, searchType, isPagination) {

    return dispatch => {
        dispatch(request());

        capsuleService.searchCapsulesByName(name, group, limit, offset, searchType)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SEARCH_CAPSULE_REQUEST', isPagination}; }
    function success(response) { return { type: 'SEARCH_CAPSULE_SUCCESS', response, searchType, isPagination }; }
    function failure(error) { return { type: 'SEARCH_CAPSULE_ERROR', error }; }
}

function preloadSingleCapsule(id) {

    return dispatch => {
        dispatch(request());

        capsuleService.preloadSingleCapsule(id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'PRELOAD_CAPSULE_REQUEST'}; }
    function success(response) { return { type: 'PRELOAD_CAPSULE_SUCCESS', response }; }
    function failure(error) { return { type: 'PRELOAD_CAPSULE_ERROR', error }; }
}

function shareBack(formId, email, phone, name) {

    return dispatch => {
        dispatch(request());

        capsuleService.shareBack(formId, email, phone, name)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SEND_SHARE_REQUEST'}; }
    function success(response) { return { type: 'SEND_SHARE_SUCCESS', response }; }
    function failure(error) { return { type: 'SEND_SHARE_ERROR', error }; }
}

function shareBackCapsule(formId, capsuleId) {

    return dispatch => {
        dispatch(request());

        capsuleService.shareBackCapsule(formId, capsuleId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SEND_CAPSULE_REQUEST'}; }
    function success(response) { return { type: 'SEND_CAPSULE_SUCCESS', response }; }
    function failure(error) { return { type: 'SEND_CAPSULE_ERROR', error }; }
}

function createShareForm(groupId) {

    return dispatch => {
        dispatch(request());

        capsuleService.createShareForm(groupId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'CREATE_SHARE_REQUEST'}; }
    function success(response) { return { type: 'CREATE_SHARE_SUCCESS', response }; }
    function failure(error) { return { type: 'CREATE_SHARE_ERROR', error }; }
}

function getCapsuleGroups(userToken) {
    return dispatch => {
        dispatch(request());

        capsuleService.getCapsuleGroups(userToken)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_CAPSULE_GROUPS_REQUEST'}; }
    function success(response) { return { type: 'GET_CAPSULE_GROUPS_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_CAPSULE_GROUPS_ERROR', error }; }
}

function getMainCapsule(userToken) {
    return dispatch => {
        dispatch(request());

        capsuleService.getMainCapsule(userToken)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_MAIN_CAPSULE_REQUEST'}; }
    function success(response) { return { type: 'GET_MAIN_CAPSULE_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_MAIN_CAPSULES_ERROR', error }; }
}

function updateMainCapsule(data, capsuleId) {
    return dispatch => {
        dispatch(request());

        capsuleService.updateMainCapsule(data, capsuleId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_MAIN_CAPSULE_REQUEST'}; }
    function success(response) { return { type: 'GET_MAIN_CAPSULE_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_MAIN_CAPSULES_ERROR', error }; }
}

function createCapsuleGroup(groupName, capsuleId) {
    return dispatch => {
        dispatch(request());

        capsuleService.createCapsuleGroup(groupName, capsuleId)
            .then(
                response => {
                    dispatch(userActions.setGroupInConfig(response));
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'CREATE_CAPSULE_GROUP_REQUEST'}; }
    function success(response) { return { type: 'CREATE_CAPSULE_GROUP_SUCCESS', response }; }
    function failure(error) { return { type: 'CREATE_CAPSULE_GROUP_ERROR', error }; }
}

function addCapsuleToGroup(groupId, capsuleId) {
    return dispatch => {
        dispatch(request());

        capsuleService.addCapsuleToGroup(groupId, capsuleId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'ADD_CAPSULE_TO_GROUP_REQUEST'}; }
    function success(response) { return { type: 'ADD_CAPSULE_TO_GROUP_SUCCESS', response }; }
    function failure(error) { return { type: 'ADD_CAPSULE_TO_GROUP_ERROR', error }; }
}

function removeCapsuleFromGroup(groupId, capsuleId) {
    return dispatch => {
        dispatch(request());

        capsuleService.removeCapsuleFromGroup(groupId, capsuleId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'REMOVE_CAPSULE_FROM_GROUP_REQUEST'}; }
    function success(response) { return { type: 'REMOVE_CAPSULE_FROM_GROUP_SUCCESS', response }; }
    function failure(error) { return { type: 'REMOVE_CAPSULE_FROM_GROUP_ERROR', error }; }
}

function updateCapsuleGroup(id, data)  {
    return dispatch => {
        dispatch(request());

        capsuleService.updateCapsuleGroup(id, data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'UPDATE_CAPSULE_GROUP_REQUEST'}; }
    function success(response) { return { type: 'EDIT_GROUP_NAME_SUCCESS', response }; }
    function failure(error) { return { type: 'UPDATE_CAPSULE_GROUP_ERROR', error }; }
}

function editGroupCapsules(id, data)  {
    return dispatch => {
        dispatch(request());

        capsuleService.editGroupCapsules(id, data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'UPDATE_CAPSULE_GROUP_REQUEST'}; }
    function success(response) { return { type: 'UPDATE_CAPSULE_GROUP_SUCCESS', response }; }
    function failure(error) { return { type: 'UPDATE_CAPSULE_GROUP_ERROR', error }; }
}

function deleteGroup(groupId) {
    return dispatch => {
        dispatch(request());

        capsuleService.deleteGroup(groupId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'DELETE_GROUP_BY_ID_REQUEST'}; }
    function success(response) { return { type: 'DELETE_GROUP_BY_ID_SUCCESS', response, groupId }; }
    function failure(error) { return { type: 'DELETE_GROUP_BY_ID_ERROR', error }; }
}

function removeCapsuleFromShared(capsuleId, userId) {
    return dispatch => {
        dispatch(request());

        capsuleService.removeCapsuleFromShared(capsuleId, userId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'DELETE_CAPSULE_FROM_SHARED_REQUEST'}; }
    function success(response) { return { type: 'DELETE_CAPSULE_FROM_SHARED_SUCCESS', response }; }
    function failure(error) { return { type: 'DELETE_CAPSULE_FROM_SHARED_ERROR', error }; }
}

function removeGroupFromSharedWithMe(groupId) {
    return dispatch => {
        dispatch(request());

        capsuleService.removeGroupFromSharedWithMe(groupId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'REMOVE_GROUP_FROM_SHARED_WITH_ME_REQUEST'}; }
    function success(response) { return { type: 'REMOVE_GROUP_FROM_SHARED_WITH_ME_SUCCESS', response, groupId }; }
    function failure(error) { return { type: 'REMOVE_GROUP_FROM_SHARED_WITH_ME_ERROR', error }; }
}

function getCapsuleById(capsuleId) {
    return dispatch => {
        dispatch(request());

        capsuleService.getCapsuleById(capsuleId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_CAPSULE_BY_ID_REQUEST'}; }
    function success(response) { return { type: 'GET_CAPSULE_BY_ID_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_CAPSULE_BY_ID_ERROR', error }; }
}

function getExternalCapsule(type, id) {
    return dispatch => {
        dispatch(request());

        capsuleService.getExternalCapsule(type, id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_CAPSULE_BY_ID_REQUEST'}; }
    function success(response) { return { type: 'GET_CAPSULE_BY_ID_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_CAPSULE_BY_ID_ERROR', error }; }
}

function refreshCapsuleById(capsuleId) {
    return dispatch => {
        dispatch(request());

        capsuleService.getCapsuleById(capsuleId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'REFRESH_CAPSULE_BY_ID_REQUEST'}; }
    function success(response) { return { type: 'REFRESH_CAPSULE_BY_ID_SUCCESS', response }; }
    function failure(error) { return { type: 'REFRESH_CAPSULE_BY_ID_ERROR', error }; }
}

function refreshCapsules(capsule) {
    return dispatch => {
        dispatch(success(capsule));
    };

    function success(capsule) { return { type: 'REFRESH_CAPSULES_SUCCESS', capsule }; }
}

function addSharedGroups(groups) {
    return dispatch => {
        dispatch(success(groups));
    };

    function success(groups) { return { type: 'ADD_SHARED_GROUPS_SUCCESS', groups }; }
}

function previewCapsule(data) {
    return dispatch => {
        dispatch(success(JSON.parse(assemblePayload(data, true))));

    };
    function success(response) { return { type: 'GET_CAPSULE_BY_ID_SUCCESS', response }; }
}

function clearCapsules() {
    return dispatch => {
        dispatch(success());
    };
    function success(response) { return { type: 'CLEAR_CAPSULES_SUCCESS', response }; }
}

function clearFavourites() {
    return dispatch => {
        dispatch(success());
    };
    function success(response) { return { type: 'CLEAR_FAVOURITES_SUCCESS', response }; }
}

function clearGroupCapsules() {
    return dispatch => {
        dispatch(success());
    };
    function success(response) { return { type: 'CLEAR_GROUP_CAPSULES_SUCCESS', response }; }
}

function clearGroups() {
    return dispatch => {
        dispatch(success());
    };
    function success(response) { return { type: 'CLEAR_GROUPS_SUCCESS', response }; }
}

function importContactsFromCsv(data, groupName) {
    return dispatch => {
        dispatch(request());

        capsuleService.importContactsFromCsv(data, groupName)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'IMPORT_CONTACTS_REQUEST'}; }
    function success(response) {
        return { type: 'IMPORT_CONTACTS_SUCCESS', response };
    }
    function failure(error) { return { type: 'IMPORT_CONTACTS_ERROR', error }; }
}

function getCapsulesByGroup(groupId) {
    return dispatch => {
        dispatch(request());

        capsuleService.getCapsulesByGroup(groupId)
            .then(
                response => {
                    dispatch(userCapsulesToCapsuleList(response));
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_CAPSULE_GROUP_BY_ID_REQUEST'}; }
    function success(response) { return { type: 'GET_CAPSULE_GROUP_BY_ID_SUCCESS', response, groupId }; }
    function failure(error) { return { type: 'GET_CAPSULE_GROUP_BY_ID_ERROR', error }; }
}

function userCapsulesToCapsuleList(capsules) {

    return dispatch => {

        dispatch(success(capsules));

    };

    function success(response) { return { type: 'USER_CAPSULES_TO_CAPSULE_LIST_SUCCESS', response }; }
}

function addListToSearchResults(list) {
    return dispatch => {
        dispatch(request());
        dispatch(success(list));

    };

    function request() { return { type: 'LIST_TO_RESULTS_REQUEST'}; }
    function success(response) { return { type: 'LIST_TO_RESULTS_SUCCESS', response }; }
}

function checkIfXframeOptions(url) {
    return dispatch => {
        dispatch(request());

        capsuleService.checkIfXframeOptions(url)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'CHECK_IF_XFRAME_REQUEST'}; }
    function success(response) { return { type: 'CHECK_IF_XFRAME_SUCCESS', response }; }
    function failure(error) { return { type: 'CHECK_IF_XFRAME_ERROR', error }; }
}

function importCapsuleFromMB(id) {
    return dispatch => {
        dispatch(request());

        capsuleService.importCapsuleFromMB(id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'IMPORT_FROM_MB_REQUEST'}; }
    function success(response) { return { type: 'IMPORT_FROM_MB_SUCCESS', response }; }
    function failure(error) { return { type: 'IMPORT_FROM_MB_ERROR', error }; }
}

function getShareToken(id, durationDays, durationHours, durationMinutes) {
    return dispatch => {
        dispatch(request());

        capsuleService.getShareToken(id, durationDays, durationHours, durationMinutes)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_SHARE_TOKEN_REQUEST'}; }
    function success(response) { return { type: 'GET_SHARE_TOKEN_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_SHARE_TOKEN_ERROR', error }; }
}

function updateShareToken(id, durationDays, durationHours, durationMinutes) {
    return dispatch => {
        dispatch(request());

        capsuleService.updateShareToken(id, durationDays, durationHours, durationMinutes)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'UPDATE_SHARE_TOKEN_REQUEST'}; }
    function success(response) { return { type: 'UPDATE_SHARE_TOKEN_SUCCESS', response }; }
    function failure(error) { return { type: 'UPDATE_SHARE_TOKEN_ERROR', error }; }
}

function consumeShareToken(token) {
    return dispatch => {
        dispatch(request());

        capsuleService.consumeShareToken(token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'CONSUME_SHARE_TOKEN_REQUEST'}; }
    function success(response) { return { type: 'CONSUME_SHARE_TOKEN_SUCCESS', response }; }
    function failure(error) { return { type: 'CONSUME_SHARE_TOKEN_ERROR', error }; }
}

function getFeaturedCapsules() {
    return dispatch => {
        dispatch(request());

        capsuleService.getFeaturedCapsules()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_FEATURED_CAPSULES_REQUEST'}; }
    function success(response) { return { type: 'GET_FEATURED_CAPSULES_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_FEATURED_CAPSULES_ERROR', error }; }
}

function getFavourites() {
    return dispatch => {
        dispatch(request());

        capsuleService.getFavourites()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_FAVOURITE_CAPSULES_REQUEST'}; }
    function success(response) { return { type: 'GET_FAVOURITE_CAPSULES_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_FAVOURITE_CAPSULES_ERROR', error }; }
}

function addToFavourites(id, capsule) {
    return dispatch => {
        dispatch(request());

        capsuleService.addToFavourites(id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'ADD_FAVOURITE_CAPSULE_REQUEST'}; }
    function success(response) { return { type: 'ADD_FAVOURITE_CAPSULE_SUCCESS', response, capsule: [capsule] }; }
    function failure(error) { return { type: 'ADD_FAVOURITE_CAPSULE_ERROR', error }; }
}

function removeFromFavourites(id) {
    return dispatch => {
        dispatch(request());

        capsuleService.removeFromFavourites(id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'REMOVE_FAVOURITE_CAPSULE_REQUEST'}; }
    function success(response) { return { type: 'REMOVE_FAVOURITE_CAPSULE_SUCCESS', response, id }; }
    function failure(error) { return { type: 'REMOVE_FAVOURITE_CAPSULE_ERROR', error }; }
}

function addItemsToFavourites(items, favorites) {
    return dispatch => {
        dispatch(request());

        capsuleService.addItemsToFavourites(items)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'ADD_FAVOURITE_CAPSULES_REQUEST'}; }
    function success(response) { return { type: 'ADD_FAVOURITE_CAPSULES_SUCCESS', response, favorites }; }
    function failure(error) { return { type: 'ADD_FAVOURITE_CAPSULES_ERROR', error }; }
}

function getSharedCapsules(limit, offset, isPagination) {
    return dispatch => {
        dispatch(request());

        capsuleService.getSharedCapsules(limit, offset)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_SHARED_CAPSULES_REQUEST', isPagination}; }
    function success(response) { return { type: 'GET_SHARED_CAPSULES_SUCCESS', response, isPagination }; }
    function failure(error) { return { type: 'GET_SHARED_CAPSULES_ERROR', error }; }
}

function getExploreGroups() {
    return dispatch => {
        dispatch(request());

        capsuleService.getExploreGroups()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_EXPLORE_GROUPS_REQUEST'}; }
    function success(response) { return { type: 'GET_EXPLORE_GROUPS_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_EXPLORE_GROUPS_ERROR', error }; }
}

function setCapsuleType(type) {
    return dispatch => {

        dispatch(success(type));

    };

    function success(response) { return { type: 'SET_CAPSULE_TYPE_SUCCESS', response }; }
}

{/* function getMostRecentCount() {
    return dispatch => {
        dispatch(request());

        capsuleService.getCapsulesOfExploreGroup(['all'])
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_MOST_RECENT_COUNT_REQUEST'}; }
    function success(response) { return { type: 'GET_MOST_RECENT_COUNT_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_MOST_RECENT_COUNT_ERROR', error }; }
} */}

function getUserCapsules(limit, offset, isPagination) {

    return dispatch => {
        dispatch(request());

        capsuleService.getUserCapsules(limit, offset)
            .then(
                response => {
                    //dispatch(userActions.updateUserCapsules(response));
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_PUBLIC_CAPSULE_REQUEST', isPagination, capsuleType: 'user'}; }
    function success(response) { return { type: 'GET_PUBLIC_CAPSULE_SUCCESS', response, isPagination, capsuleType: 'user' }; }
    function failure(error) { return { type: 'GET_PUBLIC_CAPSULE_ERROR', error }; }
}

function getCapsulesOfExploreGroup(groups, limit, offset, isPagination) {
    let isMostRecent = groups === 'all' ? true : false;
    return dispatch => {
        dispatch(request());

        capsuleService.getCapsulesOfExploreGroup(groups, limit, offset)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_PUBLIC_CAPSULE_REQUEST', isPagination, capsuleType: groups}; }
    function success(response) { return { type: 'GET_PUBLIC_CAPSULE_SUCCESS', response, isMostRecent, isPagination, capsuleType: groups }; }
    function failure(error) { return { type: 'GET_PUBLIC_CAPSULE_ERROR', error }; }
}

function getCapsuleSharedUsers(capsuleId) {
    return dispatch => {
        dispatch(request());

        capsuleService.getCapsuleSharedUsers(capsuleId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_CAPSULE_SHARED_USERS_REQUEST'}; }
    function success(response) { return { type: 'GET_CAPSULE_SHARED_USERS_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_CAPSULE_SHARED_USERS_ERROR', error }; }
}

function getGroupSharedUsers(groupId) {
    return dispatch => {
        dispatch(request());

        capsuleService.getGroupSharedUsers(groupId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_GROUP_SHARED_USERS_REQUEST'}; }
    function success(response) { return { type: 'GET_GROUP_SHARED_USERS_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_GROUP_SHARED_USERS_ERROR', error }; }
}

function removeSearchCapsuleDeleted(capsuleId) {

    return dispatch => {
        dispatch(success(capsuleId));

    };

    function success(response) { return { type: 'REMOVE_SEARCH_CAPSULE_DELETED_SUCCESS', response }; }

}

function setGotCapsuleSharedUsers(value) {

    return dispatch => {
        dispatch(success(value));

    };

    function success(response) { return { type: 'SET_GOT_CAPSULE_SHARED_USERS', response }; }
}

function setRevokedUserShare(value) {

    return dispatch => {
        dispatch(success(value));

    };

    function success(response) { return { type: 'SET_REVOKED_USER_SHARE', response }; }
}

function setRevokedUserGroupShare(value) {

    return dispatch => {
        dispatch(success(value));

    };

    function success(response) { return { type: 'SET_REVOKED_USER_GROUP_SHARE', response }; }
}

function setGotGroupSharedUsers(value) {

    return dispatch => {
        dispatch(success(value));

    };

    function success(response) { return { type: 'SET_GOT_GROUP_SHARED_USERS', response }; }
}

function revokeUserShare(capsuleId, user2Id) {
    return dispatch => {
        dispatch(request());

        capsuleService.revokeUserShare(capsuleId, user2Id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'REVOKE_USER_SHARE_REQUEST'}; }
    function success(response) { return { type: 'REVOKE_USER_SHARE_SUCCESS', response, capsuleId, user2Id }; }
    function failure(error) { return { type: 'REVOKE_USER_SHARE_REQUEST_ERROR', error }; }
}

function revokeUserGroupShare(groupId, user2Id) {
    return dispatch => {
        dispatch(request());

        capsuleService.revokeUserGroupShare(groupId, user2Id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'REVOKE_USER_GROUP_SHARE_REQUEST'}; }
    function success(response) { return { type: 'REVOKE_USER_GROUP_SHARE_SUCCESS', response, groupId, user2Id }; }
    function failure(error) { return { type: 'REVOKE_USER_GROUP_SHARE_REQUEST_ERROR', error }; }
}

function getUserCapsulesOfExploreGroup(id) {
    return dispatch => {
        dispatch(request());

        capsuleService.getCapsulesOfExploreGroup(id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_USER_CAPSULE_EXPLORE_GROUP_REQUEST'}; }
    function success(response) { return { type: 'GET_USER_CAPSULE_EXPLORE_GROUP_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_USER_CAPSULE_EXPLORE_GROUP_ERROR', error }; }
}

function setUserCapsulesForExploreGroup(id, capsules) {
    return dispatch => {
        dispatch(request());

        capsuleService.setUserCapsulesForExploreGroup(id, capsules)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SET_USER_CAPSULE_EXPLORE_GROUP_REQUEST'}; }
    function success(response) { return { type: 'SET_USER_CAPSULE_EXPLORE_GROUP_SUCCESS', response }; }
    function failure(error) { return { type: 'SET_USER_CAPSULE_EXPLORE_GROUP_ERROR', error }; }
}

function setCapsulesToPublic(capsules) {
    return dispatch => {
        dispatch(request());

        capsuleService.setCapsulesToPublic(capsules)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'SET_CAPSULES_PUBLIC_REQUEST'}; }
    function success(response) { return { type: 'SET_CAPSULES_PUBLIC_SUCCESS', response }; }
    function failure(error) { return { type: 'SET_CAPSULES_PUBLIC_ERROR', error }; }
}

function filterPrivateCapsules() {
    return dispatch => {
        dispatch(success());

        function success() { return { type: 'FILTER_VISIBILITY_SUCCESS'}; }
    };
}

function setGroups(response) {
    return dispatch => {
        dispatch(success());

        function success() { return { type: 'SET_GROUPS_SUCCESS', response};}
    };
}
