/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
var QRCode = require('qrcode.react');
import { Row, Container, Col } from 'reactstrap';
import { capsuleActions } from '../redux/actions';
import messageIcon from '../../assets/images/MessageActive@3x.png';
import messengerIcon from '../../assets/images/Messenger@3x.png';
import twitterIcon from '../../assets/images/TwitterActive@3x.png';
import './CapsuleView.css';
//import favIcon from '../../assets/images/favicon.ico';
//import { getUrlAndLicence } from '../../utils/VieroUtils';
//import { isObjectExists } from '../../utils/Helpers';
import {ModalView} from '../profile/ModalView';

class ShareView extends Component {

    constructor (props) {
        super(props);
        this.state = {
            gotToken:        false,
            isShare:         false,
            copied:          false,
            shareType:       'share',
            groupId:         '',
            shareLink:       '',
            newGroupName:    '',
            showOptions:     false,
            updateDuration:  false,
            updatedDuration: false,
            shareDurationD:  '3',        //backend sets default share link to 3 days but if needed web and iOS can set default value.
            shareDurationH:  '0',
            shareDurationM:  '0'
        };

        this.days = 'D: ';
        this.hours = 'H: ';
        this.minutes = 'M: ';
    }

    componentDidMount() {
        if (-1 !== window.location.href.indexOf('share_token') || (-1 === window.location.href.indexOf('share_token') && -1 !== window.location.href.indexOf('capsules'))) {
            this.setState({isShare: true});
        }

        if ((this.props.capsuleToShow.ownerId && this.props.user.id && this.props.capsuleToShow.ownerId === this.props.user.id) || this.props.isPreview) {
            this.props.dispatch(capsuleActions.getShareToken(this.props.capsuleToShow.id, this.state.shareDurationD, this.state.shareDurationH, this.state.shareDurationM));
            this.props.isShareBack === true && this.getReverseShareLink();
        }

    }

    componentDidUpdate(prevProps) {

        let background = 'https://myuniverse.com/favicon.ico';
        //let background = favIcon;

        /*if (isObjectExists(this.props, 'capsuleToShow.items.contact.image.profile.metadata.storageUUID') && this.props.capsuleToShow.items.contact.image.profile.metadata.storageUUID !== '') {
            background = getUrlAndLicence(this.props.capsuleToShow.items.contact.image.profile.metadata.storageUUID, false, '200x200', 'VieroToolkitUtilsTypeStillSingle').url;
        }*/

        /*let ogParams = '&title=' + this.props.capsuleToShow.name +
        '&description=MyUniverse capsule' +
        '&type=website' +
        '&url=' + process.env.REACT_APP_BASE_URI +
        '&image=' + background;*/

        let ogParams = '&image=' + background;

        if (!this.state.gotToken && this.props.capsules.shareToken && this.props.capsules.shareToken !== '' && this.state.shareType === 'share') {
            this.setState({shareToken: this.props.capsules.shareToken, gotToken: true});
            window.history.pushState('', '', '/capsules/' + this.props.capsuleToShow.id + '?share_token=' + this.props.capsules.shareToken + ogParams);
            //window.history.pushState('', '', '/capsules/' + this.props.capsuleToShow.id + '?share_token=' + this.props.capsules.shareToken);
            if (this.state.updateDuration === true && this.state.updatedDuration === false) {
                console.log('hello got here1');
                this.setState({updatedDuration: true});
            }
        }

        if (!prevProps.capsules.groupCreated && this.props.capsules.groupCreated) {
            this.props.dispatch(capsuleActions.createShareForm(this.props.capsules.newGroup.id));
        }

    }

    componentWillUnmount() {
        if (!this.state.isShare) {
            window.history.pushState('', '', '/');
        }
    }

    copytoClipboard = (url) => {

        var textArea = document.createElement('textarea');
        textArea.value = url;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            this.setState({copied: successful});
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }

        document.body.removeChild(textArea);
    }

    handleChange = (e) => {
        this.setState({groupId: e.target.value});
        this.props.dispatch(capsuleActions.createShareForm(e.target.value));
    }

    getReverseShareLink = () => {
        if (this.state.groupId === '') {
            this.props.dispatch(capsuleActions.createShareForm());
        } else {
            this.props.dispatch(capsuleActions.createShareForm(this.state.groupId));
        }
        this.props.dispatch(capsuleActions.createShareForm(this.state.groupId));
    }

    createGroupAndShareBack = () => {
        if (this.state.newGroupName !== '') {
            this.props.dispatch(capsuleActions.createCapsuleGroup(this.state.newGroupName));
        }
    }

    getShareOptions = () => {
        if (this.props.capsules && this.props.capsules.groups && this.props.capsuleToShow.ownerId === this.props.user.id) {
            //8-3-2022 - review details of what comments mean in this section.
            //uncomment after selected group does not error with shared back capsules.
            //let groups = this.props.capsules.groups;

            return (
                <React.Fragment>
                    {/* the 22 lines of code below sb uncommented after share back selected group does not error on shareback capsules even if only going to shared with me.*/}
                    {/*<Col xs="12" sm="12" md="12" lg="12" className="mt-4">
                    Select group:
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" >
                        <select value={this.state.group} disabled={this.state.newGroupName !== ''} onChange={this.handleChange}>
                            <option key={'sharedWithMe'} id={'sharedWithMe'} value={''}>{'Optional'}</option>
                            { Object.keys(groups).map((i) => (
                                <option key={groups[i].id} id={groups[i].id} value={groups[i].id}>{groups[i].name}</option>
                            ))
                            }
                        </select>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" className="mt-2">
                    or create one
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" className="mt-2">
                        <div>
                            Group name
                        </div>
                        <input name="group-name" id="group-name" onChange={(e) => this.setState({newGroupName: e.target.value})}/>
                        <div className="mt-3"><button onClick={this.createGroupAndShareBack}>Create group and get share link</button></div>
                    </Col>

                    {/*<Col xs="12" sm="12" md="12" lg="12">
                        <span onClick={() => this.getReverseShareLink()} className="navigation-button">Get share link</span>
                    </Col>*/}
                    {/*The 3 lines above should stay commentted out even after groups do not error with shareback capsules. Commented out by Attila }*/}
                </React.Fragment>

            );
        } else {
            return null;
        }
    }

    setShareDuration = (durationDays, durationHours, durationMinutes) => {
        if (durationDays === '365') {
            durationHours = '0';
            durationMinutes = '0';
            this.setState({shareDurationH: '0', shareDurationM: '0'});
        }
        this.props.dispatch(capsuleActions.updateShareToken(this.props.capsules.shareToken, durationDays, durationHours, durationMinutes));
        this.setState({updateDuration: true, gotToken: false});
    }

    getDuration = () => {

        let newDuration;
        let newDurationD = '';
        let newDurationH = '';
        let newDurationM = '';
        const re = /^[2-9\b]+$/;
        const re1 = /^[0\b]+$/;

        if (this.state.shareDurationD !== '0') {
            if (re.test(this.state.shareDurationD) || re1.test(this.state.shareDurationD)) {
                newDurationD = this.state.shareDurationD + ' days';
            } else {
                newDurationD = this.state.shareDurationD + ' day';
            }
        }
        if (this.state.shareDurationH !== '0') {
            if (this.state.shareDurationD === '0') {
                if (re.test(this.state.shareDurationH) || re1.test(this.state.shareDurationH)) {
                    newDurationH = this.state.shareDurationH + ' hrs';
                } else {
                    newDurationH = this.state.shareDurationH + ' hr';
                }
            } else {
                if (re.test(this.state.shareDurationH) || re1.test(this.state.shareDurationH)) {
                    newDurationH = ', ' + this.state.shareDurationH + ' hrs';
                } else {
                    newDurationH = ', ' + this.state.shareDurationH + ' hr';
                }
            }
        }
        if (this.state.shareDurationM !== '0') {
            if (this.state.shareDurationD === '0' && this.state.shareDurationH === '0') {
                newDurationM = this.state.shareDurationM + ' min';
            } else {
                newDurationM = ', ' + this.state.shareDurationM + ' min';
            }
        }

        if (this.state.shareDurationD === '0' && this.state.shareDurationH === '0' && this.state.shareDurationM === '0') {
            newDuration = 'active 0 days, 0 hrs, 0 min';
        } else {
            newDuration = 'active ' + newDurationD + newDurationH + newDurationM;
        }
        return newDuration;
    }

    renderTopViewLoggedIn = () => {
        let url;
        let background = 'https://myuniverse.com/favicon.ico';
        //let background = favIcon;

        /*if (isObjectExists(this.props, 'capsuleToShow.items.contact.image.profile.metadata.storageUUID') && this.props.capsuleToShow.items.contact.image.profile.metadata.storageUUID !== '') {
            background = getUrlAndLicence(this.props.capsuleToShow.items.contact.image.profile.metadata.storageUUID, false, '200x200', 'VieroToolkitUtilsTypeStillSingle').url;
        }*/

        /*let ogParams = '&title=' + this.props.capsuleToShow.name +
        '&description=MyUniverse capsule' +
        '&type=website' +
        '&url=' + process.env.REACT_APP_BASE_URI +
        '&image=' + background;*/

        let ogParams = '&image=' + background;

        if (this.props.capsules.formId && this.props.capsules.formId.id && this.props.capsules.formId.id !== '') {
            url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id + '?share_token=' + this.state.shareToken + '&form=' + this.props.capsules.formId.id;
            window.history.pushState('', '',
                '/capsules/' + this.props.capsuleToShow.id +
             '?share_token=' + this.state.shareToken +
             '&form=' + this.props.capsules.formId.id + ogParams

                /*window.history.pushState('', '',
                '/capsules/' + this.props.capsuleToShow.id +
                '?share_token=' + this.state.shareToken +
                '&form=' + this.props.capsules.formId.id*/

            );
        } else if ( -1 !== window.location.href.indexOf('share_token') && this.props.capsuleToShow.id) {
            url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id + '?share_token=' + this.state.shareToken + ogParams;
            //url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id + '?share_token=' + this.state.shareToken;
        } else if (-1 === window.location.href.indexOf('share_token') && !this.props.capsuleToShow.id) {
            url = process.env.REACT_APP_BASE_URI + '/capsules/x/e/' + this.props.capsuleToShow.externalSources.ElasticSearch.id;
        } else {
            url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id + ogParams;
            //url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id;
        }

        return (
            <Container className="text-center">
                <ModalView isOpen={this.state.copied}
                    toggle={() => this.setState({copied: !this.state.copied})}
                    onContinue={() => this.setState({copied: !this.state.copied})}
                    modalType="copy"
                />

                <Row className="align-items-center share-margin">

                    {/* <Col xs="12" sm="12" md="12" lg="12">

                        <select value={this.state.shareType} onChange={this.handleChange}>
                            <option value="share">Simple Share</option>
                            <option value="reverse_share">Reverse share</option>
                            <option value="competition">Competition</option>
                        </select>

                    </Col>*/}

                    <Col xs="12" sm="12" md="12" lg="12">
                        {(this.state.gotToken || !this.state.gotToken && this.state.updateDuration) && this.state.shareToken &&
                        <QRCode className="qr-code" includeMargin={true} level="M" value={url} />
                        }
                        {!this.state.updatedDuration ?
                            <React.Fragment>
                                <div onClick={() => this.copytoClipboard(url)}><span className="navigation-button">Click to copy share link</span></div>
                                <div className="duration-spacing" ><span>(active 3 days)</span></div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div onClick={() => this.copytoClipboard(url)}><span className="navigation-button">Click to copy share link</span></div>
                                <div className="duration-spacing" ><span>({this.getDuration()})</span></div>
                            </React.Fragment>
                        }
                    </Col>
                </Row>
                <Row className="align-items-center share-margin">
                    {!this.state.showOptions ?
                        <Col>
                            <div className="mt-2" onClick={() => this.setState({showOptions: true})}><span className="navigation-button">Update Share Link Duration</span></div>
                        </Col>
                        :
                        !this.state.updatedDuration &&
                            <Col>
                                <div className="mt-2"><span>Maximum: 365 days</span></div>
                            </Col>
                    }
                </Row>
                <Row className="align-items-center share-margin">
                    <Col>
                        {this.state.showOptions && !this.state.updatedDuration &&
                          <span className="days-spacing">
                              <label>
                                  {this.days}&nbsp;
                              </label>
                              <input className="text-center"
                                  value={this.state.shareDurationD}
                                  onChange={(e) => {const re = /^[0-9\b]+$/; if ((re.test(e.target.value) && this.state.shareDurationD === undefined) || (re.test(e.target.value) && parseInt(e.target.value) < 366) || (e.target.value === '')) {this.setState({shareDurationD: e.target.value});}}}
                                  name="shareDurationD"
                                  id="shareDurationD"
                                  placeholder="0"
                                  label="D"
                                  size="4"
                                  type="text"
                                  color="#FFFFFF"
                              />
                          </span>
                        }
                        {this.state.showOptions && !this.state.updatedDuration &&
                          <span className="hours-spacing">
                              <label>
                                  {this.hours}&nbsp;
                              </label>
                              <input className="text-center"
                                  value={this.state.shareDurationH}
                                  onChange={(e) => {const re = /^[0-9\b]+$/; if ((re.test(e.target.value) && this.state.shareDurationH === undefined) || (re.test(e.target.value) && parseInt(e.target.value) < 24) || (e.target.value === '')) {this.setState({shareDurationH: e.target.value});}}}
                                  name="shareDurationH"
                                  id="shareDurationH"
                                  placeholder="0"
                                  size="4"
                                  type="text"
                                  color="#FFFFFF"
                              />
                          </span>
                        }
                        {this.state.showOptions && !this.state.updatedDuration &&
                          <span className="minutes-spacing">
                              <label>
                                  {this.minutes}&nbsp;
                              </label>
                              <input className="text-center"
                                  value={this.state.shareDurationM}
                                  onChange={(e) => {const re = /^[0-9\b]+$/; if ((re.test(e.target.value) && this.state.shareDurationM === undefined) || (re.test(e.target.value) && parseInt(e.target.value) < 60) || (e.target.value === '')) {this.setState({shareDurationM: e.target.value});}}}
                                  name="shareDurationM"
                                  id="shareDurationM"
                                  placeholder="0"
                                  size="4"
                                  type="text"
                                  color="#FFFFFF"
                              />
                          </span>
                        }
                    </Col>
                </Row>
                <Row>
                    {this.state.showOptions && !this.state.updatedDuration &&
                      <Col>
                          <div className="mt-2" onClick={() => {this.setShareDuration(this.state.shareDurationD, this.state.shareDurationH, this.state.shareDurationM);}}><span className="navigation-button">Click to Confirm</span></div>
                      </Col>
                    }
                </Row>
                {/*}{this.props.isShareBack && this.getShareOptions()} uncomment after share user an select a group again - can't due to shareback capsule error with group selected */}

            </Container>
        );
    }

    renderTopView = () => {

        let url;

        if ( -1 === window.location.href.indexOf('share_token') && this.props.capsuleToShow.id) {
            url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id;
        } else if (-1 === window.location.href.indexOf('share_token') && !this.props.capsuleToShow.id) {
            url = process.env.REACT_APP_BASE_URI + '/capsules/x/e/' + this.props.capsuleToShow.externalSources.ElasticSearch.id;
        } else {
            url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id + '?share_token=' + this.state.shareToken;
        }
        return (
            <Container className="text-center">
                <ModalView isOpen={this.state.copied}
                    toggle={() => this.setState({copied: !this.state.copied})}
                    onContinue={() => this.setState({copied: !this.state.copied})}
                    modalType="copy"
                />

                <Row className="align-items-center share-margin">
                    <Col>
                        <QRCode className="qr-code" includeMargin={true} level="M" value={url} />
                        <div onClick={() => this.copytoClipboard(url)}><span className="navigation-button">Click to copy share link</span></div>

                    </Col>
                    {/*}{this.props.isShareBack && this.getShareOptions()} uncomment after share user an select a group again - can't due to shareback capsule error with group selected */}
                </Row>
            </Container>
        );
    }

    renderBottomView = () => {
        return (
            <React.Fragment>
                <Row className="capsule-view bottom-view">
                    <Col>

                        <Row>
                            <Col>
                                <img className="share-image" src={messageIcon} />
                                <div>iOS</div>
                            </Col>
                            <Col>
                                <img className="share-image"/>
                                <div>E-Mail</div>
                            </Col>
                            <Col>
                                <img className="share-image" src={twitterIcon} />
                                <div>Twitter</div>
                            </Col>
                            <Col className="messenger-column">
                                <img className="share-image messenger" src={messengerIcon} />
                                <div>Messenger</div>
                            </Col>
                        </Row>

                    </Col>

                    <Col lg="12" className="share-cancel" onClick={() => this.props.onMenuChange('')}>
                        <div>Cancel</div>
                    </Col>

                </Row>

            </React.Fragment>

        );
    }

    render() {
        if ((this.state.gotToken && this.state.shareToken) || -1 !== window.location.href.indexOf('share_token')) {
            return this.renderTopViewLoggedIn();
        } else {
            return this.renderTopView();
        }

    }

}

ShareView.propTypes = {
    dispatch:      PropTypes.func,
    render:        PropTypes.string,
    capsules:      PropTypes.object,
    user:          PropTypes.object,
    capsuleToShow: PropTypes.object,
    onMenuChange:  PropTypes.func,
    isPreview:     PropTypes.bool,
    isShareBack:   PropTypes.bool
};

function mapStateToProps(state) {
    const { capsules, user } = state;
    return {
        capsules,
        user
    };
}

const connectedShareViewPage = connect(mapStateToProps)(ShareView);
export { connectedShareViewPage as ShareView };
