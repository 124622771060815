import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal } from 'reactstrap';
import { isObjectExists } from '../../utils/Helpers';
//import cardAdded from '../../assets/images/CardAddedIcon@3x.png';

export class ConfirmationView extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            isErrorOpen: true
        };

    }

    getMessages = () => {
        let messageObject = {
            text:   '',
            button: ''
        };
        switch (this.props.confirmationType) {
            case 'edit':
                messageObject.text = 'Your Capsule is saved successfully!';
                messageObject.button = 'Continue';
                break;
            case 'create':
                messageObject.text = 'Your Capsule is created successfully!';
                messageObject.button = 'Continue';
                break;
            case 'profile':
                messageObject.text = 'Profile is saved!';
                messageObject.button = 'Continue';        }

        return messageObject;
    }

    renderAreYouSure = () => {
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <div className="d-flex h-100 align-items-center flex-column bd-highlight profile-card">
                    <Row className="p-2 bd-highlight">
                        <Col xs="12" md="12" sm="12" lg="12" className="textCenter">Are you sure you want to remove this video?</Col>
                    </Row>
                    <Row className="mt-auto p-2 bd-highlight bottom-button navigation-button">
                        <Col xs="6" md="6" sm="6" lg="6"><button onClick={() => this.props.onContinue()} type="button" className="btn btn-primary create-button">Remove</button></Col>
                        <Col xs="6" md="6" sm="6" lg="6"><button onClick={() => this.props.onCancel()} type="button" className="btn btn-light create-button">Cancel</button></Col>
                    </Row>
                </div>
            </Modal>);
    }

    render() {
        if (this.props.isRemove) {
            return this.renderAreYouSure();
        } else {
            const message = this.getMessages();
            if (isObjectExists(this.props, 'user.error.messages.error.name') && this.props.user.error.messages.error.name === 'ForbiddenError') {
                return <Modal isOpen={this.state.isErrorOpen} backdrop={'static'}>
                    <div className="d-flex h-100 align-items-center flex-column bd-highlight profile-card">
                        <Row className="p-2 bd-highlight">
                            Your main capsule cannot be deleted. Please set antoher capsule as your Main Capsule to delete this capsule.
                        </Row>
                        <Row className="mt-auto p-2 bd-highlight bottom-button navigation-button">
                            <button onClick={() => window.location.reload()} type="button" className="btn btn-light create-button">Continue</button>
                        </Row>
                    </div>
                </Modal>;
            } else {
                return (
                    <Modal isOpen={this.props.isOpen} fade={false} toggle={this.props.toggle}>
                        <div className="d-flex h-100 align-items-center flex-column bd-highlight profile-card">
                            {/* <Row className="p-2 bd-highlight">
                                <img className="group-create-success" src={cardAdded} />
                            </Row>
                            */}
                            <Row className="p-2 bd-highlight">
                                {message.text}
                            </Row>
                            <Row className="mt-auto p-2 bd-highlight bottom-button navigation-button">
                                <button onClick={() => this.props.onContinue()} type="button" className="btn btn-light create-button">{message.button}</button>
                            </Row>
                        </div>
                    </Modal>
                );
            }

        }

    }
}

ConfirmationView.propTypes = {
    confirmationType: PropTypes.string,
    onContinue:       PropTypes.func,
    isRemove:         PropTypes.bool,
    onCancel:         PropTypes.func,
    isOpen:           PropTypes.bool,
    toggle:           PropTypes.func,
    user:             PropTypes.object
};
