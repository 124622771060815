import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import './CapsuleView.css';
import { MediaView } from './MediaView';
//import verticalDivider from '../../assets/images/VerticalDivider@3x.png';
//import mediaIcon from '../../assets/images/MediaIcon@3x.png';
import mediaIcon from '../../assets/images/ContentIconBlueNew.png';
//import shareIcon from '../../assets/images/ConnectIcon.png';
//import vCardIcon from '../../assets/images/vCardIcon.png';
import vCardIcon from '../../assets/images/Contact Icon Blue.PNG';
//import {Wrap} from '../wrap/Wrap';
import backButton from '../../assets/images/Close@2x.png';
import shareIcon from '../../assets/images/ShareIconBlueSmaller.PNG';
import shareIconWhite from '../../assets/images/ShareIconWhiteSmaller.PNG';
import {AddCardView} from './AddCardView';
import { ShareView } from './ShareView';
import { ChatbotView } from './ChatbotView';
import { getUrlAndLicence, getVideoThumbnail } from '../../utils/VieroUtils';
import VideoPlayer from '../video/VideoPlayer';
import {VcardView} from './VcardView';
import {ConnectView} from './ConnectView';
import { isObjectExists, isWebsiteSocial, getUserTokenFromStorage, getVcard } from '../../utils/Helpers';
import SocialView from '../socialView/SocialView';
import {ModalView} from '../profile/ModalView';

export class CapsuleView extends Component {
    static defaultProps = {
        updateParentStateFunc: function() {}
    };

    constructor (props) {
        super(props);
        this.state = {
            cardAdded:              false,
            listenerSet:            false,
            isScrollable:           false,
            isConnect:              this.getIfConnect(),
            footerVisible:          true,
            shareBackModal:         false,
            capsuleListModal:       false,
            getSharedUsers:         false,
            gotSharedUsers:         false,
            capsuleSharedUsers:     [],
            capsuleAnonSharedUsers: [],
            countSharedUsers:       0,
            countRecSharedUsers:    0,
            isSharedUsersOpen:      false,
            isRevokeShareOpen:      false,
            revokeCapsuleId:        '',
            revokeShareId:          '',
            revokedUserShare:       false,
            vCard:                  '',
            vCardHasData:           false,
            vCardNoData:            false
        };
        this.videoPlayer;
    }

    componentDidMount() {

        document.documentElement.style.setProperty('position', 'fixed');
        // Works for everything except Safari. Safari won't allow going to full screen without user interaction
        let video = document.getElementById('video-background');
        window.addEventListener('orientationchange', () => {
            if (video) {
                if (window.orientation == 90 || window.orientation == -90) {
                    // We are in portrait, request full screen
                    video.requestFullscreen().catch(() => {
                        // Ignore for now.
                    });
                }
            }
        });
        window.addEventListener('message', (e) => {
            if (e.data === 'up' && this.state.footerVisible === false) {
                this.setState({footerVisible: true});
            }

            if (e.data === 'down' && this.state.footerVisible === true) {
                this.setState({footerVisible: false});
            }
        });
        this.checkIfScrollable();
        this.props.setGotCapsuleSharedUsers(false);
        this.getVCardInformation();
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.footerVisible === true && this.props.websiteUrl && isWebsiteSocial(this.props.websiteUrl) === false && this.props.viewToShow === '' && this.props.capsuleToShow) {
            /*this.typingTimer = setTimeout(() => {
                if (this.props.websiteUrl && this.props.viewToShow === '') {
                    this.setState({footerVisible: false});
                }
            }, 5000);*/
        }

        if (!this.state.vCardHasData) {
            this.isVCardDownloadReady();
        }

        if (prevProps.capsuleToShow && prevProps.capsuleToShow.id !== this.props.capsuleToShow.id) {
            this.setState({isConnect: this.getIfConnect()});
            clearTimeout(this.typingTimer);
        }

        if (this.props.viewToShow !== 'chatbotView' && localStorage.getItem('fanbotWebChatClientId')) {
            this.clearBot();
        }

        /*if (prevProps.fullscreenCapsule === true && this.props.fullscreenCapsule === false) {
            clearTimeout(this.typingTimer);
            this.setState({footerVisible: true});

        }*/

        if (prevState.footerVisible === false && this.state.footerVisible === true) {
            this.expandSection(document.getElementById('capsule-options'));
        }

        if (prevState.footerVisible === true && this.state.footerVisible === false) {
            this.collapseSection(document.getElementById('capsule-options'));
        }

        if (document.getElementById('capsule-header') && document.getElementById('capsule-options') && document.getElementById('capsule-view') && document.getElementById('content-container') && this.props.websiteUrl && this.props.viewToShow === '') {
            let contentHeight =  document.getElementById('capsule-view').clientHeight - (document.getElementById('capsule-header').clientHeight + document.getElementById('capsule-options').clientHeight);

            document.getElementById('content-container').style.height= contentHeight + 'px';

        }

        if (!document.getElementById('capsule-header') && document.getElementById('capsule-options') && document.getElementById('capsule-view') && document.getElementById('content-container')) {
            let contentHeight =  document.getElementById('capsule-view').clientHeight -  document.getElementById('capsule-options').clientHeight;

            document.getElementById('content-container').style.height= contentHeight + 'px';

        }

        if (document.getElementById('capsule-header') && document.getElementById('capsule-view') && document.getElementById('chat-bot-content-container')) {
            let chatbotHeight =  document.getElementById('capsule-view').clientHeight - document.getElementById('capsule-header').clientHeight;
            if (document.getElementById('multichat-chat-box')) {
                document.getElementById('multichat-chat-box').style.height= chatbotHeight + 'px';
                document.getElementById('multichat-chat-box').style.width= document.getElementById('capsule-width').clientWidth + 'px';
            }
        }

        if (this.state.getSharedUsers === false && this.props.gotCapsuleSharedUsers === false) {
            this.props.getSharedUsers(this.props.capsuleToShow.id);
            this.setState({getSharedUsers: true});
        }

        if (this.props.gotCapsuleSharedUsers === true && this.state.gotSharedUsers === false) {
            this.listSharedUsers();
            this.setState({gotSharedUsers: true});
        }

        if (this.props.revokedUserShare === true && this.state.revokedUserShare === false) {
            this.setRevoked(this.props.revokedCapsuleId, this.props.revokedUser2Id);
            this.props.setRevokedUserShare(false);
            this.setState({revokedUserShare: true});
        }

        if (this.props.revokedUserShare === false && this.state.revokedUserShare === true) {
            this.setState({revokedUserShare: false});
        }
    }

    async getVCardInformation() {
        let vCard =  encodeURIComponent(await getVcard({...this.updateSocials(this.props.capsuleToShow.items.contact), capsuleName: this.props.capsuleToShow.name}));
        this.setState({vCard: vCard});
    }

    updateSocials = (contact) => {
        let modifiedContact = contact;
        if (isObjectExists(this.props, 'capsuleToShow.items.contact.socialProfiles') && 0 < contact.socialProfiles.length) {
            let socials = contact.socialProfiles;
            let modifiedSocials = [];
            for (let i in socials) {
                if (socials[i].value.toolbar === true) {
                    modifiedSocials.push(socials[i]);
                }
            }
            modifiedContact.socialProfiles = modifiedSocials;

        }
        return modifiedContact;
    }

    goFullScreen = () => {
        //document.getElementById('video').controls = true;
        //document.getElementById('video').load();

    }

    showShareBack = () => {
        //if (this.props.user.userCapsules && !this.props.isShare & this.props.capsuleToShow.ownerId === this.props.user.id) {
        if (this.props.user.userCapsules && this.props.capsuleToShow.ownerId === this.props.user.id) {
            return true;
        } else {
            return false;
        }
    }

    showShareBackForm = () => {
        if (-1 !== window.location.href.indexOf('form=') && this.props.capsuleToShow.ownerId !== this.props.user.id) {
            return true;
        } else {
            return false;
        }
    }

    shareBackUserHasCapsules = () => {
        // lds change later to check for anonymous user instead
        if (this.props.user.userCapsules && this.props.user.userCapsules.length > 0 && this.props.capsuleToShow.ownerId !== this.props.user.id) {
            return true;
        } else {
            return false;
        }
    }

    sharer = () => {
        if (this.props.capsuleToShow.ownerId !== undefined && this.props.user.id !== undefined && this.props.capsuleToShow.ownerId === this.props.user.id) {
            return true;
        } else {
            return false;
        }
    }

    setRevokeShare = (id) => {
        this.setState({isRevokeShareOpen: !this.state.isRevokeShareOpen, revokeShareId: id, revokeCapsuleId: this.props.capsuleToShow.id});
    }

    clearRevokeShare = () => {
        this.setState({isRevokeShareOpen: !this.state.isRevokeShareOpen, revokeShareId: '', revokeCapsuleId: ''});
    }

    getSharedUsersList = () => {

        const sharedUsersList = this.state.capsuleSharedUsers;
        const sharedAnonList = this.state.capsuleAnonSharedUsers;
        if (0 < sharedUsersList.length || 0 < sharedAnonList.length) {
            return [sharedUsersList, sharedAnonList];
        } else {
            return 'NoUsers';
        }
    }

    changeVisibility = (id) => {
        let partialConfig = this.state.revokeSharedUserconfig;

        for (let i in partialConfig) {
            if (partialConfig[i].id === id) {
                partialConfig[i].display = !partialConfig[i].display;
            }
        }
        //let sharedUsersConfig = partialConfig;
        this.setState({revokeSharedUserconfig: partialConfig});

    }

    listSharedUsers = () => {

        let result = [];
        let resultAnon = [];
        let count = 0;
        let countRecSharedUsers = 0;
        let sharedUsers = this.props.capsuleSharedUsers;

        for (let i in sharedUsers) {
            if (sharedUsers[i].email) {
                result.push({
                    user:    sharedUsers[i].email,
                    id:      sharedUsers[i].id,
                    revoked: false
                });
                countRecSharedUsers++;
            } else if (sharedUsers[i].userPhoneForVerification) {
                result.push({
                    user:    sharedUsers[i].userPhoneForVerification,
                    id:      sharedUsers[i].id,
                    revoked: false
                });
                countRecSharedUsers++;
            } else {
                resultAnon.push({
                    anonymousUser: 'Anonymous',
                    anonymousId:   sharedUsers[i].id,
                    revoked:       false
                });
            }
            count++;
        }
        this.setState({capsuleSharedUsers: result, capsuleAnonSharedUsers: resultAnon, countRecSharedUsers: countRecSharedUsers, countSharedUsers: count});
    }

    setRevoked = (revokedCapsuleId, revokedUser2Id) => {
        let sharedUsers = this.state.capsuleSharedUsers;
        let anonSharedUsers = this.state.capsuleAnonSharedUsers;

        for (let i in sharedUsers) {
            if (sharedUsers[i].id === revokedUser2Id) {
                sharedUsers[i].revoked = true;
                break;
            }
        }
        for (let i in anonSharedUsers) {
            if (anonSharedUsers[i].anonymousId === revokedUser2Id) {
                anonSharedUsers[i].revoked = true;
                break;
            }
        }

        this.setState({capsuleSharedUsers: sharedUsers, capsuleAnonSharedUsers: anonSharedUsers});
    }

    getIfConnect = () => {
        let result = false;
        let chatbotCapsules = this.props.config.chatBotConfig;
        if (isObjectExists(this.props, 'capsuleToShow.id')) {
            for (let i in chatbotCapsules) {
                if (this.props.capsuleToShow.id === chatbotCapsules[i].capsuleId) {
                    result = true;
                }
            }
        }
        return result;
    }

    checkIfScrollable = () => {
        if (document.getElementById('capsule-view')) {
            if (document.getElementById('capsule-view').scrollHeight > document.getElementById('capsule-view').clientHeight && !this.state.isScrollable) {
                this.setState({isScrollable: true});
            }

            if (document.getElementById('capsule-view').scrollHeight === document.getElementById('capsule-view').clientHeight && this.state.isScrollable) {
                this.setState({isScrollable: false});
            }
        }
    }

    getMuteState = () => {
        return (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) ? 'muted' : '';
    }

    isUsersCapsule = () => {
        let result = false;

        if (this.props.user.id === this.props.capsuleToShow.ownerId && this.props.isFromGroups) {
            result = true;
        }

        return result;
    }

    // This is the important part!

collapseSection = (element) => {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = '';

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function() {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;

        // on the next frame (as soon as the previous style change has taken effect),
        // have the element transition to height: 0
        requestAnimationFrame(function() {
            element.style.height = 0 + 'px';
        });
    });

}

  expandSection = (element) => {
      // get the height of the element's inner content, regardless of its actual size
      var sectionHeight = element.scrollHeight;

      // have the element transition to the height of its inner content
      element.style.height = sectionHeight + 'px';

      // when the next css transition finishes (which should be the one we just triggered)
      element.addEventListener('transitionend', function remove() {
      // remove this event listener so it only gets triggered once
          element.removeEventListener('transitionend', remove);

          // remove "height" from the element's inline styles, so it can return to its initial value
          //element.style.height = null;
      });

  }

  openForm = () => {
      this.setState({shareBackModal: !this.state.shareBackModal});
  }

  showConnect = () => {
      let result = ((this.sharer() && this.showShareBack() && !this.showShareBackForm())
        || this.getIfConnect() || (!this.sharer() && (this.props.capsuleToShow.public || this.showShareBackForm()))
        || this.props.isPreview
        || (this.props.groupName === 'Shared with Me' && (this.props.capsuleToShow.public || this.showShareBackForm()))) ? true : false;
      return result;
  }

  openCapsuleList = () => {
      this.setState({capsuleListModal: !this.state.capsuleListModal});
  }

    renderCapsuleFooter = (isWebsite) => {

        let hideArrow = (

            <React.Fragment>
                <Row className="text-center">
                    <Col>
                        {this.state.footerVisible ?

                            <img onClick={() => this.setState({footerVisible: !this.state.footerVisible})} src={backButton} className="footer-arrow opened black-arrow hover" />
                            :
                            <img onClick={() => this.setState({footerVisible: !this.state.footerVisible})} src={backButton} className="footer-arrow closed black-arrow hover" />
                        }

                    </Col>

                </Row>

            </React.Fragment>

        );

        let footer = (
            <Row id='capsule-options' className={isWebsite ? 'capsule-options-transform' : 'capsule-options bg-white'}>
                {-1 !== window.location.href.indexOf('form=') && this.props.capsuleToShow.ownerId !== this.props.user.id &&
                <React.Fragment>
                    <ModalView isOpen={this.state.shareBackModal}
                        toggle={() => this.openForm()}
                        onContinue={() => this.openForm()}
                        title={'Share Contact Info'}
                        modalType="share-back"
                        user={this.props.user}
                        dispatch={this.props.dispatch}
                        capsules={this.props.capsules}
                    />
                    <ModalView isOpen={this.state.capsuleListModal}
                        toggle={() => this.openCapsuleList()}
                        onContinue={() => this.openCapsuleList()}
                        title={'Share Contact Capsule'}
                        modalType="shareBackCapsule"
                        user={this.props.user}
                        dispatch={this.props.dispatch}
                        capsules={this.props.capsules}
                    />
                    {/*<Col className="mb-4" xs="12" sm="12" md="12" lg="12">
                        <span className="navigation-button" onClick={() => this.openForm()}>Share back your information </span>
                      or
                        <span className="navigation-button"  onClick={() => this.openCapsuleList()}> one of your capsules</span></Col>*/}
                </React.Fragment>
                }
                {this.state.vCardHasData && this.state.vCard !== '' ?
                    <Col  xs="4" sm="4" md="4" lg="4" className={this.props.viewToShow === 'vCard' ? 'selected capsule-option p-2' : 'unselected p-2'} onClick={() => this.props.onMenuChange('vCard')}>
                        <span>
                            <a download className="mt-2"
                                href={'data:text/vcard;charset=utf8,' + this.state.vCard}><img className="capsule-options-img" src={vCardIcon}></img>
                                <br></br>
                                <div className="option-text">Contact</div>
                            </a>
                        </span>
                    </Col>
                    :
                    (this.state.vCardNoData) ?
                        <Col  xs="4" sm="4" md="4" lg="4" className={this.props.viewToShow === 'vCard' ? 'selected capsule-option p-2' : 'unselected p-2'} onClick={() => this.props.onMenuChange('vCard')}>
                            <span>
                                <img className={'capsule-options-img'}src={vCardIcon} />
                                <div className={'option-text'}>Contact</div>
                            </span>
                        </Col>
                        :
                        <Col  xs="4" sm="4" md="4" lg="4" className={this.props.viewToShow === 'vCard' ? 'selected capsule-option p-2' : 'unselected p-2'}>
                            <span>
                                <img className={'capsule-options-img'}src={vCardIcon} />
                                <div className={'option-text'}>Contact</div>
                            </span>
                        </Col>
                }

                <Col xs="4" sm="4" md="4" lg="4" className={this.props.viewToShow === 'capsuleList' ? 'selected capsule-footer-option p-2' : 'unselected capsule-footer-option p-2'} onClick={() => this.props.onMenuChange('capsuleList')}>
                    <span>
                        <img className={'capsule-options-img'}src={mediaIcon} />
                        <div className={'option-text'}>Content</div>
                    </span>
                </Col>

                <Col xs="4" sm="4" md="4" lg="4"
                    className={this.props.viewToShow === 'connectView' || this.props.viewToShow === 'chatbotView' || this.props.viewToShow === 'shareView' ? 'p-2 selected' : this.showConnect() ? 'p-2 unselected' : 'p-2 disable-button'}
                    onClick={this.props.isEdit === false ? null : this.showConnect() ? () => this.props.onMenuChange('connectView') : null}
                >
                    <span>
                        {this.showConnect() ?
                            <img className="capsule-options-img" src={shareIcon} />
                            :
                            <img className="capsule-options-img-disable" src={shareIconWhite} />
                        }
                        <div className={this.showConnect() ? 'option-text hover' : 'option-text-disable'}>Share</div>
                    </span>
                </Col>
            </Row>
        );

        if (isWebsite) {
            return (
                <div className={'capsule-options'}>
                    {hideArrow}
                    {footer}

                </div>
            );
        } else {
            return footer;
        }

    }

    clearBot = () => {
        localStorage.removeItem('fanbotWebChatClientId');
    }

    openSharedUsersModal = () => {
        this.setState({isSharedUsersOpen: !this.state.isSharedUsersOpen});
    }

    renderCapsuleHeader = (isClose, isListing) => {
        let closeButton = (
            <div className="closeButton navigation-button" onClick={() => this.props.onMenuChange('')}>Close</div>
        );

        if (isListing && !this.isUsersCapsule()) {
            closeButton = null;
        } else if (isListing && this.isUsersCapsule()) {
            closeButton = null;
        } else if (this.props.viewToShow === 'shareView' && this.state.isConnect || this.props.viewToShow === 'chatbotView') {
            closeButton = <div className="closeButton navigation-button" onClick={() => {this.props.onMenuChange('connectView'); this.clearBot();}}>Close</div>;
        } else if (this.props.viewToShow === 'shareView') {
            closeButton = <div className="closeButton navigation-button" onClick={() => {this.props.onMenuChange('');}}>Close</div>;
        }

        return (
            <Row id="capsule-header" className={this.props.viewToShow === 'chatbotView' ? 'capsule-header linear p-3' : 'capsule-header linear pt-3'}>
                <Col className="" xs="10" sm="10" md="10" lg="10" xl="10">
                    {
                        this.props.getThumbnailPicture(this.props.capsuleToShow, false, 'capsule-pic', 'thumbnail')
                    }
                    <div><span className="capsule-name">{this.props.capsuleToShow.name}</span></div>

                    {
                        this.props.capsuleToShow.ownerId ?
                            isObjectExists(this.props, 'capsuleToShow.items.contact.organisation.organizationName') && this.props.capsuleToShow.items.contact.organisation.organizationName !== '' && <div><span className="category">{this.props.capsuleToShow.items.contact.organisation.organizationName}</span></div>
                            :
                            this.props.capsuleToShow.category !== '' && <div><span className="category">{this.props.capsuleToShow.category}</span></div>
                    }
                </Col>

                {isClose &&
                    <React.Fragment>
                        <Col xs="2" sm="2" md="2" lg="2" xl="2" className="right">
                            {closeButton}
                        </Col>
                    </React.Fragment>
                }
            </Row>
        );
    }

    getVideoBackground = (profile) => {
        let src, keySystems, isViero, appleTech;

        if (isObjectExists(profile, 'metadata.storageType') && profile.metadata.storageType === 'viero') {
            let videoParameters = getUrlAndLicence(profile.metadata.storageUUID, false, '300,fit', 'VieroToolkitUtilsTypeMotion');
            src = videoParameters.url;
            keySystems = videoParameters.keySystems;
            appleTech = videoParameters.appleTech;
            isViero = true;
        } else {
            src = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' +
            profile.fileId
            + '.' + ((profile.metadata.mimeType.split('/')[1] === 'mpeg') ? 'mov' : profile.metadata.mimeType.split('/')[1])
            + '?token=' + getUserTokenFromStorage();
        }

        return <VideoPlayer
            url={src}
            appleTech={appleTech}
            id={isViero ? profile.metadata.storageUUID : null}
            status={this.props.user.fireBaseStatus || false}
            poster={isViero ? getVideoThumbnail(profile.metadata.storageUUID, 0, '') : null}
            keySystems={keySystems} videoClass={this.props.isFullscreen ? '' : 'video-background'}
            type={isViero ? profile.metadata ? profile.metadata.mimeType : null : profile.metadata.mimeType}
            listenStatus={this.props.listenStatus}
            isViero={isViero}
            showControls={this.state.showControls}
            isMuted={this.props.isMuted}
            parentStateUpdater={this.props.updateParentStateFunc}
        />;
    }

    renderMainCapsule = () => {
        return (
            <React.Fragment>
                {this.props.isVideoBackground === true &&
                    isObjectExists(this.props, 'capsuleToShow.items.contact.image.profile.metadata.mimeType') && -1 === this.props.capsuleToShow.items.contact.image.profile.metadata.mimeType.indexOf('image') &&
                    this.getVideoBackground(this.props.capsuleToShow.items.contact.image.profile)

                }
                {this.props.isFromGroups ? this.renderCapsuleHeader(true, true) : this.renderCapsuleHeader(false)}

                {this.renderCapsuleFooter()}
            </React.Fragment>
        );
    }

    isVCardDownloadReady = () => {
        const socialProfiles = isObjectExists(this.props, 'capsuleToShow.items.contact.socialProfiles') ? this.props.capsuleToShow.items.contact.socialProfiles : [];
        const emailAddresses = isObjectExists(this.props, 'capsuleToShow.items.contact.emailAddresses') ? this.props.capsuleToShow.items.contact.emailAddresses : [];
        const phoneNumbers = isObjectExists(this.props, 'capsuleToShow.items.contact.phoneNumbers') ? this.props.capsuleToShow.items.contact.phoneNumbers : [];
        const postalAddresses = isObjectExists(this.props, 'capsuleToShow.items.contact.postalAddresses') ? this.props.capsuleToShow.items.contact.postalAddresses : [];
        const firstName = isObjectExists(this.props, 'capsuleToShow.items.contact.name.givenName') ? this.props.capsuleToShow.items.contact.name.givenName : '';
        const lastName =  isObjectExists(this.props, 'capsuleToShow.items.contact.name.familyName') ? this.props.capsuleToShow.items.contact.name.familyName : '';
        const birthDay = isObjectExists(this.props, 'capsuleToShow.items.contact.date.birthday') ? this.props.capsuleToShow.items.contact.date.birthday === '' ? null : new Date(this.props.capsuleToShow.items.contact.date.birthday) : null;
        const dates = isObjectExists(this.props, 'capsuleToShow.items.contact.date.dates') ? this.props.capsuleToShow.items.contact.date.dates : [];
        const notes = isObjectExists(this.props, 'capsuleToShow.items.contact.notes') ? this.props.capsuleToShow.items.contact.notes : null;

        if (firstName !== '' || lastName !== '' ||
        this.arrayHasValidItems(phoneNumbers) ||
        this.arrayHasValidItems(emailAddresses) ||
        this.arrayHasValidItems(socialProfiles, 'website') ||
        this.arrayHasValidItems(postalAddresses, 'postal') ||
        birthDay || this.arrayHasValidItems(dates) || this.arrayHasValidItems(notes, 'notes')
        ) {
            if (!this.state.vCardHasData) {
                this.setState({vCardHasData: true});
            }
        } else {
            if (!this.state.vCardNoData) {
                this.setState({vCardNoData: true});
            }
        }
    }

    arrayHasValidItems = (arr, type) => {
        let result = false;

        switch (type) {
            case 'website':
                if (arr && 1 < arr.length || (arr.length === 1 && arr[0].value.urlString !== '')) {
                    for (let i in arr) {
                        if (arr[i].value.toolbar === true) {
                            result = true;
                            break;
                        }
                    }
                }
                break;
            case 'postal':
                if ((arr && 1 < arr.length) || (arr.length === 1 &&
                    arr[0].value.street !== '' &&
                    arr[0].value.city !== '' &&
                    arr[0].value.state !== '' &&
                    arr[0].value.postalCode !== '' &&
                    arr[0].value.country !== '')

                ) {
                    result = true;
                }
                break;
            case 'notes':
                if (arr && 1 < arr.length || arr && (arr.length === 1 && arr[0] !== '')) {
                    result = true;
                }
                break;
            default:
                if (arr && 1 < arr.length || arr && (arr.length === 1 && arr[0].value !== '')) {
                    result = true;
                }
                break;

        }
        return result;
    }

    renderMediaView = (isListing) => {
        return (
            <React.Fragment>
                {this.props.viewToShow === 'capsuleList' ? this.renderCapsuleHeader(true) : this.renderCapsuleHeader(true, true)}
                <div id="content-container" className="content-container">
                    <MediaView
                        getTransformationClassBasedOnOrientation={this.props.getTransformationClassBasedOnOrientation}
                        user={this.props.user}
                        onShare={() => this.props.onMenuChange('shareView')}
                        capsuleToShow={this.props.capsuleToShow}
                        onClose={() => this.props.onMenuChange('')}
                        isListing={isListing}
                        dispatch={this.props.dispatch}
                        openEditCard={this.props.openEditCard}
                        deleteCapsule={this.props.deleteCapsule}
                        checkIfScrollable={this.checkIfScrollable}
                        listenStatus={this.props.listenStatus}
                        //getStatusOfMedia={this.props.getStatusOfMedia}
                        getImageScalingParameter={this.props.getImageScalingParameter}

                    />
                    {this.renderCapsuleFooter()}
                </div>

            </React.Fragment>);
    }

    renderShareView = (isShareBack) => {
        return (
            <React.Fragment>
                {this.renderCapsuleHeader(true)}
                <ShareView capsuleToShow={this.props.capsuleToShow}
                    onClose={() => this.props.onMenuChange('')}
                    isPreview={this.props.isPreview}
                    isFromGroups={this.props.isFromGroups}
                    isShareBack={isShareBack}
                    onMenuChange={this.props.onMenuChange}
                />
                {this.renderCapsuleFooter()}
            </React.Fragment>);
    }

    renderVcardView = () => {

        return (
            <React.Fragment>
                {this.props.viewToShow === 'capsuleList' ? this.renderCapsuleHeader(true, true) : this.renderCapsuleHeader(true)}
                <div id="content-container" className="content-container">
                    <VcardView
                        getTransformationClassBasedOnOrientation={this.props.getTransformationClassBasedOnOrientation}
                        user={this.props.user}
                        onShare={() => this.props.onMenuChange('shareView')}
                        capsuleToShow={this.props.capsuleToShow}
                        onClose={() => this.props.onMenuChange('')}
                        openEditCard={this.props.openEditCard}
                        deleteCapsule={this.props.deleteCapsule}
                        onMenuChange={this.props.onMenuChange}
                        checkIfScrollable={this.checkIfScrollable}
                        vCard={this.state.vCard}
                        vCardHasData={this.state.vCardHasData}
                    />
                </div>
                {this.renderCapsuleFooter()}

            </React.Fragment>);
    }

    renderConnectView = () => {

        return (
            <React.Fragment>
                {this.renderCapsuleHeader(true)}
                <div id="content-container" className="content-container">
                    <ModalView isOpen={this.state.isSharedUsersOpen}
                        toggle={() => this.setState({isSharedUsersOpen: !this.state.isSharedUsersOpen})}
                        getSharedUsersList={this.getSharedUsersList}
                        setRevokeShare={this.setRevokeShare}
                        title={this.state.capsuleAnonSharedUsers.length > 0 || this.state.capsuleSharedUsers.length > 0 ? 'Select to remove shared capsule' : 'There are no shared users'}
                        modalType="sharedUsers"
                    />
                    <ModalView isOpen={this.state.isRevokeShareOpen}
                        toggle={() => this.setState({isRevokeShareOpen: !this.state.isRevokeShareOpen})}
                        onContinue={() => this.props.revokeShare(this.state.revokeCapsuleId, this.state.revokeShareId)}
                        onCancel={() => this.clearRevokeShare()}
                        title="Please confirm share removal"
                        modalType="shareRevoke"
                    />
                    <ConnectView
                        isEdit={this.props.isEdit}
                        user={this.props.user}
                        onShare={() => this.props.onMenuChange('shareView')}
                        hasChat={this.getIfConnect()}
                        capsuleToShow={this.props.capsuleToShow}
                        onClose={() => this.props.onMenuChange('')}
                        openEditCard={this.props.openEditCard}
                        deleteCapsule={this.props.deleteCapsule}
                        onMenuChange={this.props.onMenuChange}
                        checkIfScrollable={this.checkIfScrollable}
                        showShareBack={this.showShareBack()}
                        showShareBackForm={this.showShareBackForm()}
                        shareBackModal={this.state.shareBackModal}
                        openForm={() => this.openForm()}
                        openCapsuleList={() => this.openCapsuleList()}
                        shareBackUserHasCapsules={this.shareBackUserHasCapsules()}
                        sharer={this.sharer()}
                        isPreview={this.props.isPreview}
                        openSharedUsersModal={this.openSharedUsersModal}
                    />
                </div>
                {this.renderCapsuleFooter()}

            </React.Fragment>);
    }

    renderChatbotView = () => {
        return (<React.Fragment>
            {this.renderCapsuleHeader(true)}
            <ChatbotView
                isEdit={this.props.isEdit}
                user={this.props.user}
                onShare={() => this.props.onMenuChange('shareView')}
                capsuleToShow={this.props.capsuleToShow}
                onClose={() => this.props.onMenuChange('')}
                openEditCard={this.props.openEditCard}
                deleteCapsule={this.props.deleteCapsule}
                onMenuChange={this.props.onMenuChange}
                checkIfScrollable={this.checkIfScrollable}
                config={this.props.config}
            />

        </React.Fragment>);
    }

    renderAddCardView = () => {
        return (
            <React.Fragment>
                {this.renderCapsuleHeader(true)}
                <AddCardView
                    cardAdded={this.state.cardAdded}
                    changeCardAdded={this.changeCardAdded}
                    capsuleToShow={this.props.capsuleToShow}
                    onMenuChange={this.props.onMenuChange}
                    setGotGroups={this.props.setGotGroups}

                />

            </React.Fragment>);
    }

    renderCancel = () => {
        return (
            <Col className="add-card-cancel"
                onClick={this.state.cardAdded ?
                    () => {this.props.onMenuChange(''); this.changeCardAdded(false); this.props.setGotGroups(false);} :
                    () => {this.props.onMenuChange(''); this.changeCardAdded(false);}
                }
            >
                { this.state.cardAdded ? 'OK' : 'Cancel' }
            </Col>
        );
    }

    changeCardAdded = (value) => {
        this.setState({cardAdded: value});
    }

    determineContainerClass = () => {
        let classes = isWebsiteSocial(this.props.websiteUrl) === false ? 'h-100' : '';

        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
            classes += ' pos-rel';
        }

        if (this.props.websiteUrl && isWebsiteSocial(this.props.websiteUrl) !== 'twitter') {
            classes += ' overflow-hidden';
        }

        return classes;
    }

    render() {
        if (this.props.websiteUrl && this.props.viewToShow === '' && this.props.capsuleToShow) {
            return (
                <React.Fragment>
                    {/*this.props.isFromGroups ? this.renderCapsuleHeader(true, true) : this.renderCapsuleHeader(false)*/}
                    <Col className="my-auto fullscreen-button right p-left-0">
                        <span className="navigation-button" onClick={() => this.props.setFullscreen()}>{this.props.isCapsuleFullscreen ? 'Compact' : 'Fullscreen'}</span>
                    </Col>

                    <div id={isWebsiteSocial(this.props.websiteUrl) === false ? '' : 'content-container'} className={this.determineContainerClass()}>
                        <SocialView
                            capsules={this.props.capsules}
                            websiteUrl={this.props.websiteUrl}
                            capsuleToShow={this.props.capsuleToShow}
                            dispatch={this.props.dispatch}

                        />
                    </div>
                    {this.renderCapsuleFooter(isWebsiteSocial(this.props.websiteUrl) !== false ? false : true)}
                </React.Fragment>
            );
        } else if (this.props.capsuleToShow) {
            switch (this.props.viewToShow) {
                case 'media':
                    return this.renderMediaView();
                case 'capsuleList':
                    return this.renderMediaView(true);
                case 'vCard':
                    return this.renderVcardView();
                case 'addCard':
                    return this.renderAddCardView();
                case 'shareView':
                    return this.renderShareView();
                case 'shareBackView':
                    return this.renderShareView(true);
                case 'connectView':
                    return this.renderConnectView();
                case 'chatbotView':
                    return this.renderChatbotView();
                default:
                    return this.renderMainCapsule();

            }
        } else {
            return null;
        }
    }

}

CapsuleView.propTypes = {
    capsuleToShow:                            PropTypes.object,
    onMenuChange:                             PropTypes.func,
    viewToShow:                               PropTypes.string,
    setGotGroups:                             PropTypes.func,
    user:                                     PropTypes.object,
    isVideoBackground:                        PropTypes.bool,
    searchInitiated:                          PropTypes.bool,
    getTransformationClassBasedOnOrientation: PropTypes.func,
    getImageFromImageAndVideos:               PropTypes.func,
    getImageResizerParameters:                PropTypes.func,
    getThumbnailPicture:                      PropTypes.func,
    backgroundLoaded:                         PropTypes.bool,
    openEditCard:                             PropTypes.func,
    deleteCapsule:                            PropTypes.func,
    isFromGroups:                             PropTypes.bool,
    isPreview:                                PropTypes.bool,
    isEdit:                                   PropTypes.bool,
    websiteUrl:                               PropTypes.string,
    dispatch:                                 PropTypes.func,
    capsules:                                 PropTypes.object,
    listenStatus:                             PropTypes.func,
    config:                                   PropTypes.object,
    isFullscreen:                             PropTypes.bool,
    isMuted:                                  PropTypes.bool,
    updateParentStateFunc:                    PropTypes.func,
    getImageScalingParameter:                 PropTypes.func,
    fullscreenCapsule:                        PropTypes.bool,
    setFullscreen:                            PropTypes.func,
    isCapsuleFullscreen:                      PropTypes.bool,
    //checkIfUploading:                         PropTypes.func,
    groupName:                                PropTypes.string,
    capsuleSharedUsers:                       PropTypes.array,
    gotCapsuleSharedUsers:                    PropTypes.bool,
    getSharedUsers:                           PropTypes.func,
    setGotCapsuleSharedUsers:                 PropTypes.func,
    revokeShare:                              PropTypes.func,
    revokedUserShare:                         PropTypes.bool,
    revokedUser2Id:                           PropTypes.string,
    revokedCapsuleId:                         PropTypes.string,
    setRevokedUserShare:                      PropTypes.func
};
