import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { capsuleActions } from '../../redux/actions';

export class ShareBackView extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            name:           this.props.user && this.props.user.name ? this.props.user.name : '',
            phone:          this.props.user && this.props.user.phone ? this.props.user.phone : '',
            email:          this.props.user && this.props.user.email ? this.props.user.email : '',
            shareSent:      false,
            disabledSubmit: true

        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.capsules.shareSent === false && this.props.capsules.shareSent === true) {
            this.setState({shareSent: true});
        }
    }

    onChangeName = (e) => {
        let disabledSubmit = e.target.value === '' ? true : false;
        this.setState({[e.target.name]: e.target.value, disabledSubmit: disabledSubmit});
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    shareBack = () => {
        this.props.dispatch(capsuleActions.shareBack(window.location.href.split('form=')[1], this.state.email, this.state.phone, this.state.name));
    }

    renderForm = () => {
        return (
            <Container>

                <Row className="align-items-center">
                    <Col>Email</Col>
                </Row>
                <Row className="align-items-center">
                    <Col>
                        <input
                            value={this.state.email}
                            onChange={(e) => this.onChange(e)}
                            name="email"
                            id="email"
                            placeholder="Email"
                            className="capsule-input"
                        />

                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col>Phone</Col>
                </Row>
                <Row className="align-items-center">
                    <Col>
                        <input
                            value={this.state.phone}
                            onChange={(e) => this.onChange(e)}
                            name="phone"
                            id="phone"
                            placeholder="Phone"
                            className="capsule-input"
                        />
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col>Name</Col>
                </Row>
                <Row className="align-items-center">
                    <Col>
                        <input
                            value={this.state.name}
                            onChange={(e) => this.onChangeName(e)}
                            name="name"
                            id="username"
                            placeholder="Name (required)"
                            className="capsule-input"
                        />
                    </Col>
                </Row>
                <Row className="text-center button-row">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.toggle()}>{'Cancel'}</button>
                    </Col>
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            disabled={this.state.disabledSubmit}
                            onClick={() => this.shareBack()}>{'Send'}</button>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderSuccess = () => {
        return (
            <Container>
                <Row><Col className="text-dark">Information sent back successfully!</Col></Row>
                <Row className="text-center button-row">

                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.toggle()}>{'OK'}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    render() {

        if (!this.state.shareSent) {
            return this.renderForm();
        } else {
            return this.renderSuccess();
        }
    }

}

ShareBackView.propTypes = {
    dispatch:        PropTypes.func,
    user:            PropTypes.object,
    capsules:        PropTypes.array,
    toggle:          PropTypes.func,
    isSectionOpen:   PropTypes.bool,
    onCapsuleChange: PropTypes.func,
    paymentData:     PropTypes.object
};
