import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Collapse } from 'reactstrap';
import facebookIcon from '../../assets/images/FacebookIcon@3x.png';
import instagramIcon from '../../assets/images/Instagram@3x.png';
import twitterIcon from '../../assets/images/TwitterActive@33x.png';
import youtubeIcon from '../../assets/images/YoutubeActive@3x.png';
import spotifyIcon from '../../assets/images/SpotfyActive@3x.png';
import subtractIcon from '../../assets/images/Subtract@3x.png';
import customIcon from '../../assets/images/CustomLinkIcon@3x.png';
import addIcon from '../../assets/images/AddIcon@3x.png';
import backButton from '../../assets/images/Close@2x.png';
import {
    SortableContainer,
    SortableElement,
} from 'react-sortable-hoc';

const SortableItem = SortableElement(({indexOfItem, value, toggleToolbar, getSocialImage, onChange, setSocialOption, getUrlObject, focusInput, getWidthOfRow}) =>
    <Row id={'item-' + indexOfItem} className="input-row">
        <Col lg="3" md="2" sm="2">
            <img onClick={() => toggleToolbar(indexOfItem, true)} className="manage-icons add-remove" src={subtractIcon} />
            <img className="vCard-icons" onClick={() => setSocialOption(indexOfItem)} src={getSocialImage(value.value.service)} />
        </Col>
        
        <Col lg="9" md="10" sm="10">
            
            {getUrlObject(value) !== false ?
                <div id={'input-group-' + indexOfItem} className="input-group"> 
                    <div id={'prepend-' + indexOfItem} className="input-group-prepend color-grey ">
                        <div onClick={() => focusInput('item-input-'+indexOfItem)}>
                            {getUrlObject(value).prefix}<span className="slash">/</span>
                        </div>
                    </div>
                    
                    <div style={{width: getWidthOfRow('input-group-' + indexOfItem,  'prepend-' + indexOfItem)}}>
                        <input
                        
                            value={getUrlObject(value) !== false ? getUrlObject(value).url : value.value.urlString} 
                            onChange={(e) => onChange(e, indexOfItem, undefined, getUrlObject(value).prefix + '/')} 
                            name="facebook" 
                            id={'item-input-' + indexOfItem} 
                            className="capsule-input"
                        />
                    </div>
                </div>
                : 
                <div className="input-group website-width">
                    <input                       
                        value={value.value.urlString} 
                        onChange={(e) => onChange(e, indexOfItem)} 
                        name="facebook" 
                        id={'item-input-' + indexOfItem} 
                        className="capsule-input"
                    />
                </div>
            }
        </Col>
    </Row>
    
);
 
const SortableList = SortableContainer(({items, ...props}) => {
    return (
        <ul className="list-unstyled">
            {items.map((value, index) => (
                value.value.toolbar &&
                    <SortableItem 
                        key={`item-${index}`} index={index} onChange={props.onChange} 
                        indexOfItem={index} getUrlObject={props.getUrlObject} setSocialOption={props.setSocialOption} 
                        value={value} toggleToolbar={props.toggleToolbar} getSocialImage={props.getSocialImage}
                        focusInput={props.focusInput} getWidthOfRow={props.getWidthOfRow}
                    />  
            ))}
            <span id="bottom"></span>
        </ul>
    );
});

export class SocialEdit extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            facebook:             this.props.socialData.facebook,
            instagram:            this.props.socialData.instagram,
            twitter:              this.props.socialData.twitter,
            youtube:              this.props.socialData.youtube,
            spotify:              this.props.socialData.spotify,
            isShowMoreOpen:       false,
            isRenderSocialOption: false,
            itemChangedId:        '',
            changeHappened:       false,
            isSectionOpen:        false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.changeHappened !== this.state.changeHappened) {
            if (document.getElementById(this.state.itemChangedId)) {
                document.getElementById(this.state.itemChangedId).setSelectionRange(0, 0);
                document.getElementById(this.state.itemChangedId).focus();
            }
        }
    }

    getSocialImage(social) {
        switch (social) {
            case 'facebook':
                return facebookIcon;
            case 'twitter':
                return twitterIcon;
            case 'spotify':
                return spotifyIcon;
            case 'youtube':
                return youtubeIcon;
            case 'instagram':
                return instagramIcon;
            default: 
                return customIcon;      
        }
    }

    toggleMenu = (menu) => {
        this.setState({[menu]: !this.state[menu]});
    }

    onChange = (e, i, service, prefix) => {
        let event = e;
        
        if (prefix) {
            event.target.value = prefix + e.target.value;
        }
        this.props.onCapsuleChange(event, 'social', i, service);
    }

    onOrderSave = () => {
        this.props.onOrderSave();
    }

    onSocialTypeChange = (i, type) => {
        let e = {target: {value: type}};
        this.onChange(e, i, true);
        if (this.state.noToolBar) {
            this.setState({isRenderSocialOption: false, itemChangedId: 'social-'+i, noToolBar: false, changeHappened: !this.state.changeHappened});
        } else {
            this.setState({isRenderSocialOption: false, itemChangedId: 'item-input-'+i, changeHappened: !this.state.changeHappened});
        }
        
    }

    setSocialOption = (i) => {
        this.setState({isRenderSocialOption: i});
    }

    getWidthOfRow = (id, pid) => {
        let row = document.getElementById(id);
        let preRow = document.getElementById(pid);
        if (row && preRow) {
            return row.clientWidth - preRow.clientWidth - 20;
        } else {
            this.setState({temp: true});
            return '50%';
        }
    }

    renderSocialOptions = (i) => {
        return (
            <Container>
              
                <Row className="input-row hover pt-5" onClick={() => this.onSocialTypeChange(i, 'website')}>
                    <Col lg="2" md="2" sm="2">
                        <img className="vCard-icons" src={customIcon} />  
                    </Col>
                    <Col>
                Website
                    </Col>
                </Row>
                <Row className="input-row hover" onClick={() => this.onSocialTypeChange(i, 'facebook')}>
                    <Col lg="2" md="2" sm="2">
                        <img className="vCard-icons"  src={facebookIcon} />  
                    </Col>
                    <Col>
                Facebook
                    </Col>
                </Row>
                <Row className="input-row hover" onClick={() => this.onSocialTypeChange(i, 'twitter')}>
                    <Col lg="2" md="2" sm="2">
                        <img className="vCard-icons"  src={twitterIcon} />  
                    </Col>
                    <Col>
                Twitter
                    </Col>
                </Row>
                <Row className="input-row hover" onClick={() => this.onSocialTypeChange(i, 'spotify')}>
                    <Col lg="2" md="2" sm="2">
                        <img className="vCard-icons" src={spotifyIcon} />  
                    </Col>
                    <Col>
                Spotify
                    </Col>
                </Row>
                <Row className="input-row hover" onClick={() => this.onSocialTypeChange(i, 'youtube')}>
                    <Col lg="2" md="2" sm="2">
                        <img className="vCard-icons"  src={youtubeIcon} />  
                    </Col>
                    <Col>
                Youtube
                    </Col>
                </Row>
                <Row className="input-row hover" onClick={() => this.onSocialTypeChange(i, 'instagram')}>
                    <Col lg="2" md="2" sm="2">
                        <img className="vCard-icons"  src={instagramIcon} />  
                    </Col>
                    <Col>
                Instagram
                    </Col>
                </Row>
               
            </Container>
        );
    }

    renderSocialSetup = () => {
        return (
            <Container>
                <Row className="capsule-header vcard">
                    <Col className="font-weight-bold color-black">
                        Connect Apps & Websites    
                    </Col>
                </Row>
                {this.state.isRenderSocialOption === false && 
                <Row className="social-header">
                    <Col lg="2" md="2" sm="2">
                        Socials   
                    </Col>
                    <Col lg="7" md="7" sm="7">
                    </Col>
                    <Col lg="3" md="3" sm="3">
                        <img className="manage-icons" onClick={() => this.props.addToSocial()} src={addIcon} />   
                    </Col>
                </Row>
                }
                {this.state.isRenderSocialOption === false ?
                    Object.keys(this.props.socialData.socialProfiles).map((i) => (      
                        <Row key={i}>
                            <Col lg="4" md="4" sm="4">
                                <img className="vCard-icons" onClick={() => this.setSocialOption(i)} src={this.getSocialImage(this.props.socialData.socialProfiles[i].label)} />
                            </Col>
                            <Col lg="6" md="6" sm="6">                             
                                <textarea
                                    rows='1'
                                    value={this.props.socialData.socialProfiles[i].value.urlString} 
                                    onChange={(e) => this.onChange(e, i)} 
                                    name="facebook" 
                                    id="facebook" 
                                    className="capsule-input textarea"
                                />
                            </Col>
                            <Col lg="2" md="2" sm="2"><img onClick={() => this.props.removeFromSocial(i)} className="manage-icons" src={subtractIcon} /></Col>
                        </Row>
                    ))
                    :
                    this.renderSocialOptions(this.state.isRenderSocialOption)
                }
            </Container>
        );
    }

    assembleUrl = (splittedUrl) => {
        let resultUrl = '';
        for (let i = 3;i < splittedUrl.length; i++) {
            if (i === splittedUrl.length-1 ) {
                resultUrl += splittedUrl[i];
            } else {
                resultUrl += splittedUrl[i] + '/';
            }
        }
        return resultUrl;
    }

    focusInput = (id) => {
        document.getElementById(id).setSelectionRange(0, 0);
        document.getElementById(id).focus();
    }

    getUrlObject = (value) => {
        let resultObject = {prefix: '', url: ''};
        let url = value.value.urlString;

        if (url) {
            if (url.indexOf('facebook') !== -1) {
                resultObject.prefix = 'https://www.facebook.com';
                resultObject.url = this.assembleUrl(url.split('/'));
            } else if (url.indexOf('twitter') !== -1) {
                resultObject.prefix = 'https://www.twitter.com';
                resultObject.url = this.assembleUrl(url.split('/'));
            } else if (url.indexOf('spotify') !== -1) {
                resultObject.prefix = 'https://www.open.spotify.com';
                resultObject.url = this.assembleUrl(url.split('/'));
            } else if (url.indexOf('youtube') !== -1) {
                resultObject.prefix = 'https://www.youtube.com';
                resultObject.url = this.assembleUrl(url.split('/'));
            } else if (url.indexOf('instagram') !== -1) {
                resultObject.prefix = 'https://www.instagram.com';
                resultObject.url = this.assembleUrl(url.split('/'));
            } else { 
                resultObject = false;
            }
        } else {
            resultObject = false;
        }

        return resultObject;
    }

    renderMain = () => {
        let socialProfiles = this.props.socialData.socialProfiles;
        return (
            <Container>
                {this.state.isRenderSocialOption === false && 
            <React.Fragment>
                <Row className="capsule-header vcard">
                    <Col className="font-weight-bold color-black">
                    Connect Apps & Websites
                    </Col>
                </Row>
                <Row className="social-header font-weight-bold color-black" >           
                    Sites Enabled for Viewing
                </Row>
                
                <SortableList 
                    items={socialProfiles} 
                    onSortEnd={this.props.onSortEnd} 
                    toggleToolbar={this.props.toggleToolbar}
                    getSocialImage={this.getSocialImage}
                    onChange={this.onChange}
                    getUrlObject={this.getUrlObject}
                    focusInput={this.focusInput}
                    getWidthOfRow={this.getWidthOfRow}
                    setSocialOption={this.setSocialOption}
                    pressDelay={200}
                    lockAxis={'y'}
                    
                >
                </SortableList>
                <Row>
                    <div className="mr-15 mb-10 font-weight-bold color-black">Add Websites or Social Links</div>
                    <img className="manage-icons add-remove" onClick={() => this.props.addToSocial()} src={addIcon} /> 
                </Row>
               
                <Row className="social-header pl-0">
                    
                    <div className="mr-15 font-weight-bold color-black">Sites Not Enabled for Viewing</div>     
                    
                </Row>
            </React.Fragment>
                }
                { this.state.isRenderSocialOption === false ? 
                    Object.keys(socialProfiles).map((value) => (
                        !socialProfiles[value].value.toolbar &&
                        <Row key={'social-' + value} className="input-row">
                            <Col lg="3" md="2" sm="2">
                                <img onClick={() => this.props.toggleToolbar(value)} className="manage-icons add-remove" src={addIcon} />
                                <img className="vCard-icons"
                                    onClick={() => this.setState({isRenderSocialOption: value, noToolBar: true})}
                                    src={this.getSocialImage(socialProfiles[value].label)}
                                />
                            </Col>
                            
                            <Col lg="8" md="9" sm="9">

                                {this.getUrlObject(socialProfiles[value]) !== false ? 
                                    <div id={'no-input-group-' + value} className="input-group">
                                        <div id={'no-prepend-' + value} className="input-group-prepend color-grey">
                                            <div onClick={() => this.focusInput('social-' + value)}>
                                                {this.getUrlObject(socialProfiles[value]).prefix}<span className="slash">/</span>
                                            </div>
                                        </div>
                                
                                        <div style={{width: this.getWidthOfRow('no-input-group-' + value, 'no-prepend-' + value)}}>
                                            <input
                                                rows='1'
                                                value={this.getUrlObject(socialProfiles[value]) !== false ? this.getUrlObject(socialProfiles[value]).url : socialProfiles[value].value.urlString} 
                                                onChange={(e) => this.onChange(e, value, undefined, this.getUrlObject(socialProfiles[value]).prefix + '/')} 
                                                name="facebook" 
                                                id={'social-' + value} 
                                                className="capsule-input textarea"
                                            />
                                        </div>
                                   
                                    </div>
                                    : 
                                    <input
                                        value={socialProfiles[value].value.urlString} 
                                        onChange={(e) => this.onChange(e, value)} 
                                        name="facebook" 
                                        id={'social-' + value} 
                                        className="capsule-input textarea"
                                    />}
     
                            </Col>
                           
                            <Col lg="1" md="1" sm="1"><img onClick={() => this.props.removeFromSocial(value)} className="manage-icons" src={subtractIcon} /></Col>
                        </Row>
                    ))
                    :
                    this.renderSocialOptions(this.state.isRenderSocialOption)
                }
            </Container>
        );
    }

    render() {
        return (
            !this.props.isSectionOpen ? 
                <Container className="profile-closed mt-10">
                    <Row onClick={() => this.props.toggleMenu('socialOpen')}>
                        <Col >
                            <div onClick={() => this.props.toggleMenu('socialOpen')}>
                                <span>Social Media</span>
                            </div>
            
                        </Col>
                        <Col xs="2" className="right"><img src={backButton} className="hover section-button"/></Col>
                    </Row>
                </Container>
                :
                <Collapse isOpen={this.props.isSectionOpen}>
                    <Container className="profile-card mt-10" >
                        <Row onClick={() => this.props.toggleMenu('socialOpen')} className="mb-2">
                            <Col className="color-black font-weight-bold">
                                <span>Social Media</span>
                            </Col>
                            <Col xs="2" className="right">
                                <img src={backButton} className="hover button-up section-button"/>
                            </Col>
               
                        </Row>
                        {this.renderMain()}
                    </Container>
                </Collapse>
        );
    }

}

SocialEdit.propTypes = {
    dispatch:         PropTypes.func,
    changeView:       PropTypes.func,
    isReorder:        PropTypes.bool,
    socialData:       PropTypes.object,
    onCapsuleChange:  PropTypes.func,
    onOrderSave:      PropTypes.func,
    onSortEnd:        PropTypes.func,
    toggleToolbar:    PropTypes.func,
    addToSocial:      PropTypes.func,
    removeFromSocial: PropTypes.func,
    toggleMenu:       PropTypes.func,
    isSectionOpen:    PropTypes.bool
};            