import { configService } from '../services/configService';

export const configActions = {
    getChatbotConfig,
    getWrapConfig
};

function getChatbotConfig(name) {
    return dispatch => {
        dispatch(request());

        configService.getChatbotConfig(name)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_CHATBOT_CONFIG_REQUEST'}; }
    function success(response) { return { type: 'GET_CHATBOT_CONFIG_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_CHATBOT_CONFIG_ERROR', error }; }
}

function getWrapConfig(wrapName) {
    return dispatch => {
        dispatch(request());

        configService.getWrapConfig(wrapName)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: 'GET_WRAP_CONFIG_REQUEST'}; }
    function success(response) { return { type: 'GET_WRAP_CONFIG_SUCCESS', response }; }
    function failure(error) { return { type: 'GET_WRAP_CONFIG_ERROR', error }; }
}
