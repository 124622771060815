export function assemblePayload(data, preview) {
    let image = data.social.image;
    var profile = null;
    var thumbnail = null;
    let name = data.capsule.name;
    if (image.profile !== '') {
        profile = image.profile;
    }
    if (image.thumbnail !== '') {
        thumbnail = image.thumbnail;
    }

    if (name === '') {
        if (data.vCard.firstName && !data.vCard.lastName) {
            name = data.vCard.firstName.trim();
        } else if (!data.vCard.firstName && data.vCard.lastName) {
            name = data.vCard.lastName.trim();       
        } else if (data.vCard.firstName && data.vCard.lastName) {
            name = data.vCard.firstName.trim() + ' ' + data.vCard.lastName.trim();
        }
    }

    let body = {
        name:          name.trim(),
        category:      data.capsule.category,
        public:        data.settings.public,
        groups:        data.capsule.groups,
        id:            data.capsule.id,
        exploreGroups: data.capsule.exploreGroups,
        items:         {
            contact: {
                date: {
                    birthday: data.vCard.birthDay || '',
                    dates:    data.vCard.dates || []
                },
                name: {
                    givenName:  data.vCard.firstName.trim(),
                    familyName: data.vCard.lastName.trim(),
                },
                organisation: {
                    organizationName: data.vCard.company,
                },                
                phoneNumbers:    data.vCard.phoneNumber,
                emailAddresses:  data.vCard.emailAddress,
                notes:           data.media.notes,
                postalAddresses: data.vCard.locationAddress,
                socialProfiles:  data.social.socialProfiles,
                urlAddresses:    data.social.urlAddresses,
                image:           {
                    profile:   profile,
                    thumbnail: thumbnail
                }
            },
            image_and_video: {
                images: data.media.images,
                videos: data.media.videos
            },
            audio: {
                audio: data.media.audio
            },
            artist: {
                bioOverview: data.artist.bio
            },
            documents: {documents: data.media.documents} 
        }

    };

    if (preview) {
        body.id = data.capsule.id ? data.capsule.id : null;
    }

    return JSON.stringify(body);
}