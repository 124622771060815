export function config(state = {}, action) {
    switch (action.type) {
        case 'GET_CHATBOT_CONFIG_REQUEST':
            return {
                ...state,
                error: {}
            };
        case 'GET_CHATBOT_CONFIG_SUCCESS':
            return {
                ...state,
                chatBotConfig: action.response,
                error:         {}
            };
        case 'GET_CHATBOT_CONFIG_ERROR':
            return {
                ...state,
                error: action.error
            };
        case 'GET_WRAP_CONFIG_REQUEST':
            return {
                ...state,
                error: {}
            };
        case 'GET_WRAP_CONFIG_SUCCESS':
            return {
                ...state,
                wrap:  action.response,
                error: {}
            };
        case 'GET_WRAP_CONFIG_ERROR':
            return {
                ...state,
                error: action.error
            };
                    
        default:
            return state;
    }
}