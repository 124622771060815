import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import { getUrlAndLicence } from '../../utils/VieroUtils';
import { getUserTokenFromStorage } from '../../utils/Helpers';

//import { CapsuleEdit } from '../profile/EditCapsuleView';
//import { isObjectExists} from '../../utils/Helpers';
//import cardAdded from '../../assets/images/CardAddedIcon@3x.png';

export class ProfileMedia extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            profileView: 'main'
        };
    }

    checkIfVieroImage = (i) => {
        let result = false;
        if (-1 !== this.props.user.fileId[i].mimeType.indexOf('image') && -1 !== this.props.user.fileId[i].storageType.indexOf('viero')) {
            result = true;
        }
        return result;
    }

    renderUploadedFileTypes = (isImage) => {
        if (isImage) {
            return Object.keys(this.props.user.fileId).map((i) => (
                this.checkIfVieroImage(i) &&
                <Col xs="6" sm="4" md="2" lg="2" className="photos mb-2 mt-2" key={i} >
                    <img
                        className="media-list-image"
                        src={getUrlAndLicence(this.props.user.fileId[i].storageUUID, false, '100x100,crop', 'VieroToolkitUtilsTypeStillSingle').url}
                        onClick={() => { this.setState({ visible: !this.state.visible, imageToShow: process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + this.props.user.fileId[i].id + '?token=' + getUserTokenFromStorage() }); } }
                    />
                    {/*<div className="hover mb-10" onClick={() => this.removePhoto(this.props.user.fileId[i].id)}>{this.removeText}</div>*/}
                </Col>

            ));
        } else {
            return Object.keys(this.props.user.fileId).map((i) => (
                -1 !== this.props.user.fileId[i].mimeType.indexOf('video') &&
                <Col xs="6" sm="4" md="2" lg="2" className="photos mb-2 mt-2" key={i} >
                    <img
                        className="media-list-image"
                        src={getUrlAndLicence(this.props.user.fileId[i].storageUUID, false, '100x100,crop', 'VieroToolkitUtilsTypeStillSingle').url}
                        onClick={() => { this.setState({ visible: !this.state.visible, imageToShow: process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + this.props.user.fileId[i].id + '?token=' + getUserTokenFromStorage() }); } }
                    />
                    {/*<div className="hover mb-10" onClick={() => this.removePhoto(this.props.user.fileId[i].id)}>{this.removeText}</div>*/}
                </Col>

            ));
        }
    }

    renderUploadedFiles = () => {

        return (
            this.props.user.fileId && this.props.user.fileId.length > 0 && this.props.user.gotFiles ?
                <React.Fragment>
                    <Col sm="12" md="12" lg="12">Images</Col>
                    {this.renderUploadedFileTypes(true)}
                    {/*}<Col sm="12" md="12" lg="12">Videos</Col>*/}
                    {/*}{this.renderUploadedFileTypes()}*/}
                </React.Fragment>
                :
                <Col>There is no uploaded media.</Col>

        );
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col><span className="hover" onClick={() => this.props.back()}>Back</span></Col>
                </Row>
                <Row className="profile-card mt-10">
                    {this.renderUploadedFiles()}
                </Row>
            </Container>
        );

    }
}

ProfileMedia.propTypes = {
    user:         PropTypes.object,
    renderHeader: PropTypes.func,
    dispatch:     PropTypes.func,
    logout:       PropTypes.func,
    backToGroups: PropTypes.func,
    back:         PropTypes.func,
    capsules:     PropTypes.object
};
