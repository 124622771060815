// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("./Wraps.css");
var ___HTML_LOADER_IMPORT_1___ = require("./wrapImages/M3DS Logo.png");
var ___HTML_LOADER_IMPORT_2___ = require("./wrapImages/My Universe Opening Image-BikeImage-FOR HTML.png");
var ___HTML_LOADER_IMPORT_3___ = require("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<html> <head> <link rel=\"stylesheet\" href=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> <title>MyUniverse Contacts M3DS Eric Sorensen</title> </head> <body> <div class=\"page-container\"> <div class=\"logo-line\"><img class=\"img img-fluid logo-one\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"/></div> <div class=\"page-one-background\"> <img class=\"img img-fluid\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"/> <div class=\"image-text\"> <div class=\"colby-name\">Colby Raha</div> <div class=\"sub-title\">8 TME X GAME MEDALIST</div> </div> </div> <div class=\"roll-on-gel-position\"> <img class=\"img img-fluid roll-on-gel-image float-left\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"/> <div> <span class=\"page-text\"> <span class=\"name-text\">Eric Sorensen</span> <br> <span>Managing Member</span> </span> </div> </div> </div> </body> </html> ";
// Exports
module.exports = code;