import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col} from 'reactstrap';

export class CreateWrap extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            pages:     [{}],
            position:  0,
            pageCount: 1
        };
        this.inputPictureRef = React.createRef();
    }

    setPageNumber = (count) => {
        let pages = [...this.state.pages];
        if (count <= 10 && count > pages.length) {
            while (pages.length !== parseInt(count)) {
                pages.push({});
            }
        } else if (count <= 10 && count < pages.length) {
            pages = pages.slice(0, parseInt(count));
        }

        this.setState({pages});

    }

    getBase64Img = (blob) => {
        var reader = new FileReader();
        return new Promise((resolve, reject) =>{
            reader.onerror = () => {
                reader.abort();
                reject(new DOMException('Problem parsing input file.'));
            };

            reader.onload = function() {
                resolve(reader.result.replace(/^data:.+;base64,/, ''));
            };
            reader.readAsDataURL(blob);
        });

    };

    onFileSelected = (event) => {
        var selectedFile = event.target.files[0];
        var reader = new FileReader();
        return new Promise((resolve, reject) =>{
            reader.onerror = function(e) {
                reject(e);
            };

            reader.onload = function() {
                resolve(reader.result);
            };

            reader.readAsDataURL(selectedFile);
        });
    }

    setImage = async (e) => {
        let imgUrl = await this.onFileSelected(e);
        let pages = [...this.state.pages];
        pages[this.state.position].imgUrl = imgUrl;
        this.setState({pages});
    }

    navigateWrapTo = (page) => {

        if ((page < this.state.pages.length && page > this.state.position) || (page >= 0 && page < this.state.position)) {
            this.setState({position: page});
        }
    }

    getWrapPage = () => {
        let pages = [...this.state.pages];
        if (pages[this.state.position].imgUrl) {
            return (
                <div className="create-wrap-container border w-100">
                    <img src={pages[this.state.position].imgUrl} className="img-fluid"/>
                </div>
            );
        } else {
            return (
                <div className="create-wrap-container border w-100">
                    <span onClick={() => this.inputPictureRef.current.click()}>Click to add photo</span>
                    <input
                        ref={this.inputPictureRef}
                        onChange={(e) => this.setImage(e, 'photo')}
                        type="file"
                        accept=".jpg, .jpeg, .png, .gif, .heic"
                        style={{display: 'none'}}/>
                </div>
            );
        }

    }

    render() {

        return (<Container>
            <Row>
                <Col xs="6" sm="6" md="6" lg="6">
                    <div className="text-center">Wrap</div>
                    <Row>
                        <Col className="text-center" xs="4" sm="4" md="4" lg="4">
                            <span onClick={() => this.navigateWrapTo(this.state.position-1)}> {'<'}</span>
                        </Col>
                        <Col className="text-center" xs="4" sm="4" md="4" lg="4">
                            {this.state.position+1 + '/' + this.state.pages.length}
                        </Col>
                        <Col className="text-center" xs="4" sm="4" md="4" lg="4">
                            <span onClick={() => this.navigateWrapTo(this.state.position+1)}> {'>'}</span>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            {this.getWrapPage()}
                        </Col>
                    </Row>
                </Col>

                <Col xs="6" sm="6" md="6" lg="6">
                    <div className="text-center">Options</div>
                    <Row>
                        <Col>
                            <label>Page number</label>
                            <input value={this.state.pageCount} onChange={(e) => this.setState({pageCount: e.target.value})} />
                        </Col>
                        <Col>
                            <button onClick={() => this.setPageNumber(this.state.pageCount)}>Set</button>
                        </Col>
                    </Row>

                    <div>Layout</div>
                </Col>
            </Row>

        </Container>);
    }

}

CreateWrap.propTypes = {
    user:         PropTypes.object,
    capsules:     PropTypes.capsules,
    listenStatus: PropTypes.func,
    width:        PropTypes.string,
    height:       PropTypes.string,
    pages:        PropTypes.array,
    next:         PropTypes.func,
    prev:         PropTypes.func,
    setPages:     PropTypes.func,
    currentPage:  PropTypes.number
};
