import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { sortAlphabeticallyUsers } from '../../utils/Helpers';
import redButtonIcon from '../../assets/images/Red-button-icon.png';
import greenButtonIcon from '../../assets/images/Green-button-icon.png';

export class VerifiedUsersAdmin extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            getVerifiedUsers:       false,
            gotVerifiedUsers:       false,
            verifiedUsers:          [],
            countRecVerifiedUsers:  0,
            countVerifiedUsers:     0,
            updatedCanPublishValue: false,
            countPublicAccounts:    0,
        };

        this.updateStatusText = 'Click circle to change status';
        this.publicText = 'Public';
        this.notPublicText = 'Private';

    }

    componentDidMount() {
        this.props.setGotVerifiedUsers(false);
    }

    componentDidUpdate() {

        if (this.state.getVerifiedUsers === false && this.props.gotVerifiedUsers === false) {
            this.props.getVerifiedUsers();
            this.setState({getVerifiedUsers: true});
        }

        if (this.props.gotVerifiedUsers === true && this.state.gotVerifiedUsers === false) {
            this.listVerifiedUsers();
            this.setState({gotVerifiedUsers: true});
        }

        if (this.props.updatedCanPublishValue === true && this.state.updatedCanPublishValue === false) {
            this.setUpdatedUserCanPublish(this.props.publishUserId, this.props.canPublish);
            this.props.setUpdatedUserCanPublish(false);
            this.setState({updatedCanPublishValue: true});
        }

        if (this.props.updatedCanPublishValue === false && this.state.updatedCanPublishValue === true) {
            this.setState({updatedCanPublishValue: false});
        }

    }

    setUpdatedUserCanPublish = (userId, canPublishValue) => {
        let verifiedUsers = this.state.verifiedUsers;
        let countPublicAccounts = 0;

        for (let i in verifiedUsers) {
            if (verifiedUsers[i].id === userId) {
                verifiedUsers[i].canPublishCapsule = canPublishValue;
                break;
            }
        }

        for (let i in verifiedUsers) {
            if (verifiedUsers[i].canPublishCapsule === true) {
                countPublicAccounts++;
            }
        }

        this.setState({verifiedUsers: verifiedUsers, countPublicAccounts: countPublicAccounts});

    }

    getName = (i) => {
        let user = this.props.verifiedUsers[i];
        let name = '';
        let firstName = user.name !== undefined && user.name !== null && user.name.givenName !== null && user.name.givenName !== undefined ? user.name.givenName : '';
        let lastName = user.name !== undefined && user.name !== null && user.name.familyName !== null && user.name.familyName !== undefined ? user.name.familyName : '';
        if (firstName !== '' && lastName !== '') {
            name = firstName + ' ' + lastName;
        } else if (firstName !== '' && lastName === '') {
            name = firstName;
        } else if (firstName  === '' && lastName !== '') {
            name = lastName;
        } else {
            name = 'No name';
        }

        return name;
    }

    listVerifiedUsers = () => {

        let result = [];
        let resultEmail = [];
        let resultPhone = [];
        let resultNeither = []; //this shouldn't occur but if does check db
        let count = 0;
        let countRecVerifiedUsers = 0;
        let countPublicAccounts = 0;
        let finalCountPublicAccounts = 0;
        let verifiedUsers = this.props.verifiedUsers;

        for (let i in verifiedUsers) {
            if (verifiedUsers[i].email) {
                resultEmail.push({
                    name:              this.getName(i),
                    user:              verifiedUsers[i].email,
                    id:                verifiedUsers[i].id,
                    canPublishCapsule: verifiedUsers[i].canPublishCapsule
                });
                countRecVerifiedUsers++;
            } else if (verifiedUsers[i].phone) {
                resultPhone.push({
                    name:              this.getName(i),
                    user:              verifiedUsers[i].phone,
                    id:                verifiedUsers[i].id,
                    canPublishCapsule: verifiedUsers[i].canPublishCapsule
                });
                countRecVerifiedUsers++;
            } else {
                resultNeither.push({
                    name:              this.getName(i),
                    user:              verifiedUsers[i].id,
                    id:                verifiedUsers[i].id,
                    canPublishCapsule: verifiedUsers[i].canPublishCapsule
                });
            }
            count++;
            finalCountPublicAccounts = verifiedUsers[i].canPublishCapsule === true ? countPublicAccounts++ : countPublicAccounts;
        }

        countPublicAccounts = finalCountPublicAccounts;

        if (0 < resultEmail.length) {
            result = sortAlphabeticallyUsers(resultEmail);
        }

        if (0 < resultPhone.length) {
            resultPhone = sortAlphabeticallyUsers(resultPhone);
            for (let i in resultPhone) {
                result.push({
                    name:              resultPhone[i].name,
                    user:              resultPhone[i].user,
                    id:                resultPhone[i].id,
                    canPublishCapsule: resultPhone[i].canPublishCapsule
                });
            }
        }

        if (0 < resultNeither.length) {
            resultNeither = sortAlphabeticallyUsers(resultNeither);
            for (let i in resultNeither) {
                result.push({
                    name:              resultNeither[i].name,
                    user:              resultNeither[i].user,
                    id:                resultNeither[i].id,
                    canPublishCapsule: resultNeither[i].canPublishCapsule
                });
            }
        }

        this.setState({verifiedUsers: result, countRecVerifiedUsers: countRecVerifiedUsers, countVerifiedUsers: count, countPublicAccounts: countPublicAccounts});
    }

    getVerifiedUsersList = () => {
        const verifiedUsersList = this.state.verifiedUsers;
        if (0 < verifiedUsersList.length) {
            return verifiedUsersList;
        } else {
            return 'NoUsers';
        }
    }

    setCanPublishValue = (userId, canPublishValue) => {
        this.props.setCanPublishValueDispatch(userId, canPublishValue);
    }

    getVerifiedUsers = () => {
        let verifiedUsers = this.getVerifiedUsersList();
        let noUsers = verifiedUsers === 'NoUsers' ? true : false;
        if (!noUsers) {
            return (
                <Container className="publish-background">
                    <div className="verify-users-container stop-scroll">
                        <Row className="group-element lightgrey">
                            <Col><span>Which Accounts Can Make Public Capsules? {this.state.countPublicAccounts} of {this.state.countVerifiedUsers}</span></Col>
                        </Row>
                        <Row className="group-element left-banned-box-spacing">
                            <Col className="group-list-divider letter-line color-black">
                                <img className="divider-circle" src={greenButtonIcon}/>
                                {this.publicText}
                                <img className="divider-circle" src={redButtonIcon}/>
                                {this.notPublicText}
                            </Col>
                        </Row>
                        <Row className="group-element">
                            <Col><span className="status-italics">{this.updateStatusText}</span></Col>
                        </Row>
                    </div>
                    <div className="verify-users-container">
                        {this.renderVerifiedUsers(verifiedUsers)}
                    </div>
                </Container>
            );
        }
    }

    renderVerifiedUsers = (verifiedUsers) => {
        return (
            <React.Fragment>

                {Object.keys(verifiedUsers).map((i) =>
                    <React.Fragment key={i}>
                        <Row className="left-value-verified">
                            <Col className="lightgrey">
                                {verifiedUsers[i].name}
                            </Col>
                            <Col className='right-value-verified'>
                                {
                                    !verifiedUsers[i].canPublishCapsule ?
                                        <span onClick={() => this.setCanPublishValue(verifiedUsers[i].id, !verifiedUsers[i].canPublishCapsule)} className="circle-red hover">
                                        </span>
                                        :
                                        <span onClick={() => this.setCanPublishValue(verifiedUsers[i].id, !verifiedUsers[i].canPublishCapsule)} className="circle-green hover">
                                        </span>
                                }
                            </Col>
                        </Row>
                        <Row className="left-value-verified-secondary">
                            <Col className="lightgrey">
                                {verifiedUsers[i].user}
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    render() {

        return (
            <Container className="container-width">
                {this.getVerifiedUsers()}
            </Container>);
    }

}

VerifiedUsersAdmin.propTypes = {
    user:                       PropTypes.object,
    renderHeader:               PropTypes.func,
    dispatch:                   PropTypes.func,
    backToGroups:               PropTypes.func,
    setGotVerifiedUsers:        PropTypes.func,
    getVerifiedUsers:           PropTypes.func,
    gotVerifiedUsers:           PropTypes.bool,
    verifiedUsers:              PropTypes.array,
    setCanPublishValueDispatch: PropTypes.func,
    publishUserId:              PropTypes.string,
    canPublish:                 PropTypes.bool,
    setUpdatedUserCanPublish:   PropTypes.func,
    updatedCanPublishValue:     PropTypes.bool,
    back:                       PropTypes.func

};
